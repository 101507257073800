import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { AXFAPITemplateService } from '../../services/template/template-api.service';
import { AXFTemplateService } from '../../services/template/template.service';
import { AXFWidgetSharedModule } from '../shared/shared.module';
import { AXFButtonWidgetModule } from '../widgets/button/button.module';
import { AXFCheckboxInputWidgetModule } from '../widgets/checkbox-input/checkbox-input.module';
import { AXFColWidgetModule } from '../widgets/col/col.module';
import { AXFContainerWidgetModule } from '../widgets/container/container.module';
import { AXFDateInputWidgetModule } from '../widgets/date-input/date-input.module';
import { AXFDropdownInputWidgetModule } from '../widgets/dropdown-input/dropdown-input.module';
import { AXFFileUploadWidgetModule } from '../widgets/file-upload/file-upload.module';
import { AXFGridInputWidgetModule } from '../widgets/grid-input/grid-input.module';
import { AXFImageInputWidgetModule } from '../widgets/image-input/image-input.module';
import { AXFImageWidgetModule } from '../widgets/image/image-view.module';
import { AXFLinkInputWidgetModule } from '../widgets/link-input/link-input.module';
import { AXFListInputWidgetModule } from '../widgets/list-input/list-input.module';
import { AXFListSecondInputWidgetModule } from '../widgets/listsecond-input/listsecond-input.module';
import { AXFLovInputWidgetModule } from '../widgets/lov/lov-input.module';
import { AXFNumberInputWidgetModule } from '../widgets/number-input/number-input.module';
import { AXFPageOutletWidgetModule } from '../widgets/outlet/outlet.module';
import { AXFPageBreakWidgetModule } from '../widgets/page-break/page-break.module';
import { AXFPageFooterWidgetModule } from '../widgets/page-footer/page-footer.module';
import { AXFPageHeaderWidgetModule } from '../widgets/page-header/page-header.module';
import { AXFPagePageWidgetModule } from '../widgets/page/page.module';
import { AXFPanelWidgetModule } from '../widgets/panel/panel.module';
import { AXFRepeaterlWidgetModule } from '../widgets/repeater/repeater.module';
import { AXFRowWidgetModule } from '../widgets/row/row.module';
import { AXFSignatureInputWidgetModule } from '../widgets/signature-input/signature-input.module';
import { AXFTableCellWidgetModule } from '../widgets/table-cell/table-cel.module';
import { AXFTableRowWidgetModule } from '../widgets/table-row/table-row.module';
import { AXFTableWidgetModule } from '../widgets/table/table.module';
import { AXFTextAreaWidgetModule } from '../widgets/text-area/text-area.module';
import { AXFTextBlockWidgetModule } from '../widgets/text-block/text-block.module';
import { AXFTextInputWidgetModule } from '../widgets/text-input/text-input.module';
import { AXFTimeInputWidgetModule } from '../widgets/time-input/time-input.module';
import { AXFVideoInputWidgetModule } from '../widgets/video-input/video-input.module';
import { AXFVideoWidgetModule } from '../widgets/video/video-view.module';
import { WidgetInjector } from './widget';

const MODULES = [
  CommonModule,
  AXFWidgetSharedModule,
  AXFRowWidgetModule,
  AXFColWidgetModule,
  AXFTextBlockWidgetModule,
  AXFPageBreakWidgetModule,
  AXFDateInputWidgetModule,
  AXFTimeInputWidgetModule,
  AXFPagePageWidgetModule,
  AXFTextInputWidgetModule,
  AXFNumberInputWidgetModule,
  AXFTextAreaWidgetModule,
  AXFCheckboxInputWidgetModule,
  AXFImageWidgetModule,
  AXFSignatureInputWidgetModule,
  AXFPageOutletWidgetModule,
  AXFListInputWidgetModule,
  AXFListSecondInputWidgetModule,
  AXFDropdownInputWidgetModule,
  AXFButtonWidgetModule,
  AXFGridInputWidgetModule,
  AXFPanelWidgetModule,
  AXFTableWidgetModule,
  AXFTableRowWidgetModule,
  AXFTableCellWidgetModule,
  AXFRepeaterlWidgetModule,
  AXFPageHeaderWidgetModule,
  AXFPageFooterWidgetModule,
  AXFImageInputWidgetModule,
  AXFContainerWidgetModule,
  AXFFileUploadWidgetModule,
  AXFLovInputWidgetModule,
  AXFLinkInputWidgetModule,
  AXFVideoInputWidgetModule,
  AXFVideoWidgetModule,
];

@NgModule({
  declarations: [],
  imports: [...MODULES],
  exports: [...MODULES],
  providers: [
    {
      provide: AXFTemplateService,
      useClass: AXFAPITemplateService,
    },
  ],
})
export class AXFWidgetModule {
  constructor(injector: Injector) {
    WidgetInjector.instance = injector;
  }
}
