import { ACoreXUIModule } from '@acorex/ui';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AXFBoxStyleBoxSizeValue } from '../../../property-editor/editors/style/box-style/box-style.class';
import { AXFWidgetService } from '../../../services/widget.service';
import {
  AXF_BG_COLOR_PROPERTY,
  AXF_BOX_STYLE_PROPERTY,
  AXF_DISPLAY_NAME_PROPERTY,
  AXF_DS_LIST_PROPERTY,
  AXF_INIT_EVENT,
  AXF_MINWIDTH_PROPERTY,
  AXF_NAME_PROPERTY,
  AXF_TAG_PROPERTY,
  AXF_VALUE_CHANGE_EVENT,
  AXF_VISIBLE_PROPERTY,
} from '../../config/general-properties';
import { AXFWidgetSharedModule } from '../../shared/shared.module';
import { AXFRepeaterWidgetDesigner } from './designer/repeater-widget.designer';
import { AXFRepeaterWidgetPrint } from './print/repeater-widget.print';
import { AXFRepeaterWidgetView } from './view/repeater-widget.view';

export const COMPONENTS = [
  AXFRepeaterWidgetDesigner,
  AXFRepeaterWidgetView,
  AXFRepeaterWidgetPrint,
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [CommonModule, ACoreXUIModule, AXFWidgetSharedModule],
  exports: [...COMPONENTS],
  entryComponents: [...COMPONENTS],
  providers: [],
})
export class AXFRepeaterlWidgetModule {
  constructor(service: AXFWidgetService) {
    service.register({
      title: 'Repeater',
      hint: 'Repeater container element',
      icon: 'fa fa-sync',
      category: 'Layout',
      visible: true,
      name: 'repeater',
      designerClass: AXFRepeaterWidgetDesigner,
      printClass: AXFRepeaterWidgetPrint,
      container: true,
      draggable: false,
      viewClass: AXFRepeaterWidgetView,
      options: {
        boxStyle: {
          border: new AXFBoxStyleBoxSizeValue('1'),
          padding: new AXFBoxStyleBoxSizeValue('0'),
          margin: new AXFBoxStyleBoxSizeValue('5', '0', '5', '0'),
        },
        bgColor: 'inherit',
        dataSource: {
          mode: 'manual',
          dataSource: {},
          columns: [],
          dataItems: [],
        },
      },
      properties: [
        {
          name: 'showHeader',
          category: 'General',
          defaultValue: false,
          title: 'Show Header',
          editor: 'CheckboxEditor',
          options: { label: 'Show Header' },
        },
        AXF_BG_COLOR_PROPERTY,
        AXF_DS_LIST_PROPERTY,
        AXF_BOX_STYLE_PROPERTY,
        AXF_NAME_PROPERTY,
        AXF_DISPLAY_NAME_PROPERTY,
        AXF_TAG_PROPERTY,
        AXF_VISIBLE_PROPERTY,
        AXF_VALUE_CHANGE_EVENT,
        AXF_INIT_EVENT,
        {
          name: 'allowAdd',
          category: 'General',
          defaultValue: false,
          title: 'Allow Add Item',
          editor: 'CheckboxEditor',
          options: { label: 'Allow Add Item' },
        },
        {
          name: 'deleteItem',
          category: 'General',
          defaultValue: false,
          title: 'Delete Item',
          editor: 'CheckboxEditor',
          options: { label: 'Delete Item' },
          visible: (options: any) => {
            if (options.dataSource && options.dataSource.mode == 'manual')
              return true;
            else return false;
          },
        },
        {
          name: 'isResponsive',
          category: 'General',
          defaultValue: false,
          title: 'Responsive',
          editor: 'CheckboxEditor',
          options: { label: 'Responsive' },
        },
        {
          name: 'clearOnCopy',
          category: 'General',
          defaultValue: false,
          title: 'Clear on Copy',
          editor: 'CheckboxEditor',
          options: { label: 'Clear on Copy' },
        },
        AXF_MINWIDTH_PROPERTY,
        {
          name: 'indexStart',
          category: 'Data',
          defaultValue: 1,
          title: 'Index Start Number',
          editor: 'TextEditor',
          options: { allowHtml: false },
        },
        {
          name: 'responsiveBorderColor',
          category: 'Style',
          defaultValue: 'inherit',
          title: 'Responsive Border Color',
          editor: 'ColorEditor',
        },
      ],
    });
  }
}
