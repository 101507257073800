<div #el class="allItem">
    <div class="rep" *ngFor="let item of items" 
        [ngStyle]="{'width':(direction=='horizontal' && columns==1)?null:(100.0/columns)+'%','float':direction=='horizontal'?(alignment=='ltr'?'left':'right'):'unset','background':bgColor}">
        <ng-container *ngIf="viewType!='both'" [ngSwitch]="alignCheck">
            <div *ngSwitchCase="'left'" style="padding: 6px;">
                <span *ngIf="mode!='unselectable'" class="spanCheck" [ngStyle]="getStyles(mode)"
                [ngClass]="mode=='single' && item.DefaultValue ? 'checkmark' : null">
                    <i *ngIf="mode=='multiple' && item.DefaultValue" [style.font-size]="getSize()" class="fa fa-check"
                     aria-hidden="true"></i>
                </span>
                <img [style.margin-left]="getMargin()" *ngIf="viewType=='image'"
                    [src]="getData(item,dataSource.columns[2].fieldName) | resolveUrl | async" height="80px" width="60px">
                <div [style.margin-left]="getMargin()" [style.color]="color" [style.font-size]="getSize()"
                    *ngIf="viewType=='text'">{{getData(item,dataSource.columns[1].fieldName)}} 
                    <i *ngIf="dataSource.showDocument"  class="fa fa-file"   aria-hidden="true"></i></div> 
            </div>
            <div *ngSwitchCase="'top'" style="padding: 6px;">
                <div  [style.padding-bottom]="getMargin()">
                    <span *ngIf="mode!='unselectable'" class="spanCheck" [ngStyle]="getStyles(mode)"
                    [ngClass]="mode=='single' && item.DefaultValue ? 'checkmark' : null">
                        <i *ngIf="mode=='multiple' && item.DefaultValue" [style.font-size]="getSize()" class="fa fa-check"
                         aria-hidden="true"></i></span>
                </div>
                <div>
                    <img *ngIf="viewType=='image'" [src]="getData(item,dataSource.columns[2].fieldName) | resolveUrl | async"
                        height="80px" width="60px">
                    <div *ngIf="viewType=='text'" [style.color]="color" [style.font-size]="getSize()">
                        {{getData(item,dataSource.columns[1].fieldName)}} <i *ngIf="dataSource.showDocument"  class="fa fa-file"   aria-hidden="true"></i></div>
                </div>
            </div>
            <div *ngSwitchCase="'bottom'" style="padding: 6px;">
                <div>
                    <img *ngIf="viewType=='image'" [src]="getData(item,dataSource.columns[2].fieldName) | resolveUrl | async"
                        height="80px" width="60px">
                    <div *ngIf="viewType=='text'" [style.color]="color" [style.font-size]="getSize()">
                        {{getData(item,dataSource.columns[1].fieldName)}} <i *ngIf="dataSource.showDocument"  class="fa fa-file"   aria-hidden="true"></i></div>
                </div>
                <div  [style.padding-bottom]="getSize()">
                    <span *ngIf="mode!='unselectable'" class="spanCheck" [ngStyle]="getStyles(mode)"
                    [ngClass]="mode=='single' && item.DefaultValue ? 'checkmark' : null">
                        <i *ngIf="mode=='multiple' && item.DefaultValue" [style.font-size]="getSize()" class="fa fa-check"
                         aria-hidden="true"></i></span>
                </div>
            </div>
            <div *ngSwitchCase="'right'" style="padding: 6px 0px 6px 10px;position: relative;display: flex;">
                <div style="float: left;" [style.margin-right]="getMargin()">
                    <img *ngIf="viewType=='image'" [src]="getData(item,dataSource.columns[2].fieldName) | resolveUrl | async"
                        height="80px" width="60px">
                    <div *ngIf="viewType=='text'" [style.color]="color" [style.font-size]="getSize()">
                        {{getData(item,dataSource.columns[1].fieldName)}} <i *ngIf="dataSource.showDocument"  class="fa fa-file"   aria-hidden="true"></i></div>
                </div>
                <div style="position: absolute;" [style.right]="getSize()">
                    <span *ngIf="mode!='unselectable'" class="spanCheck" [ngStyle]="getStyles(mode)"
                    [ngClass]="mode=='single' && item.DefaultValue ? 'checkmark' : null">
                        <i *ngIf="mode=='multiple' && item.DefaultValue" [style.font-size]="getSize()" class="fa fa-check"
                         aria-hidden="true"></i></span>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="viewType=='both'" [ngSwitch]="alignCheckNew"> 
            <div *ngSwitchCase="'ItopCleftSright'">
                <div>
                    <img [src]="getData(item,dataSource.columns[2].fieldName)| resolveUrl | async" height="80px" width="60px">
                </div>
                <div>
                    <span *ngIf="mode!='unselectable'" class="spanCheck" [ngStyle]="getStyles(mode)"
                    [ngClass]="mode=='single' && item.DefaultValue ? 'checkmark' : null">
                        <i *ngIf="mode=='multiple' && item.DefaultValue" [style.font-size]="getSize()" class="fa fa-check"
                         aria-hidden="true"></i></span>
                    <span [style.margin-left]="getSize()" [style.color]="color" [style.font-size]="getSize()">
                        {{getData(item,dataSource.columns[1].fieldName)}}<i *ngIf="dataSource.showDocument"  class="fa fa-file"   aria-hidden="true"></i></span>
                </div>
            </div>
            <div *ngSwitchCase="'ItopCrightSleft'">
                <div>
                    <img [src]="getData(item,dataSource.columns[2].fieldName)| resolveUrl | async" height="80px" width="60px">
                </div>
                <div [style.margin-right]="getSize()">
                    <span style="margin-right: 5px;" [style.color]="color" [style.font-size]="getSize()">
                        {{getData(item,dataSource.columns[1].fieldName)}}<i *ngIf="dataSource.showDocument"  class="fa fa-file"   aria-hidden="true"></i></span>
                    <span *ngIf="mode!='unselectable'" class="spanCheck" [ngStyle]="getStyles(mode)"
                    [ngClass]="mode=='single' && item.DefaultValue ? 'checkmark' : null">
                        <i *ngIf="mode=='multiple' && item.DefaultValue" [style.font-size]="getSize()" class="fa fa-check"
                         aria-hidden="true"></i></span>
                </div>
            </div>
            <div *ngSwitchCase="'IbottomCleftSright'">
                <div>
                    <span *ngIf="mode!='unselectable'" class="spanCheck" [ngStyle]="getStyles(mode)"
                    [ngClass]="mode=='single' && item.DefaultValue ? 'checkmark' : null">
                        <i *ngIf="mode=='multiple' && item.DefaultValue" [style.font-size]="getSize()" class="fa fa-check"
                         aria-hidden="true"></i></span>
                    <span [ngStyle]="{'margin-left': getSize(),'color': color,'font-size':getSize()}">
                        {{getData(item,dataSource.columns[1].fieldName)}}<i *ngIf="dataSource.showDocument"  class="fa fa-file"   aria-hidden="true"></i></span>
                </div>
                <div>
                    <img [src]="getData(item,dataSource.columns[2].fieldName) | resolveUrl | async" height="80px" width="60px">
                </div>
            </div>
            <div *ngSwitchCase="'IbottomCrightSleft'">
                <div [style.margin-right]="getSize()">
                    <span style="margin-right: 5px;" [style.color]="color" [style.font-size]="getSize()">
                        {{getData(item,dataSource.columns[1].fieldName)}}<i *ngIf="dataSource.showDocument"  class="fa fa-file"   aria-hidden="true"></i></span>
                    <span *ngIf="mode!='unselectable'" class="spanCheck" [ngStyle]="getStyles(mode)"
                    [ngClass]="mode=='single' && item.DefaultValue ? 'checkmark' : null">
                        <i *ngIf="mode=='multiple' && item.DefaultValue" [style.font-size]="getSize()" class="fa fa-check"
                         aria-hidden="true"></i></span>
                </div>
                <div>
                    <img [src]="getData(item,dataSource.columns[2].fieldName) | resolveUrl | async" height="80px" width="60px">
                </div>
            </div>
            <div *ngSwitchCase="'StopImiddleCbottom'" [style.padding-bottom]="getSize()">
                <div><span [style.color]="color" [style.font-size]="getSize()">{{getData(item,dataSource.columns[1].fieldName)}}<i *ngIf="dataSource.showDocument"  class="fa fa-file"   aria-hidden="true"></i></span></div>
                <div>
                    <img [src]="getData(item,dataSource.columns[2].fieldName) | resolveUrl | async" height="80px" width="60px">
                </div>
                <div>
                    <span *ngIf="mode!='unselectable'" class="spanCheck" [ngStyle]="getStyles(mode)"
                    [ngClass]="mode=='single' && item.DefaultValue ? 'checkmark' : null">
                        <i *ngIf="mode=='multiple' && item.DefaultValue" [style.font-size]="getSize()" class="fa fa-check"
                         aria-hidden="true"></i></span>
                </div>
            </div>
            <div *ngSwitchCase="'ItopSmiddleCbottom'" [style.padding-bottom]="getSize()">
                <div>
                    <img [src]="getData(item,dataSource.columns[2].fieldName) | resolveUrl | async" height="80px" width="60px">
                </div>
                <div><span [style.color]="color" [style.font-size]="getSize()">{{getData(item,dataSource.columns[1].fieldName)}}<i *ngIf="dataSource.showDocument"  class="fa fa-file"   aria-hidden="true"></i></span></div>
                <div>
                    <span *ngIf="mode!='unselectable'" class="spanCheck" [ngStyle]="getStyles(mode)"
                    [ngClass]="mode=='single' && item.DefaultValue ? 'checkmark' : null">
                        <i *ngIf="mode=='multiple' && item.DefaultValue" [style.font-size]="getSize()" class="fa fa-check"
                         aria-hidden="true"></i></span>
                </div>
            </div>

            <div *ngSwitchCase="'CtopSmiddleIbottom'">
                <div [style.padding-bottom]="getSize()">
                    <span *ngIf="mode!='unselectable'" class="spanCheck" [ngStyle]="getStyles(mode)"
                    [ngClass]="mode=='single' && item.DefaultValue ? 'checkmark' : null">
                        <i *ngIf="mode=='multiple' && item.DefaultValue" [style.font-size]="getSize()" class="fa fa-check"
                         aria-hidden="true"></i></span>
                </div>
                <div><span [style.color]="color" [style.font-size]="getSize()">{{getData(item,dataSource.columns[1].fieldName)}}<i *ngIf="dataSource.showDocument"  class="fa fa-file"   aria-hidden="true"></i></span></div>
                <div>
                    <img [src]="getData(item,dataSource.columns[2].fieldName) | resolveUrl | async" height="80px" width="60px">
                </div>
            </div>
            <div *ngSwitchCase="'CtopImiddleSbottom'">
                <div [style.padding-bottom]="getSize()">
                    <span *ngIf="mode!='unselectable'" class="spanCheck" [ngStyle]="getStyles(mode)"
                    [ngClass]="mode=='single' && item.DefaultValue ? 'checkmark' : null">
                        <i *ngIf="mode=='multiple' && item.DefaultValue" [style.font-size]="getSize()" class="fa fa-check"
                         aria-hidden="true"></i></span>
                </div>
                <div>
                    <img [src]="getData(item,dataSource.columns[2].fieldName) | resolveUrl | async" height="80px" width="60px">
                </div>
                <div><span [style.color]="color" [style.font-size]="getSize()">{{getData(item,dataSource.columns[1].fieldName)}}<i *ngIf="dataSource.showDocument"  class="fa fa-file"   aria-hidden="true"></i></span></div>
            </div>
        </ng-container>
    </div>
</div> 