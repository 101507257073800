import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { AXFDataSourceOption } from '../../../../property-editor/editors/data-source/data-source.class';
import { AXFConnectService } from '../../../../services/connect.service';
import { AXFValueWidgetView } from '../../../config/widget';

@Component({
  templateUrl: './list-input-widget.view.html',
  styleUrls: ['./list-input-widget.view.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXFListInputWidgetView extends AXFValueWidgetView {
  dataSource!: AXFDataSourceOption;
  mode!: string;
  direction!: string;
  alignCheck!: string;
  alignCheckNew!: string;
  viewType!: string;
  columns!: number;
  alignment!: string;
  color!: string;
  bgColor!: string;
  fontSize!: string;
  items: any[] = [];
  constructor(
    protected override cdr: ChangeDetectorRef,
    private connectService: AXFConnectService
  ) {
    super(cdr);
  }

  override onRender(): void {
    let that: any = this;
    if (
      this.value == undefined &&
      that['rIndex'] >= 0 &&
      that['dataContext'] != undefined &&
      that['dataContext'].hasOwnProperty(that['name'])
    ) {
      this.value = [that['dataContext'][that['name']].toString()];
    }
    this.cdr.detectChanges();
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    if (
      this.dataSource.mode === 'remote' &&
      this.dataSource.dataItems &&
      this.dataSource.displayMode === 'onlySelected' &&
      this.dataSource.displayItems &&
      this.dataSource.displayItems.length > 0
    )
      this.items = this.dataSource.dataItems.filter((d) =>
        this.dataSource?.displayItems?.includes(
          d[(this.dataSource.columns[0] as any).fieldName]
        )
      );
    else this.items = this.dataSource.dataItems as any[];

    if (
      this.value == undefined &&
      this.dataSource.dataItems &&
      this.dataSource.mode === 'manual'
    ) {
      let defaultVals = this.dataSource.dataItems
        .filter((s) => s.DefaultValue == true)
        .map((s) => {
          return s.value;
        });
      if (defaultVals.length > 0) {
        this.value = defaultVals;
      }
    }
    this.cdr.detectChanges();
    if (this.dataSource.mode === 'remote') {
      this.dataSource?.dataSource?.params.forEach((p) => {
        if (typeof p.value === 'string' && p.value.startsWith('$')) {
          p.value = this.resolveProperty(p.value);
        }
      });
      this.dataService
        ?.getList(
          this.dataSource.dataSource?.name as string,
          this.dataSource.dataSource?.params
        )
        .then((c) => {
          this.dataSource.dataItems = c;
          if (
            this.dataSource.displayMode === 'onlySelected' &&
            this.dataSource.displayItems &&
            this.dataSource.displayItems.length > 0
          )
            this.items = c.filter((d) =>
              this.dataSource?.displayItems?.includes(
                d[(this.dataSource.columns[0] as any).fieldName]
              )
            );
          else this.items = c;
          this.cdr.detectChanges();
        });
    }
  }

  override refresh() {
    if (this.dataSource.mode === 'remote') {
      this.dataService
        ?.getList(
          this.dataSource.dataSource?.name as string,
          this.dataSource.dataSource?.params
        )
        .then((c) => {
          this.dataSource.dataItems = c;
          if (
            this.dataSource.displayMode === 'onlySelected' &&
            this.dataSource.displayItems &&
            this.dataSource.displayItems.length > 0
          )
            this.items = c.filter((d) =>
              this.dataSource?.displayItems?.includes(
                d[(this.dataSource.columns[0] as any).fieldName]
              )
            );
          else this.items = c;
          super.refresh();
        });
    } else {
      super.refresh();
    }
  }

  getStyles(mode: string) {
    let currentSize = this.getSize();
    const styles = {
      'border-radius': mode === 'single' ? 100 + '%' : 0,
      height: currentSize,
      width: currentSize,
    };
    return styles;
  }

  onCheckValueChange(val: any) {
    if (this.readonly) {
      return;
    }

    if (this.mode === 'single') {
      this.value = [val];
    } else {
      if (!this.value) {
        this.value = [];
      }

      if (!this.value.includes(val)) {
        this.value = [...this.value, ...[val]];
      } else {
        this.value = this.value.filter((c: any) => c !== val);
      }
    }
    this.invokeEvent('onItemClick');
  }

  getSize() {
    switch (this.fontSize) {
      case 'xx-small':
        return 11 + 'px';
      case 'x-small':
        return 13 + 'px';
      default:
      case 'smaller':
      case 'inherit':
        return 15 + 'px';
      case 'small':
        return 20 + 'px';
      case 'medium':
        return 25 + 'px';
      case 'large':
        return 30 + 'px';
      case 'larger':
        return 35 + 'px';
      case 'x-large':
      case 'xx-large':
        return 40 + 'px';
    }
  }

  getMargin() {
    switch (this.fontSize) {
      case 'xx-small':
      case 'x-small':
        return 18 + 'px';
      default:
      case 'smaller':
      case 'inherit':
        return 20 + 'px';
      case 'small':
        return 25 + 'px';
      case 'medium':
        return 30 + 'px';
      case 'large':
        return 35 + 'px';
      case 'larger':
        return 40 + 'px';
      case 'x-large':
      case 'xx-large':
        return 45 + 'px';
    }
  }

  openFile(fileID: string) {
    this.connectService.send('openUrl', { url: fileID });
  }
}
