import { ACoreXUIModule } from '@acorex/ui';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AXFEditorService } from '../../../services/editor.service';
import { AXFValidationEditorComponent } from './validation.editor';

@NgModule({
  declarations: [AXFValidationEditorComponent],
  imports: [CommonModule, ACoreXUIModule, FormsModule],
  exports: [AXFValidationEditorComponent],
  entryComponents: [AXFValidationEditorComponent],
  providers: [],
})
export class AXFValidationEditorModule {
  constructor(service: AXFEditorService) {
    service.register('ValidationEditor', AXFValidationEditorComponent);
  }
}
