<div class="master-layout-container">
    <header>
        <div class="controlls start-controlls">
            <ax-toolbar-menu [items]="viewModeItems" selection="single" (itemClick)="handleViewModeClick($event)">
            </ax-toolbar-menu>
        </div>
        <div class="title">
            <h1>Formsminder V{{version}}</h1>
        </div>
        <div class="controlls end-controlls">
            <ax-toolbar-menu #actionToolbar [items]="actionItems"  (itemClick)="handleActionClick($event)">
            </ax-toolbar-menu>
        </div>
    </header>
    <article *ngIf="this.view=='designer'">
        <div class="designer-container">
            <ng-container>
                <div class="property-panel">
                    <axf-widget-prop-panel></axf-widget-prop-panel>
                </div>
                <div class="board">
                    <div class="toolbar">
                        <div class="controlls end-controlls">
                            <ax-button type="light" size="sm" tooltip="Undo" [placement]="'bottom'" [disabled]="!changeTracker.canUndo" (onClick)="changeTracker.undo()">
                                <i class="fas fa-undo-alt"></i>
                            </ax-button>
                            <ax-button type="light" size="sm" tooltip="Redo" [placement]="'bottom'" [disabled]="!changeTracker.canRedo" (onClick)="changeTracker.redo()">
                                <i class="fas fa-redo-alt"></i>
                            </ax-button>
                        </div>
                        <div class="nameHeader" [innerHtml]="name"> </div>

                        <ax-button  type="light" size="sm" tooltip="Undo" [placement]="'bottom'" (onClick)="toggleTree()">
                          <i *ngIf="showTree" class="fas fa-chevron-right"></i>
                          <i *ngIf="!showTree" class="fas fa-folder-tree"></i>
                      </ax-button>

                    </div>
                    <div class="paper" #paper>
                        <ng-container *ngFor="let w of widgets" axf-widget-renderer [parent]="this" [widget]="w">
                        </ng-container>
                    </div>
                    <div class="breadcrumb">
                        <ul>
                            <li *ngFor="let item of docTreeItems" [class.disabled]="item.locked" (click)="handleBreadcrumbClick(item)">{{getTitle(item.config)}}</li>
                        </ul>
                    </div>
                </div>

                <div *ngIf="showTree" class="treeView-panel">
                  <div class="treeView-title">

                    <i class="fas fa-folder-tree"></i>
                    <h2>Explore Path</h2>
                  </div>
                  <div style="height: 50px;"></div>
                  <ng-template #treeViewList let-navigationItems>
                      <div *ngFor="let item of navigationItems" >
                        <div class="{{ getTreeClass(item) }}">
                          <button [ngClass]="{ 'treeViewSelector': true, 'selected': isItemSelected(item) }"
                                  (click)="chooseElement(item)">
                              <i [class]="item.icon" ></i>
                              {{ getTreeTitle(item) }}
                            </button>
                            <button *ngIf="hasWidgets(item)" class="treeViewActionButton" (click)="toggleTreeContent(item)">
                            <span *ngIf="hasWidgets(item)" class="child-count">
                                {{ item.options.widgets.length }}
                            </span>
                              <i [ngClass]="{ 'far fa-minus-square': shouldShowTree(item),
                                              'fas fa-plus-square': !shouldShowTree(item),
                                              'shade': true }">
                              </i>
                          </button>
                        </div>
                          <div *ngIf="shouldShowTree(item)" class="Treecontent">
                              <ng-container *ngTemplateOutlet="treeViewList; context:{ $implicit: item.options.widgets }"></ng-container>
                          </div>
                      </div>
                  </ng-template>

                  <ng-container *ngTemplateOutlet="treeViewList; context:{ $implicit: widgets }"></ng-container>
              </div>

            </ng-container>
        </div>
    </article>
    <article *ngIf="this.view=='form'" class="preview-container">
        <axf-viewer-frame [page]="widgets[0]" [orientation]="orientation"></axf-viewer-frame>
    </article>
</div>
<div  style="display: none;" *ngIf="printRendering" #print>
    <ng-container *ngFor="let w of widgets" axf-widget-renderer [widget]="w" [mode]="'print'">
    </ng-container>
</div>
