<div class="pnlCol">
    <table class="table table-bordered table-sm">
        <thead class="thead-dark">
            <tr>
                <th *ngFor="let col1 of columns">{{col1.title}}
                </th>
                <th>Default</th>
                <th style="min-width: 105px;">Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of items;let i = index">
                <td *ngFor="let col of columns">

                    <ng-container [ngSwitch]="col.type">
                        <ax-text-box class="nodeItem" [(text)]="item[col.fieldName]" *ngSwitchCase="'string'">
                        </ax-text-box>
                        <ax-text-box class="nodeItem" [(text)]="item[col.fieldName]" type="number"
                            *ngSwitchCase="'number'"></ax-text-box>
                        <ax-date-picker class="nodeItem" [(value)]="item[col.fieldName]" *ngSwitchCase="'date'">
                        </ax-date-picker>
                        <ax-check-box class="nodeItem" [(value)]="item[col.fieldName]" *ngSwitchCase="'boolean'"
                            label=" ">
                        </ax-check-box>
                        <ax-upload-file class="nodeItem" *ngSwitchCase="'image'" type="inline"
                            (onLoad)="handleUpload(item,col,$event)"></ax-upload-file>
                        <ax-selection-list class="nodeItem" [(selectedValues)]="item[col.fieldName]"
                            direction="horizontal" *ngSwitchCase="'selectionList'"
                            [mode]="getMode(item)">
                        </ax-selection-list>
                        <div class="signature" *ngSwitchCase="'signature'">
                            <signature-pad attr.id="signature{{i}}" [options]="signaturePadOptions" (onEndEvent)="drawComplete(i)">
                            </signature-pad>
                            <div class="clear-box">
                                <button (click)="onClearClick(i)" class="btn btn-outline-danger"><i
                                        class="fa fa-trash"></i></button>
                            </div>
                        </div>
                    </ng-container> 
                </td>
                <td>
                    <ax-check-box [(value)]="item.DefaultValue" label=" " >
                    </ax-check-box> 
                </td>
                <td>
                    <ax-button type="light" size="sm" tooltip="Delete" (onClick)="deleteClick(i)">
                        <i class="fas fa-trash danger"></i>
                    </ax-button>
                    <ax-button type="light" size="sm" tooltip="Up" (onClick)="upClick(i,item)">
                        <i class="fas fa-arrow-up primary"></i>
                    </ax-button>
                    <ax-button type="light" size="sm" tooltip="Down" (onClick)="downClick(i,item)">
                        <i class="fas fa-arrow-down primary"></i>
                    </ax-button>
                </td>
            </tr>
        </tbody>
    </table>
    <ax-button type="primary" size="sm" (onClick)="addItemClick()" tooltip="Add New">
        <i class="fas fa-plus"></i>&nbsp;
        <span>Add New Item</span>
    </ax-button>
</div>