import { AXPopupService } from '@acorex/ui';
import { ChangeDetectorRef, Component } from '@angular/core';
import { AXFProperyEditor } from '../../config/editor';
import { AXFRichTextComponent } from './rich-text.component';

@Component({
  templateUrl: './text.editor.html',
})
export class AXFTextEditorComponent extends AXFProperyEditor<string> {
  allowHtml: boolean = false;

  constructor(
    protected override cdr: ChangeDetectorRef,
    private popupService: AXPopupService
  ) {
    super(cdr);
  }

  handleShowEditor() {
    this.popupService
      .open(AXFRichTextComponent, {
        title: 'Rich Text',
        size: 'md',
        data: {
          data: this.value,
        },
      })
      .closed((c: any) => {
        this.handleValueChange(c.data);
      });
  }

  override handleValueChange(value: any) {
    super.handleValueChange(value);
  }

  ngAfterViewInit() {
    this.initiated = true;
  }
}
