<div *ngIf="visible" style="font-size: 14px!important;display: inline-block;width: 100%;line-height: 1;direction: ltr;">
    <div *ngFor="let item of dataSource.dataItems"
        [ngStyle]="{'width':(direction=='horizontal' && columns==1)?null:(100.0/columns)+'%','float':direction=='horizontal'?(alignment=='ltr'?'left':'right'):'unset','background':bgColor}">
        <ng-container *ngIf="viewType!='both'" [ngSwitch]="alignCheck">
            <div *ngSwitchCase="'left'" style="padding: 6px;position: relative;">
                <svg *ngIf="mode!='unselectable' && mode=='single'" className="svg" fill="currentColor"
                    preserveAspectRatio="xMidYMid meet" style="position: absolute;" [attr.height]="getNumberSize()"
                    [attr.width]="getNumberSize()" viewBox="0 0 50 50">
                    <circle className="radioOutline" cx="25" cy="25" r="23" fill="none" stroke="black" strokeWidth="3" />
                    <circle *ngIf="hasItem(item)"
                        className="radioDot" cx="25" cy="25" r="14" fill="black" />
                </svg>
                <span *ngIf="mode!='unselectable' && mode=='multiple'"
                    [ngStyle]="{'height': getSize(),'width': getSize()}" 
                    style="position: absolute; background-color: var(--white-color);border: 1px solid;
                    border-color: var(--black-color);border-radius: 0;box-sizing: border-box;">
                    <i *ngIf="hasItem(item)"
                        style="font-weight: bold;" [style.font-size]="getSize()">&#x2713;</i>
                </span>
                <img *ngIf="viewType=='image'" [style.margin-left]="getSize()"
                    [src]="getData(item,dataSource.columns[2].fieldName)  | resolveUrl | async" height="80px" width="60px">
                <div *ngIf="viewType=='text'"
                    [ngStyle]="{'margin-left': getSize(),'color': color,'font-size':getSize()}">
                    {{getData(item,dataSource.columns[1].fieldName) | word}}</div>
            </div>
            <div *ngSwitchCase="'top'" style="padding: 6px;position: relative;">
                <div [style.padding-bottom]="getSize()">
                    <svg *ngIf="mode!='unselectable' && mode=='single'" className="svg" fill="currentColor"
                    preserveAspectRatio="xMidYMid meet" style="position: absolute;" [attr.height]="getNumberSize()"
                    [attr.width]="getNumberSize()" viewBox="0 0 50 50">
                    <circle className="radioOutline" cx="25" cy="25" r="23" fill="none" stroke="black" strokeWidth="3" />
                    <circle *ngIf="hasItem(item)"
                        className="radioDot" cx="25" cy="25" r="14" fill="black" />
                    </svg>
                    <span *ngIf="mode!='unselectable' && mode=='multiple'"
                        [ngStyle]="{'height': getSize(),'width': getSize()}" 
                        style="position: absolute; background-color: var(--white-color);border: 1px solid;
                        border-color: var(--black-color);border-radius: 0;box-sizing: border-box;">
                        <i *ngIf="hasItem(item)"
                            style="font-weight: bold;" [style.font-size]="getSize()">&#x2713;</i>
                    </span>
                </div>
                <div>
                    <img *ngIf="viewType=='image'" [src]="getData(item,dataSource.columns[2].fieldName)  | resolveUrl | async"
                        height="80px" width="60px">
                    <span *ngIf="viewType=='text'" [style.color]="color"
                        [style.font-size]="getSize()">{{getData(item,dataSource.columns[1].fieldName)}}</span>
                </div>
            </div>
            <div *ngSwitchCase="'bottom'" style="padding: 6px;position: relative;">
                <div>
                    <img *ngIf="viewType=='image'" [src]="getData(item,dataSource.columns[2].fieldName)  | resolveUrl | async"
                        height="80px" width="60px">
                    <span *ngIf="viewType=='text'" [style.color]="color"
                        [style.font-size]="getSize()">{{getData(item,dataSource.columns[1].fieldName)}}</span>
                </div>
                <div [style.padding-bottom]="getSize()">
                    <svg *ngIf="mode!='unselectable' && mode=='single'" className="svg" fill="currentColor"
                    preserveAspectRatio="xMidYMid meet" style="position: absolute;" [attr.height]="getNumberSize()"
                    [attr.width]="getNumberSize()" viewBox="0 0 50 50">
                    <circle className="radioOutline" cx="25" cy="25" r="23" fill="none" stroke="black" strokeWidth="3" />
                    <circle *ngIf="hasItem(item)"
                        className="radioDot" cx="25" cy="25" r="14" fill="black" />
                    </svg>
                    <span *ngIf="mode!='unselectable' && mode=='multiple'"
                        [ngStyle]="{'height': getSize(),'width': getSize()}" 
                        style="position: absolute; background-color: var(--white-color);border: 1px solid;
                        border-color: var(--black-color);border-radius: 0;box-sizing: border-box;">
                        <i *ngIf="hasItem(item)"
                            style="font-weight: bold;" [style.font-size]="getSize()">&#x2713;</i>
                    </span>
                </div>
            </div>
            <div *ngSwitchCase="'right'" style="padding: 2px 2px 2px 10px;position: relative;display: flex;">
                <div style="float: left;margin-bottom: 10px;" [style.margin-right]="getSize()">
                    <img *ngIf="viewType=='image'" [src]="getData(item,dataSource.columns[2].fieldName)  | resolveUrl | async"
                        height="80px" width="60px">
                    <span *ngIf="viewType=='text'" [style.color]="color"
                        [style.font-size]="getSize()">{{getData(item,dataSource.columns[1].fieldName)}}</span>
                </div>
                <div style="position: absolute;" [style.right]="getSize()"> 
                    <svg *ngIf="mode!='unselectable' && mode=='single'" className="svg" fill="currentColor"
                    preserveAspectRatio="xMidYMid meet" style="position: absolute;" [attr.height]="getNumberSize()"
                    [attr.width]="getNumberSize()" viewBox="0 0 50 50">
                    <circle className="radioOutline" cx="25" cy="25" r="23" fill="none" stroke="black" strokeWidth="3" />
                    <circle *ngIf="hasItem(item)"
                        className="radioDot" cx="25" cy="25" r="14" fill="black" />
                    </svg>
                    <span *ngIf="mode!='unselectable' && mode=='multiple'"
                        [ngStyle]="{'height': getSize(),'width': getSize()}" 
                        style="position: absolute; background-color: var(--white-color);border: 1px solid;
                        border-color: var(--black-color);border-radius: 0;box-sizing: border-box;">
                        <i *ngIf="hasItem(item)"
                            style="font-weight: bold;" [style.font-size]="getSize()">&#x2713;</i>
                    </span>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="viewType=='both'" [ngSwitch]="alignCheckNew">
            <div *ngSwitchCase="'ItopCleftSright'">
                <div>
                    <img [src]="getData(item,dataSource.columns[2].fieldName)  | resolveUrl | async" height="80px" width="60px">
                </div>
                <div>                    
                    <svg *ngIf="mode!='unselectable' && mode=='single'" className="svg" fill="currentColor"
                    preserveAspectRatio="xMidYMid meet" style="position: absolute;" [attr.height]="getNumberSize()"
                    [attr.width]="getNumberSize()" viewBox="0 0 50 50">
                    <circle className="radioOutline" cx="25" cy="25" r="23" fill="none" stroke="black" strokeWidth="3" />
                    <circle *ngIf="hasItem(item)"
                        className="radioDot" cx="25" cy="25" r="14" fill="black" />
                    </svg>
                    <span *ngIf="mode!='unselectable' && mode=='multiple'"
                        [ngStyle]="{'height': getSize(),'width': getSize()}" 
                        style="position: absolute; background-color: var(--white-color);border: 1px solid;
                        border-color: var(--black-color);border-radius: 0;box-sizing: border-box;">
                        <i *ngIf="hasItem(item)"
                            style="font-weight: bold;" [style.font-size]="getSize()">&#x2713;</i>
                    </span>
                    <span
                        [ngStyle]="{'margin-left': getSize(),'color':color,'font-size': getSize()}">{{getData(item,dataSource.columns[1].fieldName)}}</span>
                </div>
            </div>
            <div *ngSwitchCase="'ItopCrightSleft'">
                <div>
                    <img [src]="getData(item,dataSource.columns[2].fieldName)  | resolveUrl | async" height="80px" width="60px">
                </div>
                <div [style.margin-right]="getSize()">
                    <span style="margin-right: 5px;" [style.color]="color"
                        [style.font-size]="getSize()">{{getData(item,dataSource.columns[1].fieldName)}}</span>  
                    <svg *ngIf="mode!='unselectable' && mode=='single'" className="svg" fill="currentColor"
                    preserveAspectRatio="xMidYMid meet" style="position: absolute;" [attr.height]="getNumberSize()"
                    [attr.width]="getNumberSize()" viewBox="0 0 50 50">
                    <circle className="radioOutline" cx="25" cy="25" r="23" fill="none" stroke="black" strokeWidth="3" />
                    <circle *ngIf="hasItem(item)"
                        className="radioDot" cx="25" cy="25" r="14" fill="black" />
                    </svg>
                    <span *ngIf="mode!='unselectable' && mode=='multiple'"
                        [ngStyle]="{'height': getSize(),'width': getSize()}" 
                        style="position: absolute; background-color: var(--white-color);border: 1px solid;
                        border-color: var(--black-color);border-radius: 0;box-sizing: border-box;">
                        <i *ngIf="hasItem(item)"
                            style="font-weight: bold;" [style.font-size]="getSize()">&#x2713;</i>
                    </span>
                </div>
            </div>
            <div *ngSwitchCase="'IbottomCleftSright'">
                <div>
                    <svg *ngIf="mode!='unselectable' && mode=='single'" className="svg" fill="currentColor"
                    preserveAspectRatio="xMidYMid meet" style="position: absolute;" [attr.height]="getNumberSize()"
                    [attr.width]="getNumberSize()" viewBox="0 0 50 50">
                    <circle className="radioOutline" cx="25" cy="25" r="23" fill="none" stroke="black" strokeWidth="3" />
                    <circle *ngIf="hasItem(item)"
                        className="radioDot" cx="25" cy="25" r="14" fill="black" />
                    </svg>
                    <span *ngIf="mode!='unselectable' && mode=='multiple'"
                        [ngStyle]="{'height': getSize(),'width': getSize()}" 
                        style="position: absolute; background-color: var(--white-color);border: 1px solid;
                        border-color: var(--black-color);border-radius: 0;box-sizing: border-box;">
                        <i *ngIf="hasItem(item)"
                            style="font-weight: bold;" [style.font-size]="getSize()">&#x2713;</i>
                    </span>
                    <span
                        [ngStyle]="{'margin-left': getSize(),'color':color,'font-size': getSize()}">{{getData(item,dataSource.columns[1].fieldName)}}</span>
                </div>
                <div>
                    <img [src]="getData(item,dataSource.columns[2].fieldName)  | resolveUrl | async" height="80px" width="60px">
                </div>
            </div>
            <div *ngSwitchCase="'IbottomCrightSleft'">
                <div [style.margin-right]="getSize()">
                    <span style="margin-right: 5px;" [style.color]="color"
                        [style.font-size]="getSize()">{{getData(item,dataSource.columns[1].fieldName)}}</span>
                    <svg *ngIf="mode!='unselectable' && mode=='single'" className="svg" fill="currentColor"
                    preserveAspectRatio="xMidYMid meet" style="position: absolute;" [attr.height]="getNumberSize()"
                    [attr.width]="getNumberSize()" viewBox="0 0 50 50">
                    <circle className="radioOutline" cx="25" cy="25" r="23" fill="none" stroke="black" strokeWidth="3" />
                    <circle *ngIf="hasItem(item)"
                        className="radioDot" cx="25" cy="25" r="14" fill="black" />
                    </svg>
                    <span *ngIf="mode!='unselectable' && mode=='multiple'"
                        [ngStyle]="{'height': getSize(),'width': getSize()}" 
                        style="position: absolute; background-color: var(--white-color);border: 1px solid;
                        border-color: var(--black-color);border-radius: 0;box-sizing: border-box;">
                        <i *ngIf="hasItem(item)"
                            style="font-weight: bold;" [style.font-size]="getSize()">&#x2713;</i>
                    </span>
                </div>
                <div>
                    <img [src]="getData(item,dataSource.columns[2].fieldName)  | resolveUrl | async" height="80px" width="60px">
                </div>
            </div>
            <div *ngSwitchCase="'StopImiddleCbottom'" [style.padding-bottom]="getSize()">
                <div><span [style.color]="color"
                        [style.font-size]="getSize()">{{getData(item,dataSource.columns[1].fieldName)}}</span>
                </div>
                <div>
                    <img [src]="getData(item,dataSource.columns[2].fieldName)  | resolveUrl | async" height="80px" width="60px">
                </div>
                <div> 
                    <svg *ngIf="mode!='unselectable' && mode=='single'" className="svg" fill="currentColor"
                    preserveAspectRatio="xMidYMid meet" style="position: absolute;" [attr.height]="getNumberSize()"
                    [attr.width]="getNumberSize()" viewBox="0 0 50 50">
                    <circle className="radioOutline" cx="25" cy="25" r="23" fill="none" stroke="black" strokeWidth="3" />
                    <circle *ngIf="hasItem(item)"
                        className="radioDot" cx="25" cy="25" r="14" fill="black" />
                    </svg>
                    <span *ngIf="mode!='unselectable' && mode=='multiple'"
                        [ngStyle]="{'height': getSize(),'width': getSize()}" 
                        style="position: absolute; background-color: var(--white-color);border: 1px solid;
                        border-color: var(--black-color);border-radius: 0;box-sizing: border-box;">
                        <i *ngIf="hasItem(item)"
                            style="font-weight: bold;" [style.font-size]="getSize()">&#x2713;</i>
                    </span>
                </div>
            </div>
            <div *ngSwitchCase="'ItopSmiddleCbottom'" [style.padding-bottom]="getSize()">
                <div>
                    <img [src]="getData(item,dataSource.columns[2].fieldName)  | resolveUrl | async" height="80px" width="60px">
                </div>
                <div><span [style.color]="color"
                        [style.font-size]="getSize()">{{getData(item,dataSource.columns[1].fieldName)}}</span>
                </div>
                <div>
                    <svg *ngIf="mode!='unselectable' && mode=='single'" className="svg" fill="currentColor"
                    preserveAspectRatio="xMidYMid meet" style="position: absolute;" [attr.height]="getNumberSize()"
                    [attr.width]="getNumberSize()" viewBox="0 0 50 50">
                    <circle className="radioOutline" cx="25" cy="25" r="23" fill="none" stroke="black" strokeWidth="3" />
                    <circle *ngIf="hasItem(item)"
                        className="radioDot" cx="25" cy="25" r="14" fill="black" />
                    </svg>
                    <span *ngIf="mode!='unselectable' && mode=='multiple'"
                        [ngStyle]="{'height': getSize(),'width': getSize()}" 
                        style="position: absolute; background-color: var(--white-color);border: 1px solid;
                        border-color: var(--black-color);border-radius: 0;box-sizing: border-box;">
                        <i *ngIf="hasItem(item)"
                            style="font-weight: bold;" [style.font-size]="getSize()">&#x2713;</i>
                    </span>
                </div>
            </div>

            <div *ngSwitchCase="'CtopSmiddleIbottom'">
                <div [style.padding-bottom]="getSize()">
                    <svg *ngIf="mode!='unselectable' && mode=='single'" className="svg" fill="currentColor"
                    preserveAspectRatio="xMidYMid meet" style="position: absolute;" [attr.height]="getNumberSize()"
                    [attr.width]="getNumberSize()" viewBox="0 0 50 50">
                    <circle className="radioOutline" cx="25" cy="25" r="23" fill="none" stroke="black" strokeWidth="3" />
                    <circle *ngIf="hasItem(item)"
                        className="radioDot" cx="25" cy="25" r="14" fill="black" />
                    </svg>
                    <span *ngIf="mode!='unselectable' && mode=='multiple'"
                        [ngStyle]="{'height': getSize(),'width': getSize()}" 
                        style="position: absolute; background-color: var(--white-color);border: 1px solid;
                        border-color: var(--black-color);border-radius: 0;box-sizing: border-box;">
                        <i *ngIf="hasItem(item)"
                            style="font-weight: bold;" [style.font-size]="getSize()">&#x2713;</i>
                    </span>
                </div>
                <div><span [style.color]="color"
                        [style.font-size]="getSize()">{{getData(item,dataSource.columns[1].fieldName)}}</span>
                </div>
                <div>
                    <img [src]="getData(item,dataSource.columns[2].fieldName)  | resolveUrl | async" height="80px" width="60px">
                </div>
            </div>
            <div *ngSwitchCase="'CtopImiddleSbottom'">
                <div [style.padding-bottom]="getSize()">  
                    <svg *ngIf="mode!='unselectable' && mode=='single'" className="svg" fill="currentColor"
                    preserveAspectRatio="xMidYMid meet" style="position: absolute;" [attr.height]="getNumberSize()"
                    [attr.width]="getNumberSize()" viewBox="0 0 50 50">
                    <circle className="radioOutline" cx="25" cy="25" r="23" fill="none" stroke="black" strokeWidth="3" />
                    <circle *ngIf="hasItem(item)"
                        className="radioDot" cx="25" cy="25" r="14" fill="black" />
                    </svg>
                    <span *ngIf="mode!='unselectable' && mode=='multiple'"
                        [ngStyle]="{'height': getSize(),'width': getSize()}" 
                        style="position: absolute; background-color: var(--white-color);border: 1px solid;
                        border-color: var(--black-color);border-radius: 0;box-sizing: border-box;">
                        <i *ngIf="hasItem(item)"
                            style="font-weight: bold;" [style.font-size]="getSize()">&#x2713;</i>
                    </span>
                </div>
                <div>
                    <img [src]="getData(item,dataSource.columns[2].fieldName)  | resolveUrl | async" height="80px" width="60px">
                </div>
                <div><span [style.color]="color"
                        [style.font-size]="getSize()">{{getData(item,dataSource.columns[1].fieldName)}}</span>
                </div>
            </div>
        </ng-container>
    </div>
</div>