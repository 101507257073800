import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
} from '@angular/core';
import { AXFFormatService } from '../../../../services/format.service';
import { AXFWidgetPrint } from '../../../config/widget';

@Component({
  template: '',
  selector: '[axf-text-block]',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXFTextBlockWidgetPrint extends AXFWidgetPrint {
  text!: string;

  constructor(
    private hostElement: ElementRef,
    private cdr: ChangeDetectorRef,
    private formatService: AXFFormatService
  ) {
    super();
  }

  override onRender(): void {
    this.applyStyle(this.hostElement.nativeElement);
    this.cdr.markForCheck();
  }

  override ngOnInit() {
    super.ngOnInit();
    this.hostElement.nativeElement.innerHTML = this.formatService.format(
      this.text,
      this
    );
    if ((this as any)['tag']) {
      this.hostElement.nativeElement.setAttribute('role', (this as any)['tag']);
    }
  }
}
