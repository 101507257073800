<ax-drop-down [text]="_text" label="{{ label }}"  #dropdown [fitParent]="false">
    <ax-color-box [(value)]="value" (valueChange)="handleValueChange($event)" (onColorSelect)="handleItemClick($event)"></ax-color-box>
    <ng-template #editorTemplate>
        <ng-container *ngIf="value; else elseTemplate">
            <div [style.background-color]="value" [style.color]="findTextColor(value)" style="padding:0px 5px;">{{value}}&nbsp;</div>
        </ng-container>
        <ng-template #elseTemplate>
            {{placeholder}}&nbsp;
        </ng-template>
    </ng-template>
</ax-drop-down>
<div class="validation-text" *ngIf="errorText">
    {{ errorText }}
</div>