import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  ViewEncapsulation,
} from '@angular/core';
import { AXFWidgetPickerService } from '../../../../services/template/picker.service';
import { AXFContextMenuItem, AXFWidgetDesigner } from '../../../config/widget';

@Component({
  selector: 'td',
  templateUrl: './table-cell-widget.designer.html',
  styleUrls: ['./table-cell-widget.designer.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AXFTableCellWidgetDesigner extends AXFWidgetDesigner {
  constructor(
    private el: ElementRef<HTMLTableCellElement>,
    private picker: AXFWidgetPickerService,
    private zone: NgZone,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  override onRender() {
    this.applyStyle(this.el.nativeElement);
    if ((this as any)['colspan']) {
      this.el.nativeElement.colSpan = (this as any)['colspan'];
    }
    if ((this as any)['rowspan']) {
      this.el.nativeElement.rowSpan = (this as any)['rowspan'];
    }
    this.cdr.markForCheck();
  }

  override getContextMenu() {
    const items: AXFContextMenuItem[] = super
      .getContextMenu()
      .filter(
        (c) =>
          c.action !== 'copy' &&
          c.action !== 'addElementBefore' &&
          c.action !== 'addElementAfter' &&
          c.action !== 'moveUp' &&
          c.action !== 'moveDown'
      );
    items.splice(
      3,
      0,
      ...[
        {
          text: 'Column',
          icon: 'fas fa-columns',
          widget: this,
          items: [
            {
              text: 'Insert column before',
              action: 'insertColumnBefore',
              icon: 'fas fa-chevron-left',
              widget: this,
            },
            {
              text: 'Insert column after',
              action: 'insertColumnAfter',
              icon: 'fas fa-chevron-right',
              widget: this,
            },
            {
              text: 'Delete column',
              icon: 'fas fa-trash',
              action: 'deleteColumn',
              widget: this,
            },
          ],
        },
        {
          text: 'Merge',
          icon: 'fas fa-object-group',
          widget: this,
          items: [
            {
              text: 'Merge with next',
              icon: 'fas fa-chevron-right',
              action: 'mergeAfter',
            },
            {
              text: 'Merge with blow',
              icon: 'fas fa-chevron-down',
              action: 'mergeBelow',
            },
          ],
          separator: true,
        },
      ]
    );
    return items;
  }

  insertColumnBefore() {
    this.insertColumn(0);
  }
  insertColumnAfter() {
    this.insertColumn(1);
  }

  private insertColumn(index: number) {
    const table = this.parent.parent;
    table.widgets.forEach((row: any) => {
      const col = this.widgetService?.resolve('table-cell');
      row.options.widgets.splice(this.findIndex() + index, 0, col);
      row.componentRef.refresh();
    });
  }

  private deleteColumn() {
    const table = this.parent.parent;
    table.widgets.forEach((row: any) => {
      row.options.widgets.splice(this.findIndex(), 1);
      row.componentRef.refresh();
    });
  }

  private mergeAfter() {
    let colspan = Number(this.config.options.colspan);
    if (!colspan) {
      colspan = 1;
    }

    const nextCol = this.parent.widgets[this.findIndex() + 1];
    if (nextCol) {
      this.config.options.colspan = ++colspan;
      this.config.options.widgets.push(...nextCol.options.widgets);
      this.parent.config.options.widgets.splice(this.findIndex() + 1, 1);
      this.refresh();
    }
  }

  private mergeBelow() {
    let rowspan = Number(this.config.options.rowspan);
    if (!rowspan) {
      rowspan = 1;
    }
    const nextRow = this.parent.parent.widgets[this.parent.findIndex() + 1];
    if (nextRow) {
      this.config.options.rowspan = ++rowspan;
      //! this.parent.config.options.widgets.splice(this.findIndex() + 1, 1);
      this.refresh();
    }
  }
}
