import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { AXFDataSourceOption } from '../../../../property-editor/editors/data-source/data-source.class';
import { AXFWidgetPrint } from '../../../config/widget';

@Component({
  templateUrl: './listsecond-input-widget.print.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXFListSecondInputWidgetPrint extends AXFWidgetPrint {
  @ViewChild('el') el!: ElementRef<HTMLElement>;

  dataSource!: AXFDataSourceOption;
  mode!: string;
  direction!: string;
  alignCheck!: string;
  alignCheckNew!: string;
  viewType!: string;
  override visible: boolean = true;
  printMode!: string;
  columns!: number;
  alignment!: string;
  color!: string;
  bgColor!: string;
  fontSize!: string;

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  hasItem(item: any) {
    if (
      (this.mode == 'multiple' &&
        this.value &&
        this.value
          .map((d: any) => d[(this.dataSource.columns[0] as any).fieldName])
          .indexOf(item[(this.dataSource.columns[0] as any).fieldName]) > -1) ||
      (this.mode == 'single' &&
        this.value &&
        this.value[(this.dataSource.columns[0] as any).fieldName] ==
          item[(this.dataSource.columns[0] as any).fieldName])
    )
      return true;
    else return false;
  }

  override onRender(): void {
    if (this.el) this.applyStyle(this.el.nativeElement);
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.refresh();
    if (this.value == undefined && this.dataSource.mode === 'manual') {
      let defaultVals = this.dataSource.dataItems?.filter(
        (s) => s.DefaultValue == true
      ) as any[];
      if (defaultVals.length > 0) {
        if (this.mode == 'multiple') this.value = defaultVals;
        else this.value = defaultVals[0];
      }
    }
    this.cdr.detectChanges();
  }

  override refresh() {
    if (this.dataSource.mode == 'remote') {
      this.dataService
        ?.getList(
          this.dataSource.dataSource?.name as string,
          this.dataSource.dataSource?.params
        )
        .then((c) => {
          this.dataSource.dataItems = c;
          this.cdr.detectChanges();
        });
    }
  }

  getNumberSize() {
    switch (this.fontSize) {
      case 'xx-small':
      case 'x-small':
        return 13;
      default:
      case 'smaller':
      case 'inherit':
        return 15;
      case 'small':
        return 20;
      case 'medium':
        return 25;
      case 'large':
        return 30;
      case 'larger':
        return 35;
      case 'x-large':
      case 'xx-large':
        return 40;
    }
  }

  getSize() {
    return this.getNumberSize() + 'px';
  }
}
