import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewEncapsulation,
} from '@angular/core';
import { UploadStructure } from '../../../../property-editor/editors/upload/upload.structure';
import { AXFWidgetDesigner } from '../../../config/widget';

@Component({
  selector: '[axf-widget-video]',
  templateUrl: './video-widget.designer.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXFVideoWidgetDesigner extends AXFWidgetDesigner {
  value!: UploadStructure;
  constructor(
    private el: ElementRef<HTMLElement>,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  override onRender(): void {
    this.applyStyle(this.el.nativeElement.querySelector('img') as HTMLElement);
    this.cdr.detectChanges();
  }
}
