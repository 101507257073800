<table #table [hidden]="!visible">
    <thead>
        <ng-container *ngFor="let w of getHeader()" axf-widget-renderer [widget]="w"
            [parent]="this" [mode]="'view'">
        </ng-container>
    </thead>
    <tbody>
        <ng-container *ngFor="let w of getBody()" axf-widget-renderer [widget]="w"
            [parent]="this" [mode]="'view'">
        </ng-container>
    </tbody>
</table>