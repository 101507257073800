<ng-container *ngIf="visible && !isLoading && value && value.srcData">
    <div class="video preview" [style.width.px]="width" [style.height.px]="height">
        <video controls [attr.src]="value.srcData | resolveUrl | async" [style.width.px]="value.orginalWidth" [style.height.px]="value.orginalHeight">
        </video>
        <div style="display: flex;">
            <button class="btn" (click)="remove()" style="position: absolute;top: 0;left: 0;"><i class="fa fa-trash danger"></i></button> 
        </div>
    </div>
</ng-container>
<ng-container *ngIf="visible && !isLoading && (!value || !value.srcData)">
    <div class="video" [style.width.px]="width" [style.height.px]="height">
        <div class="axf-video-upload-container" (click)="openFile()" style="cursor: pointer;">
            <svg class="box_icon" xmlns="http://www.w3.org/2000/svg" width="40" height="33" viewBox="0 0 50 43">
                <path
                    d="M48.4 26.5c-.9 0-1.7.7-1.7 1.7v11.6h-43.3v-11.6c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v13.2c0 .9.7 1.7 1.7 1.7h46.7c.9 0 1.7-.7 1.7-1.7v-13.2c0-1-.7-1.7-1.7-1.7zm-24.5 6.1c.3.3.8.5 1.2.5.4 0 .9-.2 1.2-.5l10-11.6c.7-.7.7-1.7 0-2.4s-1.7-.7-2.4 0l-7.1 8.3v-25.3c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v25.3l-7.1-8.3c-.7-.7-1.7-.7-2.4 0s-.7 1.7 0 2.4l10 11.6z">
                </path>
            </svg>
            <span><p [ngStyle]="{'font-size':width/10+'px'}">Click to Add Video or <a href="javascript:void(0)" (click)="onClickPaste($event)">Paste</a></p> </span>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="isLoading">
    <div class="skeleton" [style.width.px]="width" [style.height.px]="height">
    </div>
</ng-container>
<input type="file" multiple="false" (change)="uploadVideo($event)" style="display: none;" #fileInputV name="filedataV" />