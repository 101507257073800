import { AXBasePageComponent } from '@acorex/ui';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
@Component({
  styleUrls: ['./pdfmodal.page.scss'],
  templateUrl: './pdfmodal.page.html',
})
export class PdfModalPageComponent extends AXBasePageComponent {
  @ViewChild('el') el!: ElementRef<HTMLElement>;
  constructor() {
    super();
  }

  @Input()
  public value!: string;
  srcPdf: string = '';

  ngOnInit() {
    this.srcPdf = this.value;
  }

  async ngAfterViewInit() {
    let pdf64 = (this.value as any)['changingThisBreaksApplicationSecurity'];
    pdf64 = pdf64.replace('octet-stream', 'pdf');
    // console.log('image 1', pdf64);
    this.srcPdf = pdf64;
  }
}
