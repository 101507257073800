import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { AXFWidgetDesigner } from '../../../config/widget';

@Component({
  selector: '[axf-widget-number]',
  templateUrl: './number-input-widget.designer.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXFNumberInputWidgetDesigner extends AXFWidgetDesigner {
  placeholder!: string;

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  override onRender(): void {
    this.cdr.markForCheck();
  }
}
