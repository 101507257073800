import { Pipe, PipeTransform } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { AXFUrlResolverService } from '../../services/url-resolver.service';

@Pipe({ name: 'resolveUrl' })
export class ResolveUrlPipe implements PipeTransform {
  constructor(private resolverService: AXFUrlResolverService) {}

  transform(url: string): Promise<SafeUrl> {
    return this.resolverService.resolve(url);
  }
}
