import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AXFDataSourceOption } from '../../../../property-editor/editors/data-source/data-source.class';
import { AXFWidgetDesigner } from '../../../config/widget';

@Component({
  selector: '[axf-widget-text]',
  templateUrl: './grid-input-widget.designer.html',
  styleUrls: ['./grid-input-widget.designer.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXFGridInputWidgetDesigner extends AXFWidgetDesigner {
  @ViewChild('el') el!: ElementRef<HTMLElement>;

  value!: string[];
  dataSource!: AXFDataSourceOption;

  allowAddDelete!: boolean;

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  override onRender(): void {
    if (this.el) this.applyStyle(this.el.nativeElement);
    this.cdr.markForCheck();
  }
}
