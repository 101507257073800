import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AXFFormatService } from '../../../../services/format.service';
import { AXFWidgetPrint } from '../../../config/widget';

@Component({
  selector: '[axf-panel]',
  templateUrl: './panel-widget.print.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXFPanelWidgetPrint extends AXFWidgetPrint {
  caption!: string;
  constructor(private formatService: AXFFormatService) {
    super();
  }

  override ngOnInit() {
    super.ngOnInit();
    this.caption = this.formatService.format(this.caption, this);
  }
}
