import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { AXFDataSourceOption } from '../../../../property-editor/editors/data-source/data-source.class';
import { WidgetConfig } from '../../../../services/widget.service';
import { AXFWidgetPrint } from '../../../config/widget';
import { AXFRepeaterlWidgetFormula } from '../formula';

@Component({
  templateUrl: './repeater-widget.print.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXFRepeaterWidgetPrint extends AXFWidgetPrint {
  dataSource!: AXFDataSourceOption;
  showHeader!: boolean;
  headerRows!: WidgetConfig[];
  bodyRows!: WidgetConfig[];
  rowTemplate!: WidgetConfig;
  indexStart!: string;
  @ViewChild('table', { static: true }) table!: ElementRef<HTMLTableElement>;

  get formula() {
    return new AXFRepeaterlWidgetFormula(this);
  }

  constructor(
    protected cdr: ChangeDetectorRef,
    private hostElement: ElementRef
  ) {
    super();
    console.log('print lunched!!!!!!!!!!!!!');
  }

  override onRender() {
    // console.log('print render', this.rowTemplate);
    if (this.showHeader) {
      this.headerRows = this.widgets.filter((c) => c.options.isHeader === true);
    }
    this.rowTemplate = this.widgets.find(
      (c) => c.options.isHeader === false
    ) as WidgetConfig;
    this.bodyRows = this.allItems()
      .filter((s) => s != null)
      .map((c) => {
        const cloned = this.widgetService?.clone(
          this.rowTemplate
        ) as WidgetConfig;
        cloned.dataContext = c;
        return cloned;
      });
    setTimeout(() => {
      if (this.hostElement) {
        this.applyStyle(
          <HTMLTableElement>this.hostElement.nativeElement.firstElementChild
        );
      }
      this.cdr.detectChanges();
    }, 100);
  }

  override ngOnInit() {
    if (this.dataSource.mode === 'manual') {
      this.refresh();
    }
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    if (this.dataSource.mode === 'remote') {
      if (this.showHeader)
        this.headerRows = this.widgets.filter(
          (c) => c.options.isHeader === true
        );

      this.dataSource.dataSource?.params.forEach((p) => {
        if (typeof p.value === 'string' && p.value.startsWith('$')) {
          p.value = this.resolveProperty(p.value);
        }
      });
      this.dataService
        ?.getList(
          this.dataSource.dataSource?.name as string,
          this.dataSource.dataSource?.params
        )
        .then((items) => {
          if (items && items.length) {
            this.dataSource.dataItems = items;
            this.cdr.detectChanges();
            //this.refresh();
          }
        });
    }
    if ((this as any)['tag']) {
      this.hostElement.nativeElement.setAttribute('role', (this as any)['tag']);
    }
  }

  private allItems(): any[] {
    console.log('repeater Value print', this.value);
    // this.value = this.value?.filter((val) => !val.hasOwnProperty('btnDelete'));
    const model = this.dataService.getModel();
    model[this.config.options.name] = this.value;
    const result: any[] = [];
    if (
      !this.value &&
      ((this.dataService as any)['dataModel'] as any)[this.getPath() as string]
    )
      this.value = ((this.dataService as any)['dataModel'] as any)[
        this.getPath() as string
      ];
    // console.log('repeater Value', this.config.options.name, this.value);
    if (Array.isArray(this.value)) {
      result.push(...this.value);
    }
    let fixedCols = this.dataSource.columns
      .filter((d) => d.fillByUser == false)
      .map((d) => d.fieldName);
    if (Array.isArray(this.dataSource.dataItems)) {
      for (let i = 0; i < this.dataSource.dataItems.length; i++) {
        const item = this.dataSource.dataItems[i];
        if (result[i]) {
          if (this.value[i].hasOwnProperty('btnDelete')) result[i] = null;
          else
            Object.assign(
              result[i],
              fixedCols.reduce(function (o: any, k) {
                o[k as string] = item[k as string];
                return o;
              }, {})
            );
        } else {
          result[i] = item;
        }
      }
    }
    result
      .filter((s) => s != null)
      .forEach((f, i) => (f = Object.assign(f, this.setIndex(i))));
    return result;
  }

  trackbyFunc(index: number, item: WidgetConfig) {
    return index;
  }

  // override ngAfterViewInit() {
  //     super.ngAfterViewInit();
  //     this.cdr.detectChanges();
  // }

  setIndex(length: number) {
    let lLength = length + parseInt(this.indexStart) - 1;
    let lIndex = String.fromCharCode((lLength % 26) + 97);
    if (Math.floor(lLength / 26) > 0)
      lIndex = String.fromCharCode(Math.floor(lLength / 26) + 96) + lIndex;
    let nIndex = length + parseInt(this.indexStart);
    return { NIndex: nIndex, LIndex: lIndex };
  }
}
