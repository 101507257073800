import { AXHtmlUtil } from '@acorex/ui';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewEncapsulation,
} from '@angular/core';
import { WidgetConfig } from '../../../../services/widget.service';
import { AXFContextMenuItem, AXFWidgetDesigner } from '../../../config/widget';

@Component({
  selector: 'tr',
  templateUrl: './table-row-widget.designer.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AXFTableRowWidgetDesigner extends AXFWidgetDesigner {
  constructor(private el: ElementRef, private cdr: ChangeDetectorRef) {
    super();
  }

  override onRender() {
    this.applyStyle(this.el.nativeElement);
    this.cdr.markForCheck();
  }

  override getContextMenu() {
    const items: AXFContextMenuItem[] = [];
    items.push({
      text: 'Insert row above',
      icon: 'fas fa-chevron-up',
      action: 'insertRowBefore',
    });
    items.push({
      text: 'Insert row below',
      icon: 'fas fa-chevron-down',
      action: 'insertRowAfter',
    });
    items.push({
      text: 'Delete',
      separator: true,
      action: 'delete',
      icon: 'fas fa-trash',
    });

    return items;
  }

  insertRowBefore() {
    this.insertRow(0);
  }

  insertRowAfter() {
    this.insertRow(1);
  }

  private insertRow(i: number) {
    const index = this.findIndex();
    let row = this.widgetService?.resolve('table-row') as WidgetConfig;
    row.options = { widgets: [], uid: AXHtmlUtil.getUID() };
    this.getColumns(index).forEach((col) => {
      row.options.widgets.push(col);
    });
    console.log('row ready:', row);
    this.parent.widgets.splice(index + i, 0, row);
    this.parent.refresh();
  }

  private getColumns(index: number): WidgetConfig[] {
    const result: WidgetConfig[] = [];
    const currentRow = this.parent.widgets[index];
    currentRow.options.widgets.forEach((col: any) => {
      result.push(this.widgetService?.clone(col) as WidgetConfig);
    });
    return result;
  }
}
