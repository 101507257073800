import { Component, Input, ViewChild } from '@angular/core';
import { AXDropDownComponent } from '../dropdown/dropdown.component';
import { AXColorUtil } from './color.class';
import { AXColorBoxComponent } from './colorbox.component';

@Component({
  selector: 'ax-color-picker',
  templateUrl: './colorpicker.component.html',
})
export class AXColorPickerComponent extends AXColorBoxComponent {
  @ViewChild('dropdown', { static: true })
  dropdown!: AXDropDownComponent;
  @Input() placeholder: string = '';
  @Input() showClear: boolean = false;

  @Input() label: string = '';

  _text: string = '';
  constructor() {
    super();
  }

  override focus(): void {
    this.dropdown.focus();
  }

  handleValueChange(c: string) {}

  findTextColor(color: string) {
    if (!color) return '#000';
    return !(AXColorUtil.contrastToWhite(color) > 2.0) ? '#000' : '#fff';
  }

  handleItemClick(e: any) {
    this.dropdown.close();
  }
}
