<table  #table class="axf-widget-move-handler">
    <thead *ngIf="showHeader" >
        <ng-container *ngFor="let w of getHeader()" axf-widget-renderer [widget]="w"
        [parent]="this" >
    </ng-container>
    </thead>
    <tbody *ngIf="widgets && widgets.length">
        <ng-container *ngFor="let w of getBody()" axf-widget-renderer [widget]="w"
            [parent]="this" >
        </ng-container>
    </tbody> 
</table>