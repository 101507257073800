import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { AXFProperyEditor } from '../../config/editor';

@Component({
  templateUrl: './checkbox.editor.html',
  styleUrls: ['./checkbox.editor.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXFCheckboxEditorComponent extends AXFProperyEditor<boolean> {
  constructor(protected override cdr: ChangeDetectorRef) {
    super(cdr);
  }

  ngAfterViewInit() {
    this.initiated = true;
  }
}
