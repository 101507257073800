import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
} from '@angular/core';
import { AXFBoxStyleValue } from '../../../../property-editor/editors/style/box-style/box-style.class';
import { AXFWidgetDesigner } from '../../../config/widget';

@Component({
  selector: '[axf-widget-col]',
  templateUrl: './col-widget.designer.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'axf-drop-zone' },
})
export class AXFColWidgetDesigner extends AXFWidgetDesigner {
  size: number = 1;
  color!: string;
  bgColor!: string;
  boxStyle!: AXFBoxStyleValue;

  constructor(private hostElement: ElementRef, private cdr: ChangeDetectorRef) {
    super();
  }

  override onRender(): void {
    const el: HTMLElement = this.hostElement.nativeElement as HTMLElement;
    el.classList.forEach((c) => {
      if (c.startsWith('col-md')) {
        el.classList.remove(c);
      }
    });
    el.classList.add('col-sm-12', `col-md-${this.size}`);
    this.applyStyle(this.hostElement.nativeElement);
    this.cdr.markForCheck();
    this.parent.refresh();
  }

  override getContextMenu() {
    return super
      .getContextMenu()
      .filter(
        (c) => c.action !== 'addElementBefore' && c.action !== 'addElementAfter'
      );
  }
}
