import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
} from '@angular/core';
import { AXFWidgetDesigner } from '../../../config/widget';

@Component({
  selector: '[axf-button]',
  templateUrl: './button-widget.designer.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXFButtonWidgetDesigner extends AXFWidgetDesigner {
  type!: string;
  text!: string;
  size!: string;

  constructor(
    private cdr: ChangeDetectorRef,
    private hostElement: ElementRef<HTMLDivElement>
  ) {
    super();
  }

  override onRender() {
    this.applyStyle(this.hostElement.nativeElement);
    this.cdr.markForCheck();
  }
}
