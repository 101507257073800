import { ACoreXUIModule } from '@acorex/ui';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { AXFEditorService } from '../../../services/editor.service';
import { AXFRichTextComponent } from './rich-text.component';
import { AXFTextEditorComponent } from './text.editor';

@NgModule({
  declarations: [AXFTextEditorComponent, AXFRichTextComponent],
  imports: [CommonModule, ACoreXUIModule, FormsModule, AngularEditorModule],
  exports: [AXFTextEditorComponent],
  entryComponents: [AXFTextEditorComponent],
  providers: [],
})
export class AXFTextEditorModule {
  constructor(service: AXFEditorService) {
    service.register('TextEditor', AXFTextEditorComponent);
  }
}
