import { ACoreXUIModule } from '@acorex/ui';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AXFEditorService } from '../../../../services/editor.service';
import { AXFBoxStyleEditorComponent } from './box-style.editor';

@NgModule({
  declarations: [AXFBoxStyleEditorComponent],
  imports: [CommonModule, ACoreXUIModule],
  exports: [AXFBoxStyleEditorComponent],
  entryComponents: [AXFBoxStyleEditorComponent],
  providers: [],
})
export class AXFBoxStyleEditorModule {
  constructor(service: AXFEditorService) {
    service.register('BoxStyleEditor', AXFBoxStyleEditorComponent);
  }
}
