import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { AXFWidgetDesigner } from '../../../config/widget';

@Component({
  selector: '[axf-widget-text]',
  templateUrl: './text-area-widget.designer.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXFTextAreaWidgetDesigner extends AXFWidgetDesigner {
  text!: string;
  placeholder: string = '';
  rows!: number;

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  override onRender(): void {
    this.cdr.markForCheck();
  }
}
