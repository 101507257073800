import {
  AXBasePageComponent,
  AXSelectionListComponent,
  ClosingAction,
} from '@acorex/ui';
import { Component, ViewChild } from '@angular/core';
import { AXFAnswerOption } from './answer.class';

@Component({
  templateUrl: './answeritem.editor.html',
  styleUrls: ['./answeritem.editor.scss'],
})
export class AXIAnswerItemEditorComponent extends AXBasePageComponent {
  @ViewChild('modeSelection') modeSelection!: AXSelectionListComponent;

  modeItems: any[] = [
    { value: 'text', text: 'Text' },
    { value: 'single', text: 'Single' },
    { value: 'multiple', text: 'Multiple' },
  ];
  value!: AXFAnswerOption;

  constructor() {
    super();
  }

  ngOnInit() {}

  override onClosing(e: ClosingAction) {
    e.data = {
      value: this.value,
    };
    e.resolve();
  }

  handleModeChange(v: any[]) {
    if (v && v[0].value !== this.value.mode) {
      this.value.mode = v[0].value;
      if (this.value.mode === 'text') {
        this.value.dataItems = [];
      } else {
        if (this.value.dataItems == null) this.value.dataItems = [];
        let param: any = { id: new Date().getTime(), text: 'Sample' };
        this.value.dataItems.push(param);
      }
    }
  }

  deleteClick(ind: number) {
    this.value?.dataItems?.splice(ind, 1);
  }

  upClick(ind: number, item: any) {
    if (ind > 0 && this.value && this.value.dataItems) {
      let temp = this.value.dataItems[ind - 1];
      this.value.dataItems[ind - 1] = item;
      this.value.dataItems[ind] = temp;
    }
  }

  downClick(ind: number, item: any) {
    if (
      this.value &&
      this.value.dataItems &&
      ind < this.value.dataItems.length - 1
    ) {
      let temp = this.value.dataItems[ind + 1];
      this.value.dataItems[ind + 1] = item;
      this.value.dataItems[ind] = temp;
    }
  }

  addItemClick() {
    if (!this.value.dataItems) this.value.dataItems = [];
    let param: any = { id: new Date().getTime(), text: 'Sample' };
    this.value.dataItems.push(param);
  }

  setCondition(ind: number) {}
}
