import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AXValidatableComponent } from '../base.component';
import { AXDropDownComponent } from '../dropdown/dropdown.component';
import { IValidationRuleResult } from '../validation/validation.classs';
import { AXDateTime, CalendarType } from './datetime';

@Component({
  selector: 'ax-date-picker',
  templateUrl: './datepicker.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class AXDatePickerComponent extends AXValidatableComponent {
  @ViewChild('dropdown', { static: true }) dropdown!: AXDropDownComponent;
  @Input() placeholder: string = '';
  @Input() showClear: boolean = false;

  @Input() label: string = '';

  @Input()
  type: CalendarType = 'gregorian';

  @Input()
  displayFormat: string = 'DD/MM/YYYY';

  _renderPicker: boolean = false;

  model: any = null;
  _text: string = '';

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  selectToday() {}

  clear(): void {}

  focus(): void {
    this.dropdown.focus();
  }

  @Output()
  valueChange: EventEmitter<AXDateTime | null> =
    new EventEmitter<AXDateTime | null>();

  private _value!: AXDateTime | null;
  @Input()
  public get value(): AXDateTime | null {
    return this._value;
  }
  public set value(v: AXDateTime | null) {
    if (v != this._value && !v.equal(this._value)) {
      this._value = v;
      this.valueChange.emit(v);
      this._text = v.format(this.displayFormat);
    }
  }

  validate(): Promise<IValidationRuleResult> {
    return new Promise<IValidationRuleResult>((resolve) => {
      if (!this.validator) {
        resolve({ result: true });
      } else {
        resolve({ result: false });
      }
    });
  }
  onDateChange() {
    this.dropdown.close();
  }

  handleOnOpen() {
    this._renderPicker = true;
    this.cdr.detectChanges();
  }
}
