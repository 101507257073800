import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { AXFWidgetPickerService } from '../../../../services/template/picker.service';
import { AXFWidgetDesigner } from '../../../config/widget';

@Component({
  selector: '[axf-panel]',
  templateUrl: './panel-widget.designer.html',
  styleUrls: ['./panel-widget.designer.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXFPanelWidgetDesigner extends AXFWidgetDesigner {
  caption!: string;
  allowCollapse!: boolean;
  collapsed!: boolean;

  constructor(
    private picker: AXFWidgetPickerService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  override onRender() {
    this.cdr.markForCheck();
  }
}
