import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { AXFWidgetPrint } from '../../../config/widget';

@Component({
  templateUrl: './checkbox-input-widget.print.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXFCheckboxInputWidgetPrint extends AXFWidgetPrint {
  label!: string;
  size: number = 20;
  textAlign!: string;
  fontSize!: string;
  color!: string;
  bgColor!: string;
  defaultValue!: boolean;
  @ViewChild('el', { static: true }) el!: ElementRef<HTMLElement>;
  @ViewChild('chk', { static: true }) chk!: ElementRef<HTMLElement>;
  constructor(protected cdr: ChangeDetectorRef) {
    super();
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    if (this.value === undefined && this.defaultValue !== undefined) {
      this.value = this.defaultValue;
    }
    if (this.el) this.applyStyle(this.el.nativeElement);
    if ((this as any)['tag'])
      this.chk.nativeElement.setAttribute('role', (this as any)['tag']);
    this.cdr.detectChanges();
  }

  override onRender(): void {
    if (
      this.label.match(/\[(.*?)\]/g) &&
      (this as any)['rIndex'] >= 0 &&
      (this as any)['dataContext'] != undefined
    ) {
      (this.label.match(/\[(.*?)\]/g) as Array<string>).forEach((f) => {
        let sw = f.substring(1, f.length - 1);
        if ((this as any)['dataContext'].hasOwnProperty(sw)) {
          this.label = this.label.replace(f, (this as any)['dataContext'][sw]);
          this.cdr.detectChanges();
        }
      });
    }
    this.cdr.detectChanges();
  }

  getSize(font: string) {
    switch (font) {
      case 'x-small':
      case 'xx-small':
        return 12;
      case 'smaller':
      case 'inherit':
        return 15;
      default:
      case 'small':
        return 20;
      case 'medium':
        return 25;
      case 'large':
        return 30;
      case 'larger':
        return 35;
      case 'x-large':
      case 'xx-large':
        return 40;
    }
  }
}
