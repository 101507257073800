import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { AXFDataService } from '../../../services/data.service';
import { AXFProperyEditor } from '../../config/editor';

@Component({
  template: `
    <ax-select-box
      [allowSearch]="false"
      [items]="items"
      [mode]="mode"
      [textField]="textField"
      [valueField]="valueField"
      [selectedValues]="value"
      (selectedValuesChange)="handleValueChange($event)"
    >
    </ax-select-box>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXFDropdownEditorComponent extends AXFProperyEditor<any> {
  items: any[] = [];
  textField: string = 'title';
  valueField: string = 'value';
  dataSource!: string;
  mode: 'single' | 'multiple' = 'single';

  constructor(
    protected override cdr: ChangeDetectorRef,
    private dataService: AXFDataService
  ) {
    super(cdr);
  }

  ngAfterViewInit(): void {
    if (this.dataSource) {
      this.dataService.getList(this.dataSource).then((items: any[]) => {
        this.items = items;
        this.cdr.markForCheck();
      });
    }
    this.initiated = true;
  }

  override handleValueChange(v: any) {
    this.value = v;
  }
}
