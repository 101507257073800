import { Component, Input, ViewEncapsulation } from '@angular/core';
import { AXTextInputBaseComponent } from '../base.component';

@Component({
  templateUrl: './textbox.component.html',
  selector: 'ax-text-box',
  encapsulation: ViewEncapsulation.None,
})
export class AXTextBoxComponent extends AXTextInputBaseComponent {
  @Input() mask: any;
  get maskInner() {
    return this.mask ? this.mask : false;
  }
}
