import { AXPopupService } from '@acorex/ui';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AXFDataSourceOption } from '../../../../property-editor/editors/data-source/data-source.class';
import { AXFValueWidgetView } from '../../../config/widget';
import { LovModalPage } from '../lovmodal.page';

@Component({
  selector: '[axf-widget-text]',
  templateUrl: './lov-input-widget.view.html',
  styleUrls: ['./lov-input-widget.view.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXFLovInputWidgetView extends AXFValueWidgetView {
  @ViewChild('el') el!: ElementRef<HTMLElement>;

  dataSource!: AXFDataSourceOption;
  mode!: string;
  showSelected!: boolean;
  columnName: string = '';
  title!: string;

  override readonly: boolean = true;
  constructor(
    protected override cdr: ChangeDetectorRef,
    private popupService: AXPopupService
  ) {
    super(cdr);
  }

  override onRender(): void {
    this.cdr.detectChanges();
  }

  override ngOnInit() {
    let colBase: any = this.dataSource.columns.find((w) => w.isDisplay);
    if (!colBase) colBase = this.dataSource.columns[0];
    this.columnName = colBase.fieldName;
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    if (this.dataSource.mode === 'remote') {
      this.dataSource.dataSource?.params.forEach((p) => {
        if (typeof p.value === 'string' && p.value.startsWith('$')) {
          p.value = this.resolveProperty(p.value);
        }
      });
      this.dataService
        ?.getList(
          this.dataSource.dataSource?.name as string,
          this.dataSource.dataSource?.params
        )
        .then((items) => {
          if (items && items.length) {
            this.dataSource.dataItems = items;
            this.refresh();
          }
        });
    } else {
      this.refresh();
    }
  }

  showPopup() {
    this.popupService
      .open(LovModalPage, {
        title: this.title,
        size: 'lg',
        data: {
          ww: this,
        },
      })
      .closed((e: any) => {
        if (e.data) {
          this.value = e.data;
          this.cdr.markForCheck();
        }
      });
  }

  showText(val: any) {
    if (!val) return '';

    let colBase: any = this.dataSource.columns.find((w) => w.isDisplay);
    if (!colBase) colBase = this.dataSource.columns[0];

    if (Array.isArray(val))
      return val.map((c) => c[colBase.fieldName]).join(', ');
    else return val[colBase.fieldName];
  }

  delete(item: any) {
    let ind = this.value.findIndex(
      (w) => w[this.columnName] == item[this.columnName]
    );
    if (ind > -1) {
      this.value.splice(ind, 1);
    }
  }
}
