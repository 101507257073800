import { Component, Input } from '@angular/core';
import { AXBaseComponent } from '../base.component';

@Component({
  selector: 'ax-progress-bar',
  templateUrl: './progressbar.component.html',
})
export class AXProgressBarComponent extends AXBaseComponent {
  @Input() progress: number = 0;
}
