<div #el [hidden]="!visible">
    <div (click)="showPopup()">
        <ax-text-box [text]="showText(value)" > 
            <ax-button  >
                 <i class="fas fa-list"></i> 
            </ax-button>
        </ax-text-box>
    </div>
    
    <table class="selecteditems" *ngIf="showSelected"> 
        <tr *ngIf="value && value.length">
            <td colspan="2">{{title}}</td>
        </tr>
        <tr *ngFor="let item of value">
            <td>{{item[columnName]}}</td>
            <td class="tdDel">
                <a (click)="delete(item)">
                    <i class="fas fa-times"></i>
                </a>
            </td>
        </tr>
    </table>
</div>