import {
  AXBasePageComponent,
  AXUploadFileLoadEvent,
  ClosingAction,
} from '@acorex/ui';
import { Component } from '@angular/core';
import { AXFDataSourceColumnOption } from './data-source.class';

@Component({
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.scss'],
})
export class AXIDataItemEditorComponent extends AXBasePageComponent {
  public columns: any[] = [];
  public items: any[] = [];
  signaturePadOptions: any;

  constructor() {
    super();
  }

  ngOnInit() {
    this.signaturePadOptions = {
      canvasWidth: 150,
      canvasHeight: 100,
    };
  }

  override onClosing(e: ClosingAction) {
    e.data = {
      items: this.items,
      columns: this.columns,
    };
    e.resolve();
  }

  handleUpload(
    item: any,
    col: AXFDataSourceColumnOption,
    file: AXUploadFileLoadEvent
  ) {
    if (col.fieldName) item[col.fieldName] = file.data;
  }

  deleteClick(ind: number) {
    this.items.splice(ind, 1);
  }

  upClick(ind: number, item: any) {
    if (ind > 0) {
      let temp = this.items[ind - 1];
      this.items[ind - 1] = item;
      this.items[ind] = temp;
    }
  }

  downClick(ind: number, item: any) {
    if (ind < this.items.length - 1) {
      let temp = this.items[ind + 1];
      this.items[ind + 1] = item;
      this.items[ind] = temp;
    }
  }

  addItemClick() {
    if (!this.items) this.items = [];
    let param: any = { id: new Date().getTime(), DefaultValue: true };
    this.items.push(param);
  }

  drawComplete(ind: number) {
    //this.items[ind].Value = this.signaturePad.toDataURL();
  }

  onClearClick(ind: number) {
    // var canvas =document.querySelector("#signature"+ind+"  canvas");
    // var fdf= new ElementRef(canvas);
    // var sig = new SignaturePad(fdf);
    // sig.clear();
    // this.items[ind].Value = null;
  }

  getMode(item: any): 'single' | 'multiple' {
    return item.mode ? 'multiple' : 'single';
  }
}
