import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AXFEditorService } from '../../services/editor.service';
import { AXFEditorRendererDirective } from '../editor-renderer.directive';
import { AXFAnswerEditorModule } from '../editors/answer/answer.module';
import { AXFCheckboxEditorModule } from '../editors/checkbox/checkbox.module';
import { AXFColorEditorModule } from '../editors/color/color.module';
import { AXFDataSourceEditorModule } from '../editors/data-source/data-source.module';
import { AXFDropdownEditorModule } from '../editors/dropdown/dropdown.module';
import { AXFEventEditorModule } from '../editors/event/event.module';
import { AXFFormulaEditorModule } from '../editors/formula/formula.module';
import { AXFSelectionEditorModule } from '../editors/selection/selection.module';
import { AXFSignatureEditorModule } from '../editors/signature/signature.module';
import { AXFBoxStyleEditorModule } from '../editors/style/box-style/box-style.module';
import { AXFTextEditorModule } from '../editors/text/text.module';
import { AXFUploadEditorModule } from '../editors/upload/upload.module';
import { AXFValidationEditorModule } from '../editors/validation/validation.module';

const WDIGET_MODULES = [
  AXFBoxStyleEditorModule,
  AXFTextEditorModule,
  AXFSelectionEditorModule,
  AXFDropdownEditorModule,
  AXFColorEditorModule,
  AXFUploadEditorModule,
  AXFCheckboxEditorModule,
  AXFSignatureEditorModule,
  AXFEventEditorModule,
  AXFDataSourceEditorModule,
  AXFValidationEditorModule,
  AXFFormulaEditorModule,
  AXFAnswerEditorModule,
];

@NgModule({
  declarations: [AXFEditorRendererDirective],
  imports: [CommonModule, HttpClientModule, ...WDIGET_MODULES],
  exports: [AXFEditorRendererDirective],
  providers: [AXFEditorService],
})
export class AXFEditorsModule {}
