import { ACoreXUIModule } from '@acorex/ui';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AXFEditorService } from '../../../services/editor.service';
import { AXFUploadEditorComponent } from './upload.editor';

@NgModule({
  declarations: [AXFUploadEditorComponent],
  imports: [CommonModule, ACoreXUIModule],
  exports: [AXFUploadEditorComponent],
  entryComponents: [AXFUploadEditorComponent],
  providers: [],
})
export class AXFUploadEditorModule {
  constructor(service: AXFEditorService) {
    service.register('UploadEditor', AXFUploadEditorComponent);
  }
}
