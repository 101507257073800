import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { AXFWidgetDesigner } from '../../../config/widget';

@Component({
  selector: '[axf-widget-time]',
  templateUrl: './time-input-widget.designer.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXFTimeInputWidgetDesigner extends AXFWidgetDesigner {
  value: string = '00:00';
  constructor() {
    super();
  }
}
