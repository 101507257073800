import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { AXFWidgetDesigner } from '../../../config/widget';

@Component({
  selector: '[axf-widget-link]',
  templateUrl: './link-input-widget.designer.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXFLinkInputWidgetDesigner extends AXFWidgetDesigner {
  placeholder!: string;

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  override onRender(): void {
    this.cdr.markForCheck();
  }
}
