import {
  AfterContentInit,
  Attribute,
  Component,
  ComponentFactoryResolver,
  ContentChildren,
  QueryList,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AXTabComponent } from '../tab/tab.component';
import { DynamicTabsDirective } from './dynamic-tabs.directive';

@Component({
  selector: 'ax-tab-view',
  templateUrl: './tabview.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class AXTabViewComponent implements AfterContentInit {
  dynamicTabs: AXTabComponent[] = [];

  @ContentChildren(AXTabComponent) tabs!: QueryList<AXTabComponent>;

  @ViewChild(DynamicTabsDirective, { static: true })
  dynamicTabPlaceholder!: DynamicTabsDirective;

  constructor(
    private _componentFactoryResolver: ComponentFactoryResolver,
    @Attribute('fullWidth') public fullWidth: boolean = false
  ) {}

  ngAfterContentInit() {
    // get all active tabs
    const activeTabs = this.tabs.filter((tab) => tab.active);
    if (activeTabs.length === 0) {
      this.selectTab(this.tabs.first);
    }
  }

  openTab(
    title: string,
    template: any,
    isCloseable = false,
    icon = null,
    data = null
  ) {
    const componentFactory =
      this._componentFactoryResolver.resolveComponentFactory(AXTabComponent);

    // fetch the view container reference from our anchor directive
    const viewContainerRef = this.dynamicTabPlaceholder.viewContainer;

    // create a component instance
    const componentRef = viewContainerRef.createComponent(componentFactory);

    // set the according properties on our component instance
    const instance: AXTabComponent = componentRef.instance as AXTabComponent;
    instance.caption = title;
    instance.template = template;
    instance.dataContext = data;
    instance.isCloseable = isCloseable;
    instance.icon = icon;

    // remember the dynamic component for rendering the
    // tab navigation headers
    this.dynamicTabs.push(componentRef.instance as AXTabComponent);

    // set it active
    this.selectTab(this.dynamicTabs[this.dynamicTabs.length - 1]);
  }

  selectTab(tab: AXTabComponent) {
    // deactivate all tabs
    this.tabs.toArray().forEach((tab) => (tab.active = false));
    this.dynamicTabs.forEach((tab) => (tab.active = false));

    // activate the tab the user has clicked on.
    tab.active = true;
  }

  closeTab(tab: AXTabComponent) {
    for (let i = 0; i < this.dynamicTabs.length; i++) {
      if (this.dynamicTabs[i] === tab) {
        // remove the tab from our array
        this.dynamicTabs.splice(i, 1);

        // destroy our dynamically created component again
        let viewContainerRef = this.dynamicTabPlaceholder.viewContainer;
        // let viewContainerRef = this.dynamicTabPlaceholder;
        viewContainerRef.remove(i);

        // set tab index to 1st one
        this.selectTab(this.tabs.first);
        break;
      }
    }
  }

  closeActiveTab() {
    const activeTabs = this.dynamicTabs.filter((tab) => tab.active);
    if (activeTabs.length > 0) {
      // close the 1st active tab (should only be one at a time)
      this.closeTab(activeTabs[0]);
    }
  }
}
