import { AXValidationRule } from '@acorex/ui';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { AXFProperyEditor } from '../../config/editor';
import { AXFValidatorProp } from './validation.class';

@Component({
  templateUrl: './validation.editor.html',
  styleUrls: ['./validation.editor.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AXFValidationEditorComponent
  extends AXFProperyEditor<AXFValidatorProp>
  implements OnInit
{
  required: boolean = false;

  constructor(protected override cdr: ChangeDetectorRef) {
    super(cdr);
  }

  ngOnInit(): void {
    if (!this.value) {
      this.value = new AXFValidatorProp();
    } else {
      const v: AXFValidatorProp = new AXFValidatorProp();
      Object.assign(v, this.value);
      this.value = v;
    }
  }

  ngAfterViewInit() {
    this.initiated = true;
  }

  ngDoCheck() {
    this.required = this.value.items.some((c) => c.type === 'required');
  }

  handleRequiredChange(e: any) {
    if (e) {
      const rule1 = new AXValidationRule();
      rule1.type = 'required';
      rule1.message = 'Required';
      this.value.items.push(rule1);
    } else {
      this.value.items = this.value.items.filter((c) => c.type !== 'required');
    }
    super.handleValueChange(this.value);
  }
}
