import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { AXFTemplateService } from '../../../../services/template/template.service';
import { AXFWidgetPrint } from '../../../config/widget';

@Component({
  templateUrl: './outlet-widget.print.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXFOutletWidgetPrint extends AXFWidgetPrint implements OnInit {
  widgetId!: string;
  isLoading: boolean = true;
  loadingTimer: number = 0;
  loadingInterval: number = -1;

  constructor(
    private cdr: ChangeDetectorRef,
    private templateService: AXFTemplateService
  ) {
    super();
    this.loadingTimer = 0;
    this.loadingInterval = window.setInterval(() => {
      this.loadingTimer++;
      this.cdr.markForCheck();
    }, 700);
  }

  override ngOnInit() {
    if (this.widgets == null || this.widgets.length == 0) {
      this.templateService.get(this.widgetId).then((c: any) => {
        window.clearInterval(this.loadingInterval);
        this.isLoading = false;
        this.widgets = this.widgetService?.parse(c.template)?.options.widgets;
        this.cdr.detectChanges();
        //this.refresh();
      });
    }
  }

  override refresh(): void {
    this.cdr.detectChanges();
  }
}
