import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { AXFProperyEditor } from '../../../config/editor';
import { AXFBoxStyleValue } from './box-style.class';

@Component({
  templateUrl: './box-style.editor.html',
  styleUrls: ['./box-style.editor.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AXFBoxStyleEditorComponent
  extends AXFProperyEditor<AXFBoxStyleValue>
  implements OnInit
{
  private prevValue = new AXFBoxStyleValue();

  constructor(protected override cdr: ChangeDetectorRef) {
    super(cdr);
  }

  ngOnInit(): void {
    if (!this.value) this.value = new AXFBoxStyleValue();
  }

  ngAfterViewInit() {
    this.initiated = true;
  }

  handleInnerValueChange(e: string, style: string, position: string) {
    // console.log('e', e);
    // console.log('style', style);
    // console.log(style, position, ':', e);
    // let preValue: any = this.prevValue;
    // console.log(preValue['border']);

    if (position == 'top' && this.value[style].top == this.value[style].left)
      (this.value as any)[style].left = e;
    if (position == 'top' && this.value[style].top == this.value[style].right)
      (this.value as any)[style].right = e;
    if (position == 'top' && this.value[style].top == this.value[style].bottom)
      (this.value as any)[style].bottom = e;

    (this.value as any)[style][position] = e;
    this.prevValue = JSON.parse(JSON.stringify(this.value));
    super.handleValueChange(this.value);
  }

  handleColorChange(e: any) {
    this.value.borderColor = e;
    super.handleValueChange(this.value);
  }
}
