<div #el>
    <div class="brd">
        <table class="table table-bordered table-sm">
            <thead class="thead-dark">
                <tr *ngIf="dataSource">
                    <th *ngFor="let item of dataSource.columns">{{item.title}}</th>
                    <th *ngIf="allowAddDelete">
                        <ax-button type="primary" size="sm" tooltip="Add"><!--  (onClick)="deleteClick(i)" -->
                            <i class="fas fa-plus"></i> Add Row
                        </ax-button>
                    </th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngIf="dataSource">
                    <tr style="border-bottom: 1px solid var(--primary-color);">
                        <td *ngFor="let item of dataSource.columns">
                            <span
                                *ngIf="!item.fillByUser && item.fieldName==null && item.type!='image'">{{item.defaultValue}}</span>
                            <span *ngIf="!item.fillByUser && item.fieldName!=null">[{{item.fieldName}}]</span>
                            <img *ngIf="!item.fillByUser && item.fieldName==null && item.type=='image'"
                                [src]="item.defaultValue" height="40" width="60">
                            <ax-text-box *ngIf="item.fillByUser && item.type=='string'"></ax-text-box>
                            <ax-text-box type="number" *ngIf="item.fillByUser && item.type=='number'"></ax-text-box>
                            <ax-date-picker label="" *ngIf="item.fillByUser && item.type=='date'"></ax-date-picker>
                            <ax-check-box *ngIf="item.fillByUser && item.type=='boolean'" label="' '">
                            </ax-check-box>
                            <ax-upload-file *ngIf="item.fillByUser && item.type=='image'" type="inline">
                            </ax-upload-file>
                            <ax-selection-list direction="horizontal"
                                *ngIf="item.fillByUser && item.type=='selectionList'">
                            </ax-selection-list>
                            <div class="sign" *ngIf="item.fillByUser && item.type=='signature'"
                            style="width: 50%; height: 50px;"></div> 
                        </td>
                        <td *ngIf="allowAddDelete">                             
                            <ax-button type="light" size="sm" tooltip="Delete"><!--  (onClick)="deleteClick(i)" -->
                                <i class="fas fa-trash danger"></i>
                            </ax-button> 
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>