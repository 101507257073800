import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { AXBaseComponent } from '../base.component';

@Component({
  selector: 'ax-selection-list',
  templateUrl: './selectionlist.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXSelectionListComponent extends AXBaseComponent {
  @Input() direction: string = 'horizontal';
  @Input() items: Array<any> = [];
  @Input() mode: 'single' | 'multiple' = 'single';
  @Input() textField: string = 'text';
  @Input() valueField: string = 'value';

  @Output()
  selectedItemsChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  private _selectedItems: any[] = [];
  @Input()
  public get selectedItems(): any[] {
    return this._selectedItems || [];
  }
  public set selectedItems(v: any[]) {
    this._selectedItems = v;
    this.selectedItemsChange.emit(this.selectedItems);
    this.cdr.detectChanges();
  }

  @Output()
  selectedValuesChange: EventEmitter<any[] | any> = new EventEmitter<
    any[] | any
  >();

  @Input()
  public get selectedValues(): any[] | any {
    if (this.mode == 'single')
      return this._selectedItems.map((c) => c[this.valueField])[0];
    else return this._selectedItems.map((c) => c[this.valueField]) || [];
  }
  public set selectedValues(v: any[] | any) {
    let old = this.selectedValues;
    if (v == null) v = [];
    if (JSON.stringify(old) != JSON.stringify(v)) {
      if (this.mode == 'single') {
        this.selectedItems = this.items.filter((c) => v == c[this.valueField]);
      } else {
        if (Array.isArray(v))
          this.selectedItems = this.items.filter((c) =>
            v.includes(c[this.valueField])
          );
        else this.selectedItems = [];
      }
      this.selectedValuesChange.emit(this.selectedValues);
    }
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  onCheckValueChange(value: any, checked: boolean) {
    if (this.mode == 'single') {
      this.selectedValues = [value];
    } else {
      if (checked) {
        if (!this.selectedValues.includes(value)) {
          this.selectedValues = [...this.selectedValues, ...[value]];
        }
      } else {
        this.selectedValues = this.selectedValues.filter(
          (c: any) => c != value
        );
      }
    }
  }
}
