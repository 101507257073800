import {
  ACFDesignerPage,
  ACFViewerPage,
  FormbuilderModule,
} from '@acorex/form-builder';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MasterLayout } from './layouts/master.layout';

const routes: Routes = [
  {
    path: '',
    component: MasterLayout,
    children: [
      {
        path: '',
        redirectTo: '/designer',
        pathMatch: 'full',
      },
      {
        path: 'view',
        component: ACFViewerPage,
      },
      {
        path: 'designer',
        component: ACFDesignerPage,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), FormbuilderModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
