import { ACoreXUIModule } from '@acorex/ui';
import { CommonModule } from '@angular/common';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AXFDesignerModule } from './modules/designer/config/designer.module';
import { AXFDataService } from './modules/services/data.service';
import { AXFViewerModule } from './modules/viewer/config/viewer.module';
import { AXFWidgetModule } from './modules/widget/config/widget.module';

export function init_app(dataService: AXFDataService) {
  const x = () => dataService.init();
  return x;
}

@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule,
    FormsModule,
    AXFDesignerModule,
    AXFViewerModule,
    ACoreXUIModule,
    AXFWidgetModule,
  ],
  providers: [
    AXFDataService,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [AXFDataService],
      multi: true,
    },
  ],
  exports: [AXFViewerModule, AXFDesignerModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FormbuilderModule {}
