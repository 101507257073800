import { ACoreXUIModule } from '@acorex/ui';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AXFWidgetService } from '../../../services/widget.service';
import {
  AXF_CAPTION_PROPERTY,
  AXF_DISPLAY_NAME_PROPERTY,
  AXF_NAME_PROPERTY,
  AXF_TAG_PROPERTY,
  AXF_VISIBLE_PROPERTY,
} from '../../config/general-properties';
import { AXFWidgetSharedModule } from '../../shared/shared.module';
import { AXFPanelWidgetDesigner } from './designer/panel-widget.designer';
import { AXFPanelWidgetPrint } from './print/panel-widget.print';
import { AXFPanelWidgetView } from './view/panel-widget.view';

export const COMPONENTS = [
  AXFPanelWidgetDesigner,
  AXFPanelWidgetView,
  AXFPanelWidgetPrint,
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [CommonModule, ACoreXUIModule, AXFWidgetSharedModule],
  exports: [...COMPONENTS],
  entryComponents: [...COMPONENTS],
  providers: [],
})
export class AXFPanelWidgetModule {
  constructor(service: AXFWidgetService) {
    service.register({
      title: 'Panel',
      hint: 'A collapsible panel',
      icon: 'fas fa-caret-square-up',
      category: 'Layout',
      visible: true,
      name: 'panel',
      container: true,
      designerClass: AXFPanelWidgetDesigner,
      printClass: AXFPanelWidgetPrint,
      viewClass: AXFPanelWidgetView,
      options: [],
      properties: [
        AXF_CAPTION_PROPERTY,
        {
          category: 'General',
          name: 'allowCollapse',
          title: 'Collapsable',
          defaultValue: true,
          editor: 'CheckboxEditor',
        },
        {
          category: 'General',
          name: 'collapsed',
          title: 'Collapsed',
          defaultValue: false,
          editor: 'CheckboxEditor',
        },
        AXF_VISIBLE_PROPERTY,
        AXF_NAME_PROPERTY,
        AXF_DISPLAY_NAME_PROPERTY,
        AXF_TAG_PROPERTY,
      ],
    });
  }
}
