import { PromisResult } from '@acorex/ui';
import { Observable } from 'rxjs';
import { AFXSaveTemplateModel, AXFTemplateModel } from '../db/database';

export abstract class AXFTemplateService {
  abstract saveForm(model: AFXSaveTemplateModel): PromisResult<boolean>;

  abstract load(): PromisResult<AXFTemplateModel>;

  abstract get(id: string): PromisResult<AXFTemplateModel>;

  abstract getWidgetList(): PromisResult<AXFTemplateModel[]>;

  abstract loadingEvent(): Observable<boolean>;
}
