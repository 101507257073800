import { ACoreXUIModule } from '@acorex/ui';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AXFViewerModule } from '../../viewer/config/viewer.module';
import { AXFWidgetModule } from '../../widget/config/widget.module';
import { ACFDesignerPage } from '../pages/designer.page';

@NgModule({
  declarations: [ACFDesignerPage],
  imports: [CommonModule, ACoreXUIModule, AXFWidgetModule, AXFViewerModule],
  exports: [ACFDesignerPage],
  providers: [],
  entryComponents: [],
})
export class AXFDesignerModule {}
