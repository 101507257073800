<div class="axf-drop-zone" >
    <div *ngIf="widgets.length; else elseTemplate">
        <div *ngFor="let w of widgets" axf-widget-renderer [widget]="w" [parent]="this">
        </div>
    </div>
    <ng-template #elseTemplate>
        <div style="text-align: center;color: red; font-weight: bold;">
            {{placeholder}}&nbsp;
        </div>
    </ng-template>
</div>