import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';

import { AXButtonComponent } from './components/form/button/button.component';
import { AXCheckBoxComponent } from './components/form/checkbox/checkbox.component';
import { AXColorBoxComponent } from './components/form/colorpicker/colorbox.component';
import { AXCalendarBoxComponent } from './components/form/datepicker/calendarbox.component';
import { AXDatePickerComponent } from './components/form/datepicker/datepicker.component';
import { AXDateTime } from './components/form/datepicker/datetime';
import { AXDateTimePipe } from './components/form/datepicker/datetime.pipe';
import { AXDropDownComponent } from './components/form/dropdown/dropdown.component';
import { AXNumberBoxComponent } from './components/form/numberbox/numberbox.component';
import { AXSearchBoxComponent } from './components/form/searchbox/searchbox.component';
import { AXSelectBoxComponent } from './components/form/selectbox/selectbox.component';
import { AXSelectionListComponent } from './components/form/selectionlist/selectionlist.component';
import { AXTextAreaComponent } from './components/form/textarea/textarea.component';
import { AXTextBoxComponent } from './components/form/textbox/textbox.component';

import { AXDataSourceComponent } from './components/datasource/datasource.component';
import { AXColorPickerComponent } from './components/form/colorpicker/colorpicker.component';
import { AXMenuComponent } from './components/form/menu/menu.component';
import { AXPanelBoxComponent } from './components/form/panelbox/panelbox.component';
import { AXProgressBarComponent } from './components/form/progressbar/progressbar.component';
import { AXTabComponent } from './components/form/tab/tab.component';
import { DynamicTabsDirective } from './components/form/tabview/dynamic-tabs.directive';
import { AXTabViewComponent } from './components/form/tabview/tabview.component';
import { AXToolbarMenuComponent } from './components/form/toolbarmenu/toolbarmenu.component';
import { AXUploadFileComponent } from './components/form/uploadfile/uploadfile.component';
import { AXValidationFormComponent } from './components/form/validation/validation-form.component';
import { AXValidationRule } from './components/form/validation/validation-rule.widget';
import { AXValidationComponent } from './components/form/validation/validation.component';
import { AXThemeWrapperComponent } from './components/layout/theme-wrapper/theme-wrapper.component';
import { AXDialogComponent } from './components/service/dialog/dialog.component';
import { AXDialogService } from './components/service/dialog/dialog.service';
import { EventService } from './components/service/event/event.service';
import { AXPopupComponent } from './components/service/popup/popup.component';
import { AXPopupService } from './components/service/popup/popup.service';
import { AXRenderService } from './components/service/render.service';
import { AXToastMessageComponent } from './components/service/toast/toast-message/toast-message.component';
import { AXToastWrapperComponent } from './components/service/toast/toast-wrapper/toast-wrapper.component';
import { AXToastService } from './components/service/toast/toast.service';

@NgModule({
  imports: [CommonModule, FormsModule, TextMaskModule, OverlayModule],
  exports: [
    AXThemeWrapperComponent,
    AXTextBoxComponent,
    AXNumberBoxComponent,
    AXTextAreaComponent,
    AXDropDownComponent,
    AXDateTime,
    AXDateTimePipe,
    AXCalendarBoxComponent,
    AXDatePickerComponent,
    AXButtonComponent,
    AXSearchBoxComponent,
    AXSelectBoxComponent,
    AXCheckBoxComponent,
    AXSelectionListComponent,
    AXColorBoxComponent,
    AXColorPickerComponent,
    AXProgressBarComponent,
    AXUploadFileComponent,
    AXTabComponent,
    AXTabViewComponent,
    DynamicTabsDirective,
    AXMenuComponent,
    AXToolbarMenuComponent,
    AXPopupComponent,
    AXToastWrapperComponent,
    AXToastMessageComponent,
    AXDialogComponent,
    AXPanelBoxComponent,

    AXValidationFormComponent,
    AXValidationRule,
    AXValidationComponent,

    AXDataSourceComponent,
  ],
  declarations: [
    AXThemeWrapperComponent,
    AXTextBoxComponent,
    AXNumberBoxComponent,
    AXTextAreaComponent,
    AXDropDownComponent,
    AXDateTime,
    AXDateTimePipe,
    AXCalendarBoxComponent,
    AXDatePickerComponent,
    AXButtonComponent,
    AXSearchBoxComponent,
    AXSelectBoxComponent,
    AXCheckBoxComponent,
    AXSelectionListComponent,
    AXColorBoxComponent,
    AXColorPickerComponent,
    AXProgressBarComponent,
    AXUploadFileComponent,
    AXTabComponent,
    AXTabViewComponent,
    DynamicTabsDirective,
    AXMenuComponent,
    AXToolbarMenuComponent,
    AXPopupComponent,
    AXToastWrapperComponent,
    AXToastMessageComponent,
    AXDialogComponent,
    AXPanelBoxComponent,

    AXValidationFormComponent,
    AXValidationRule,
    AXValidationComponent,

    AXDataSourceComponent,
  ],
  providers: [
    AXPopupService,
    AXRenderService,
    AXToastService,
    AXDialogService,
    EventService,
  ],
})
export class ACoreXUIModule {}
