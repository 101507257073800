import { ChangeDetectionStrategy, Component, ElementRef } from '@angular/core';
import { AXFWidgetPrint } from '../../../config/widget';

@Component({
  selector: '[axf-container]',
  templateUrl: './container-widget.print.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXFContainerWidgetPrint extends AXFWidgetPrint {
  constructor(private hostElement: ElementRef<HTMLTableCellElement>) {
    super();
  }

  override onRender() {
    if (this.hostElement) {
      this.applyStyle(this.hostElement.nativeElement);
    }
  }
}
