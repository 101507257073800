import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AXFFormulaModel } from '../../../../property-editor/editors/formula/formula.class';
import { AXFBoxStyleValue } from '../../../../property-editor/editors/style/box-style/box-style.class';
import { AXFWidgetView } from '../../../config/widget';

@Component({
  selector: '[axf-page]',
  templateUrl: './page-widget.view.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXFPageWidgetView extends AXFWidgetView {
  formula: AXFFormulaModel[] = [];
  bgColor!: string;
  themeColor!: string;
  boxStyle!: AXFBoxStyleValue;
  pageDirection!: string;
  private dataSubscription: Subscription | undefined;

  constructor(
    private hostElement: ElementRef,
    private cdr: ChangeDetectorRef,
    private zone: NgZone
  ) {
    super();
    this.dataSubscription = this.dataService?.onChange?.subscribe((data) => {
      this.calcFormula();
    });
  }

  private calcFormula() {
    if (this.formula && this.formula.length) {
      this.formula.forEach((f) => {
        const val = this.dataService?.eval(f.expression as string);
        this.dataService?.setValue(f.variable as string, val, false);
      });
    }
    this.invokeEvent('onSubscribeChange');
  }

  override ngOnInit() {
    super.ngOnInit();
    this.calcFormula();
  }

  override onRender(): void {
    const el: HTMLElement = this.hostElement.nativeElement as HTMLElement;
    el.classList.remove('rtl', 'ltr');
    el.style.setProperty('--primary-color', this.themeColor, 'important');
    el.classList.add(this.pageDirection);
    this.applyStyle(el);
  }

  override ngOnDestroy() {
    this.dataSubscription?.unsubscribe();
    super.ngOnDestroy();
  }
}
