import { AXBasePageComponent } from '@acorex/ui';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'ax-expandtext',
  templateUrl: './expandtext.page.html',
})
export class ExpandTextPage extends AXBasePageComponent {
  constructor() {
    super();
  }

  @Input()
  public value!: string;

  @Input()
  public readonly!: boolean;

  rowsN: number = 10;

  saveChanges() {
    this.close(this.value);
  }
}
