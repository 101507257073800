<div class="viewer-container" #el>
  <ngx-extended-pdf-viewer
    [src]="srcPdf"
    [showSecondaryToolbarButton]="false"
    [showFindButton]="true"
    [showDrawEditor]="false"
    [showTextEditor]="false"
    [showDownloadButton]="false"
    [showOpenFileButton]="false"
    [showStampEditor]="false"
    [showPrintButton]="false"
    [useBrowserLocale]="true"
    height="100vh"
  >
  </ngx-extended-pdf-viewer>
</div>
