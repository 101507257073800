import { ChangeDetectionStrategy, Component, ElementRef } from '@angular/core';
import { AXFBoxStyleValue } from '../../../../property-editor/editors/style/box-style/box-style.class';
import { AXFWidgetPrint } from '../../../config/widget';

@Component({
  selector: '[axf-page]',
  templateUrl: './page-widget.print.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { role: 'page' },
})
export class AXFPageWidgetPrint extends AXFWidgetPrint {
  constructor(private hostElement: ElementRef) {
    super();
  }

  bgColor!: string;
  themeColor!: string;
  boxStyle!: AXFBoxStyleValue;
  pageDirection!: string;

  override onRender(): void {
    const el: HTMLElement = this.hostElement.nativeElement as HTMLElement;
    el.classList.remove('rtl', 'ltr');
    el.style.setProperty('--primary-color', this.themeColor);
    // el.classList.add(this.pageDirection);
    el.style.setProperty('direction', this.pageDirection);
    this.applyStyle(el);
  }
}
