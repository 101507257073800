import {
  AXPopupService,
  AXSelectBoxComponent,
  AXSelectionListComponent,
} from '@acorex/ui';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ViewChild,
} from '@angular/core';
import { AXFDataService } from '../../../services/data.service';
import { AXFProperyEditor } from '../../config/editor';
import { AXFDataColumnEditorComponent } from './columns.component';
import {
  AXFDataSourceColumnOption,
  AXFDataSourceOption,
  AXFDataSourceRemoteOption,
} from './data-source.class';
import { AXIDataDisplayEditorComponent } from './display.component';
import { AXIDataItemEditorComponent } from './items.component';

@Component({
  templateUrl: `data-source.editor.html`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXFDataSourceEditorComponent extends AXFProperyEditor<AXFDataSourceOption> {
  @ViewChild('modeSelection') modeSelection!: AXSelectionListComponent;
  @ViewChild('remoteSelection') remoteSelection!: AXSelectBoxComponent;
  @ViewChild('displaySelection') displaySelection!: AXSelectBoxComponent;

  modeItems: any[] = [
    { value: 'manual', text: 'Manual' },
    { value: 'remote', text: 'Remote' },
  ];
  remoteItems: any[] = [];
  allowColumns: boolean = true;
  displayList: any[] = [
    { value: 'allItems', text: 'All Items' },
    { value: 'onlySelected', text: 'Only Selected' },
  ];
  blocked: boolean = true;

  constructor(
    protected override cdr: ChangeDetectorRef,
    private dataService: AXFDataService,
    private popupService: AXPopupService
  ) {
    super(cdr);
  }

  ngOnInit() {
    this.valueChange.subscribe((c) => {
      this.cdr.markForCheck();
    });
    if (this.value == null) {
      this.value = new AXFDataSourceOption();
      this.value.mode = 'manual';
      this.initColumns();
    } else {
      const v: AXFDataSourceOption = new AXFDataSourceOption();
      if (!this.value.displayMode) {
        this.value.displayMode = 'allItems';
        this.value.displayItems = [];
      }
      Object.assign(v, this.value);
      this.value = v;
    }
  }

  ngAfterViewInit(): void {
    this.modeSelection.selectedValues = [this.value.mode];
    this.dataService.getDSList().then((items: any) => {
      this.remoteItems = items;
      setTimeout(() => {
        if (
          this.remoteSelection &&
          this.value.dataSource &&
          this.value.dataSource.name
        ) {
          this.remoteSelection.selectedValues = this.value.dataSource.name;
        }
      });
      this.cdr.markForCheck();
    });
    this.initiated = true;
  }

  handleRemoteChange(v: any[]) {
    if (
      v &&
      (this.value.dataSource == null ||
        this.value.dataSource.name != v[0].value)
    ) {
      this.value.dataItems = [];
      (this.value.dataSource as AXFDataSourceRemoteOption).name = v[0].value;
      if (v[0].params) {
        (this.value.dataSource as AXFDataSourceRemoteOption).params =
          v[0].params.map((c: any) => ({ name: c, value: null }));
      } else {
        (this.value.dataSource as AXFDataSourceRemoteOption).params = [];
      }
      //
      this.dataService
        .getList(
          (this.value.dataSource as AXFDataSourceRemoteOption).name,
          (this.value.dataSource as AXFDataSourceRemoteOption).params
        )
        .then((items: any) => {
          if (items && items.length) {
            this.value.dataItems = items;
            if (this.allowColumns) {
              const obj = items[0];
              const cols: AXFDataSourceColumnOption[] = [];
              for (const key in obj) {
                if (obj.hasOwnProperty(key) && typeof obj[key] !== 'function') {
                  cols.push({
                    fieldName: key,
                    fillByUser: false,
                    title: key,
                    defaultValue: key,
                    type: typeof obj[key],
                    isDisplay: false,
                    textField: false,
                    valueField: false,
                  });
                }
              }
              this.value.columns = cols;
            }
            this.value.displayItems = items.map((d: any) =>
              this.value.columns[0].fieldName
                ? d[this.value.columns[0].fieldName]
                : ''
            );
            super.handleValueChange(this.value);
            this.cdr.detectChanges();
          } else {
            this.initColumns();
          }
        });
      super.handleValueChange(this.value);
      this.cdr.detectChanges();
    }
  }

  handleParamChange(v: any) {
    super.handleValueChange(this.value);
  }

  handleModeChange(v: any[]) {
    if (v && v[0].value !== this.value.mode) {
      this.value.mode = v[0].value;
      if (this.value.mode === 'manual') {
        this.initColumns();
        this.value.dataItems = [];
        this.value.displayItems = [];
      }
      if (this.value.mode === 'remote') {
        this.value.displayMode = 'allItems';
        this.value.displayItems = [];
      }
      super.handleValueChange(this.value);
      this.cdr.markForCheck();
    }
  }

  handleItemEditor() {
    this.popupService
      .open(AXIDataItemEditorComponent, {
        title: 'Items Editor',
        size: this.value.columns.length > 3 ? 'lg' : 'md',
        data: {
          columns: this.value.columns,
          items: this.value.dataItems,
        },
      })
      .closed((c: any) => {
        this.value.columns = c.data.columns;
        this.value.dataItems = c.data.items;
        this.handleValueChange(this.value);
        this.cdr.markForCheck();
      });
  }

  handleDisplayItemEditor() {
    if (
      !this.value.columns ||
      !this.value.dataItems ||
      this.value.columns.length == 0 ||
      this.value.dataItems.length == 0
    )
      return;
    this.popupService
      .open(AXIDataDisplayEditorComponent, {
        title: 'Display Editor',
        size: this.value.columns.length > 3 ? 'lg' : 'md',
        data: {
          columns: this.value.columns,
          items: this.value.dataItems,
          displayItems: this.value.displayItems,
        },
      })
      .closed((c: any) => {
        this.value.columns = c.data.columns;
        this.value.dataItems = c.data.items;
        this.value.displayItems = c.data.displayItems;
        this.handleValueChange(this.value);
        this.cdr.markForCheck();
      });
  }

  handleColumnEditor() {
    this.popupService
      .open(AXFDataColumnEditorComponent, {
        title: 'Columns Editor',
        size: 'lg',
        data: {
          columns: this.value.columns,
          allowColumns: this.allowColumns,
        },
      })
      .closed((c: any) => {
        this.value.columns = c.data.columns;
        this.handleValueChange(this.value);
        this.cdr.markForCheck();
      });
  }

  private initColumns() {
    if (this.allowColumns) {
      this.value.columns = [];
      this.value.columns.push({
        fieldName: 'column1',
        title: 'Column 1',
        defaultValue: 'Column 1',
        fillByUser: false,
        type: 'string',
        isDisplay: true,
        textField: false,
        valueField: false,
      });
      this.value.columns.push({
        fieldName: 'column2',
        title: 'Column 2',
        defaultValue: 'Column 2',
        fillByUser: false,
        type: 'string',
        isDisplay: false,
        textField: false,
        valueField: false,
      });
      this.value.columns.push({
        fieldName: 'column3',
        title: 'Column 3',
        defaultValue: 'Column 3',
        fillByUser: false,
        type: 'string',
        isDisplay: false,
        textField: false,
        valueField: false,
      });
    }
  }

  handleDisplayChange(v: any) {
    if (v && v.length > 0 && v[0].value !== this.value.displayMode) {
      this.value.displayMode = v[0].value;
      if (this.value.displayMode === 'allItems') {
        this.value.displayItems = [];
      } else {
        this.value.displayItems = this.value.dataItems?.map((d) =>
          this.value.columns[0].fieldName
            ? d[this.value.columns[0].fieldName]
            : ''
        );
      }
      super.handleValueChange(this.value);
      this.cdr.markForCheck();
    }
  }

  showDocumentChange(v: any) {
    if (v != undefined) {
      if (v) {
        if (
          this.value.dataItems &&
          this.value.dataItems.length > 0 &&
          this.value.dataItems[0].hasOwnProperty('fileID') &&
          !this.value.columns.some((d) => d.fieldName == 'fileID')
        )
          this.value.columns.push({
            fieldName: 'fileID',
            title: 'fileID',
            defaultValue: 'fileID',
            fillByUser: false,
            type: 'string',
            isDisplay: false,
            textField: false,
            valueField: false,
          });
      } else {
        if (
          this.value.dataItems &&
          this.value.dataItems.length > 0 &&
          this.value.columns.some((d) => d.fieldName == 'fileID')
        )
          this.value.columns = this.value.columns.filter(
            (d) => d.fieldName != 'fileID'
          );
      }
      super.handleValueChange(this.value);
      this.cdr.markForCheck();
    }
  }
}
