import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { AXFWidgetPickerService } from '../../../../services/template/picker.service';
import { AXFWidgetDesigner } from '../../../config/widget';

@Component({
  selector: '[axf-page-footer]',
  templateUrl: './page-footer-widget.designer.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'axf-drop-zone' },
})
export class AXFPageFooterWidgetDesigner extends AXFWidgetDesigner {
  constructor(
    private picker: AXFWidgetPickerService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  override onRender() {
    this.cdr.markForCheck();
  }
}
