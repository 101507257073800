import { AXBasePageComponent, ClosingAction } from '@acorex/ui';
import { Component } from '@angular/core';

@Component({
  templateUrl: './display.component.html',
  styleUrls: ['./display.component.scss'],
})
export class AXIDataDisplayEditorComponent extends AXBasePageComponent {
  public columns!: any;
  public items: any = [];
  public displayItems: any = [];

  override onClosing(e: ClosingAction) {
    e.data = {
      items: this.items,
      columns: this.columns,
      displayItems: this.displayItems,
    };
    e.resolve();
  }

  onCheckValueChange(val: any) {
    if (!this.displayItems) {
      this.displayItems = [];
    }

    if (!this.displayItems.includes(val)) {
      this.displayItems = [...this.displayItems, ...[val]];
    } else {
      this.displayItems = this.displayItems.filter((c: any) => c !== val);
    }
  }

  showItem(id: any) {
    return this.displayItems.some((s: any) => s == id);
  }
}
