import { AXPopupService } from '@acorex/ui';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { AXFValueWidgetView } from '../../../config/widget';
import { SignaturePadPage } from '../signaturepad.page';

@Component({
  templateUrl: './signature-input-widget.view.html',
  styleUrls: ['./signature-input-widget.view.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXFSignatureInputWidgetView extends AXFValueWidgetView {
  height!: number;
  width!: number;
  confirmText!: string;
  constructor(
    protected override cdr: ChangeDetectorRef,
    private popupService: AXPopupService
  ) {
    super(cdr);
  }

  calculate(val: any) {
    return parseInt(val) + 2;
  }

  override onRender(): void {
    this.cdr.markForCheck();
  }

  setSignatureClick() {
    this.popupService
      .open(SignaturePadPage, {
        title: 'Signature',
        size: 'md',
        data: {
          value: this.value,
          confirmText: this.confirmText,
        },
      })
      .closed((c: any) => {
        if (c && c.data) {
          this.invokeEvent('onSigned');
        }
        this.value = c.data;
        this.cdr.markForCheck();
      });
  }
}
