import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
} from '@angular/core';
import { AXFWidgetPrint } from '../../../config/widget';

@Component({
  templateUrl: './image-input-widget.print.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { role: 'image-input' },
})
export class AXFImageInputWidgetPrint extends AXFWidgetPrint {
  height!: number;
  width!: number;
  alt!: string;
  constructor(
    private el: ElementRef<HTMLElement>,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.cdr.detectChanges();
  }
}
