import { AXDateTime } from '@acorex/ui';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
} from '@angular/core';
import { AXFValueWidgetView } from '../../../config/widget';

@Component({
  templateUrl: './time-input-widget.view.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXFTimeInputWidgetView extends AXFValueWidgetView {
  timeMask = (rawValue: string) => {
    let num2 = /[0-9]/;
    if (rawValue && rawValue[0] === '2') {
      num2 = /[0-3]/;
    }
    return [/[0-2]/, num2, ':', /[0-5]/, /[0-9]/];
  };

  setCurrent: boolean = false;

  constructor(
    private el: ElementRef<HTMLElement>,
    protected override cdr: ChangeDetectorRef
  ) {
    super(cdr);
  }

  handleSetCurrent() {
    this.value = new AXDateTime().format('HH:mm');
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    if (!this.value && this.setCurrent) {
      this.value = new AXDateTime().format('HH:mm');
    }
  }
}
