<div class="pre-bootstrap" *ngIf="isBusy">
    <div class="pre-bootstrap-spinner"></div>
</div>
<div [hidden]="isBusy" [attr.version]="version">
    <ng-container *ngFor="let w of widgets" axf-widget-renderer [widget]="w" [mode]="mode">
    </ng-container>
</div>
<div  style="display: none;"  #print>
    <ng-container *ngIf="printRendering">
        <ng-container *ngFor="let w of widgets" axf-widget-renderer [widget]="w" [mode]="'print'" >
        </ng-container>
    </ng-container>
</div>
