import { AXHtmlUtil } from '@acorex/ui';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AXFConnectService } from './connect.service';

export interface AXFWidgetProperty {
  name: string;
  title: string;
  hint?: string;
  defaultValue?: any;
  category: 'General' | 'Style' | 'Behavior' | 'Data' | 'Binding' | 'Print';
  editor: any;
  visible?: boolean | Function;
  options?: any;
  order?: number;
  bindable?: boolean;
}

export interface AXFWidgetToolboxProperty {
  visible?: boolean;
  delete?: boolean;
  edite?: boolean;
  move?: boolean;
}

export interface WidgetConfig {
  title: string;
  icon: string;
  hint?: string;
  name: string;
  category: string;
  visible: boolean;
  container?: boolean;
  draggable?: boolean;
  droppable?: boolean;
  blankHover?: boolean;
  designerClass: any;
  viewClass: any;
  printClass: any;
  options?: any;
  properties: AXFWidgetProperty[];
  toolbox?: AXFWidgetToolboxProperty;
  dataContext?: any;
  onRendered?: Subject<WidgetConfig>;
}

// @dynamic
@Injectable({ providedIn: 'root' })
export class AXFWidgetService {
  static WIDGET_ITEMS: WidgetConfig[] = [];

  constructor(private connectService: AXFConnectService) {}

  getList(category?: string): WidgetConfig[] {
    return AXFWidgetService.WIDGET_ITEMS.filter(
      (c) => c.visible && (category == null || c.category == category)
    );
  }

  register(config: WidgetConfig) {
    AXFWidgetService.WIDGET_ITEMS.push(config);
  }

  resolve(name: string): WidgetConfig {
    const c: any = AXFWidgetService.WIDGET_ITEMS.find((c) => c.name === name);
    const res: WidgetConfig = {
      category: c.category,
      hint: c.hint,
      icon: c.icon,
      name: c.name,
      title: c.title,
      designerClass: c.designerClass,
      printClass: c.printClass,
      viewClass: c.viewClass,
      blankHover: c.blankHover,
      visible: c.visible,
      container: c.container,
      draggable: c.draggable,
      droppable: c.droppable,
      toolbox: c.toolbox,
      properties: [],
    };
    res.onRendered = new Subject<WidgetConfig>();
    if (c.properties) {
      res.properties = this.deepCopy(c.properties);
    }
    if (c.options) {
      res.options = JSON.parse(JSON.stringify(c.options));
    } else {
      res.options = {};
    }
    res.options.uid = AXHtmlUtil.getUID();
    return res;
  }

  private deepCopy(obj: any) {
    let copy: any;
    // Handle the 3 simple types, and null or undefined
    if (null == obj || 'object' !== typeof obj) {
      return obj;
    }
    // Handle Date
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }
    // Handle Array
    if (obj instanceof Array) {
      copy = [];
      for (let i = 0, len = obj.length; i < len; i++) {
        copy[i] = this.deepCopy(obj[i]);
      }
      return copy;
    }
    // Handle Object
    if (obj instanceof Object) {
      copy = {};
      for (const attr in obj) {
        if (obj.hasOwnProperty(attr)) {
          copy[attr] = this.deepCopy(obj[attr]);
        }
      }
      return copy;
    }
    throw new Error("Unable to copy obj! Its type isn't supported.");
  }

  serialize(item: WidgetConfig): string {
    return JSON.stringify(this.serializeInternal(item));
  }

  private serializeInternal(item: WidgetConfig): any {
    const obj: any = {};
    obj.name = item.name;
    obj.options = {};
    // A
    if (item.properties) {
      item.properties.forEach((p) => {
        if (item.options[p.name] && item.options[p.name].clone) {
          obj.options[p.name] = item.options[p.name].clone();
        } else {
          obj.options[p.name] = this.deepCopy(item.options[p.name]);
        }
      });
    }
    if (item.options && item.options.widgets && item.name !== 'outlet') {
      obj.options.widgets = [];
      item.options.widgets.forEach((w: any) => {
        obj.options.widgets.push(this.serializeInternal(w));
      });
    }
    return obj;
  }

  parse(json: string | any): WidgetConfig | null {
    if (typeof json === 'string') {
      try {
        const obj = JSON.parse(json);
        return this.parseInternal(obj);
      } catch (error) {
        console.log("Invalid widget's json to parse: ", json);
        return null;
      }
    } else {
      return this.parseInternal(json);
    }
  }

  clone(widget: WidgetConfig) {
    return this.parseInternal(this.serializeInternal(widget));
  }

  private parseInternal(obj: any): WidgetConfig {
    const item: WidgetConfig = this.resolve(obj.name);
    if (!item.options) {
      item.options = {};
    }
    item.options.uid = AXHtmlUtil.getUID();
    Object.assign(item.options, this.deepCopy(obj.options));
    if (obj.options.widgets) {
      item.options.widgets = [];
      obj.options.widgets.forEach((w: any) => {
        item.options.widgets.push(this.parseInternal(w));
      });
    }
    return item;
  }

  readPropsFromHost(widget: string, name: string, tag: string): Promise<any> {
    return this.connectService.send('readProps', {
      widget,
      name,
      tag,
    });
  }
}
