import { AXBasePageComponent } from '@acorex/ui';
import { Component, Input } from '@angular/core';
import { AXFDataService } from '../../../services/data.service';
import { LovDataSource } from './LovDataSource';
import { AXFLovInputWidgetView } from './view/lov-input-widget.view';

@Component({
  styleUrls: ['./lovmodal.page.scss'],
  templateUrl: './lovmodal.page.html',
})
export class LovModalPage extends AXBasePageComponent {
  constructor(private dataService: AXFDataService) {
    super();
  }
  columnCount: number = 0;
  filter: string = '';
  vals: any[] = [];
  columnVisibles: any[] = [];

  @Input()
  public ww!: AXFLovInputWidgetView;

  dataSource!: LovDataSource;

  ngOnInit() {
    this.columnVisibles = this.ww.dataSource.columns.filter((w) => w.isDisplay);
    this.columnCount = this.columnVisibles.length;
    if (this.ww.value) {
      if (this.ww.mode == 'single') this.vals = [this.ww.value];
      else this.vals = this.ww.value;
    }
  }

  onChange(e: string) {
    let fltr: string | null = null;
    // if(this.filter!=e)
    //     this.vals=[];
    if (e != '') {
      fltr = e;
      this.filter = e;
    }
    this.dataSource = new LovDataSource(
      this.dataService,
      this.ww.dataSource,
      this.ww,
      fltr
    );
  }

  selectItem(item: any) {
    if (this.ww.mode == 'single') this.close(item);
    else {
      let colBase = this.ww.dataSource.columns[0].fieldName as string;
      let ind = this.vals.findIndex((w) => w[colBase] == item[colBase]);
      if (ind > -1) {
        this.vals.splice(ind, 1);
      } else this.vals.push(item);
    }
  }

  showChecked(item: any) {
    return this.vals.some(
      (w) =>
        w[(this.ww.dataSource.columns[0] as any).fieldName] ==
        item[(this.ww.dataSource.columns[0] as any).fieldName]
    );
  }

  submit() {
    this.close(this.vals);
  }

  delete(item: any) {
    let colBase = this.ww.dataSource.columns[0].fieldName as string;
    let ind = this.vals.findIndex((w) => w[colBase] == item[colBase]);
    if (ind > -1) {
      this.vals.splice(ind, 1);
    }
  }
}
