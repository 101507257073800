import { ChangeDetectionStrategy, Component, ElementRef } from '@angular/core';
import { AXFBoxStyleValue } from '../../../../property-editor/editors/style/box-style/box-style.class';
import { AXFWidgetPrint } from '../../../config/widget';

@Component({
  selector: 'td',
  templateUrl: './col-widget.print.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXFColWidgetPrint extends AXFWidgetPrint {
  size: number = 1;
  color!: string;
  bgColor!: string;
  boxStyle!: AXFBoxStyleValue;

  constructor(private hostElement: ElementRef<HTMLTableCellElement>) {
    super();
  }

  override ngOnInit() {
    let el = this.hostElement.nativeElement;
    el.style.width = (this.size / 12) * 100 + '%';
    this.applyStyle(el);
  }
}
