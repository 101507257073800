import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AXFConnectService } from '../../../services/connect.service';
import { AXFProperyEditor } from '../../config/editor';
import { UploadStructure } from './upload.structure';

@Component({
  templateUrl: './upload.editor.html',
  styleUrls: ['./upload.editor.scss'],
})
export class AXFUploadEditorComponent
  extends AXFProperyEditor<UploadStructure>
  implements OnInit
{
  modeItems: any[] = [
    { value: 'auto', title: 'Auto Size' },
    { value: 'custom', title: 'Custom Size' },
  ];
  methods: any[] = [
    { value: 'url', title: 'URL' },
    { value: 'upload', title: 'Upload' },
  ];
  mode: 'image' | 'video' = 'image';

  @ViewChild('fileVideoBox') fileVideoBox!: ElementRef<HTMLElement>;
  constructor(
    protected override cdr: ChangeDetectorRef,
    private connectService: AXFConnectService
  ) {
    super(cdr);
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.initiated = true;
  }

  modeSizeChange(e: any) {
    if (e && this.value.modeSize != e) {
      this.value.modeSize = e;
      if (e == 'auto') {
        this.value.height = this.value.orginalHeight;
        this.value.width = this.value.orginalWidth;
      }
      super.handleValueChange(this.value);
    }
  }

  widthChange(e: any) {
    if (e && this.value.width != e) {
      this.value.width = e;
      if (this.value.isAspectRatio) {
        this.value.height =
          (this.value.width * this.value.orginalHeight) /
          this.value.orginalWidth;
      }
      this.cdr.detectChanges();
      super.handleValueChange(this.value);
    }
  }

  heightChange(e: any) {
    if (e && this.value.height != e) {
      this.value.height = e;
      if (this.value.isAspectRatio) {
        this.value.width =
          (this.value.height * this.value.orginalWidth) /
          this.value.orginalHeight;
      }
      this.cdr.detectChanges();
      super.handleValueChange(this.value);
    }
  }

  override async handleValueChange(evt: any) {
    let data = evt.data;
    let newDimension = await this.getImageDimensions(evt.data);
    this.value.orginalHeight = newDimension.h;
    this.value.orginalWidth = newDimension.w;
    if (this.value.modeSize == 'auto') {
      this.value.height = this.value.orginalHeight;
      this.value.width = this.value.orginalWidth;
    }
    this.connectService.send('uploadFile', { data }).then((c: any) => {
      if (c.data) this.value.srcData = c.data;
      else this.value.srcData = c;
      this.cdr.detectChanges();
      super.handleValueChange(this.value);
    });
  }

  getImageDimensions(file: any): any {
    return new Promise(function (resolved, rejected) {
      var i = new Image();
      i.onload = function () {
        resolved({ w: i.width, h: i.height });
      };
      i.src = file;
    });
  }

  aspectRatioChange(e: any) {
    if (e != undefined) {
      this.value.isAspectRatio = e;
      if (e == true) {
        this.value.height =
          (this.value.width * this.value.orginalHeight) /
          this.value.orginalWidth;
        this.cdr.detectChanges();
      }
      super.handleValueChange(this.value);
    }
  }

  handleTextChange() {
    super.handleValueChange(this.value);
  }

  selectVideo() {
    this.fileVideoBox.nativeElement.click();
  }

  uploadVideo(e: any) {
    this.cdr.detectChanges();
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    const pattern = /video-*/;
    const reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  async _handleReaderLoaded(e: any) {
    const reader = e.target;
    let data = reader.result;
    this.connectService.send('uploadFile', { data }).then((c: any) => {
      if (c.data) this.value.srcData = c.data;
      else this.value.srcData = c;
      this.cdr.detectChanges();
      super.handleValueChange(this.value);
    });
  }
}
