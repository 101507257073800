import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { AXFWidgetPrint } from '../../../config/widget';

@Component({
  templateUrl: './video-input-widget.print.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { role: 'video-input' },
})
export class AXFVideoInputWidgetPrint extends AXFWidgetPrint {
  height!: number;
  width!: number;
  alt!: string;
  constructor(private cdr: ChangeDetectorRef) {
    super();
  }
  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.cdr.detectChanges();
  }
}
