import { ACoreXUIModule } from '@acorex/ui';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AXFEditorService } from '../../../services/editor.service';
import { AXFEventEditorComponent } from './event.editor';

@NgModule({
  declarations: [AXFEventEditorComponent],
  imports: [CommonModule, ACoreXUIModule],
  exports: [AXFEventEditorComponent],
  entryComponents: [AXFEventEditorComponent],
  providers: [],
})
export class AXFEventEditorModule {
  constructor(service: AXFEditorService) {
    service.register('EventEditor', AXFEventEditorComponent);
  }
}
