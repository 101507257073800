<ax-theme-wrapper>
  <!-- Text Box:
    <ax-text-box></ax-text-box>

    Number Box:
    <ax-number-box></ax-number-box>

    Text Area:
    <ax-text-area></ax-text-area>

    DropDown:
    <ax-drop-down></ax-drop-down>

    Datepicker
    <ax-date-picker></ax-date-picker>

    Button
    <ax-button text="test" size="lg" ></ax-button>
    <br />
    Search
    <ax-search-box></ax-search-box>

    Selectbox
    <ax-select-box [items]="items" valueField="value" textField="title"></ax-select-box>

    checkbox <br />
    <ax-check-box label="test"></ax-check-box>
    <br /> <br />
    selection list
    <ax-selection-list mode="multiple" [items]="items" textField="title" valueField="value"></ax-selection-list>
    <br />
    color picker
    <ax-color-picker></ax-color-picker>
    <br /><br />
    upload file <br />
    <ax-upload-file [type]="'box'"></ax-upload-file>
    <br />
    Tab view
    <ax-tab-view >
        <ax-tab caption="All">
            <div class="list-container">
                <div class="text-item" *ngFor="let w of items"  [attr.title]='w.title'> 
                    <div class="text">
                        <div class="title">{{ w.title }}</div> 
                    </div>
                </div>
            </div>
        </ax-tab>
    </ax-tab-view>

    menu
    <ax-toolbar-menu [items]="viewModeItems" selection="single"  >
    </ax-toolbar-menu>
    <br />
    Dialog  <br />
    <ax-button text="Dialog" size="lg" (onClick)="showDialog()"></ax-button> -->

  <router-outlet></router-outlet>
</ax-theme-wrapper>
