import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { AXFWidgetPrint } from '../../../config/widget';

@Component({
  templateUrl: './file-upload-widget.print.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { role: 'file-upload' },
})
export class AXFFileUploadWidgetPrint extends AXFWidgetPrint {
  constructor(private cdr: ChangeDetectorRef) {
    super();
  }
  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.cdr.detectChanges();
  }
}
