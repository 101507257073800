import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
} from '@angular/core';
import { AXFWidgetView } from '../../../config/widget';

@Component({
  selector: '[axf-widget-row]',
  templateUrl: './row-widget.view.html',
  styleUrls: ['./row-widget.view.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXFRowWidgetView extends AXFWidgetView {
  constructor(private hostElement: ElementRef, private cdr: ChangeDetectorRef) {
    super();
  }

  override onRender(): void {
    let el: HTMLElement = this.hostElement.nativeElement as HTMLElement;
    el.classList.add('row');
    el.style.display = this.visible ? 'flex' : 'none';
    this.applyStyle(el);
    this.cdr.detectChanges();
  }
}
