import { ACoreXUIModule } from '@acorex/ui';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AceEditorModule } from 'ng2-ace-editor';
import { AXFEditorService } from '../../../services/editor.service';
import { AXFExpressionEditorComponent } from './expression-editor.component';
import { AXFFormulaManageComponent } from './formula-manage.component';
import { AXFFormulaEditorComponent } from './formula.editor';

@NgModule({
  declarations: [
    AXFFormulaEditorComponent,
    AXFFormulaManageComponent,
    AXFExpressionEditorComponent,
  ],
  imports: [CommonModule, ACoreXUIModule, AceEditorModule],
  exports: [AXFFormulaEditorComponent],
  entryComponents: [
    AXFFormulaEditorComponent,
    AXFFormulaManageComponent,
    AXFExpressionEditorComponent,
  ],
  providers: [],
})
export class AXFFormulaEditorModule {
  constructor(service: AXFEditorService) {
    service.register('FormulaEditor', AXFFormulaEditorComponent);
  }
}
