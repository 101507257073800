import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { UploadStructure } from '../../../../property-editor/editors/upload/upload.structure';
import { AXFWidgetPrint } from '../../../config/widget';

@Component({
  templateUrl: './video-widget.print.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { role: 'video-box' },
})
export class AXFVideoWidgetPrint extends AXFWidgetPrint {
  override value!: UploadStructure;
  boxStyle: any;
  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  override onRender(): void {
    this.cdr.detectChanges();
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.cdr.detectChanges();
  }
}
