import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { AXFWidgetDesigner } from '../../../config/widget';

@Component({
  selector: '[axf-widget-signature]',
  templateUrl: './signature-input-widget.designer.html',
  styleUrls: ['./signature-input-widget.designer.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXFSignatureInputWidgetDesigner extends AXFWidgetDesigner {
  height!: number;
  width!: number;
  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  override onRender(): void {
    this.cdr.markForCheck();
  }
}
