import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { ClosingEventArgs } from './popup.events';

@Component({
  selector: 'ax-popup',
  templateUrl: './popup.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class AXPopupComponent implements OnInit, OnDestroy {
  @ViewChild('popupBody', { read: ViewContainerRef, static: true })
  private popupBody!: ViewContainerRef;

  @ViewChild('container', { static: true })
  private container!: ElementRef;

  @HostListener('keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (this.closable) this.onCloseClick();
  }

  private comRef!: ComponentRef<any>;
  title: string = '';
  constructor(private resolver: ComponentFactoryResolver) {}

  ngOnInit(): void {
    const factory = this.resolver.resolveComponentFactory(this.content);
    this.comRef = this.popupBody.createComponent(factory);
    let com = this.comRef.instance as any;
    if (com.closeEvent) {
      com.closeEvent.subscribe((e: ClosingEventArgs) => {
        this.close.emit(e);
      });
    }
    this.content = com;
    Object.assign(this.content, this.data);
  }

  ngAfterViewInit() {
    this.focus();
  }

  close: EventEmitter<ClosingEventArgs> = new EventEmitter<ClosingEventArgs>();

  size: 'sm' | 'md' | 'lg' | 'full' = 'sm';

  data: any = {};

  maximizable: boolean = false;

  closable: boolean = true;

  content: any;
  onCloseClick() {
    this.close.emit({ cancel: false });
  }

  ngOnDestroy() {
    if (this.comRef) this.comRef.destroy();
  }

  focus() {
    setTimeout(() => this.container.nativeElement.focus());
  }

  onFullScreen() {}
}
