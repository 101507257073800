import { AXDateTime } from '@acorex/ui';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { AXFValueWidgetView } from '../../../config/widget';

@Component({
  templateUrl: './date-input-widget.view.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXFDateInputWidgetView extends AXFValueWidgetView {
  calendarType: any;
  displayFormat!: string;
  setCurrent: boolean = false;

  constructor(protected override cdr: ChangeDetectorRef) {
    super(cdr);
  }

  override onRender(): void {
    if (
      this.value == undefined &&
      (this as any)['rIndex'] >= 0 &&
      (this as any)['dataContext'] != undefined &&
      (this as any)['dataContext'][(this as any)['name']] != null
    ) {
      this.value = new AXDateTime(
        (this as any)['dataContext'][(this as any)['name']].toString()
      );
      this.cdr.detectChanges();
    }
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    if (!this.value && this.setCurrent) {
      this.value = new Date();
    }
  }

  @Input()
  public get date(): AXDateTime | null {
    return this.value
      ? new AXDateTime(this.value as Date, this.calendarType)
      : null;
  }
  public set date(v: AXDateTime | null) {
    this.value = v ? v.date.toJSON() : null;
  }

  dateChanged() {
    this.cdr.markForCheck();
  }
}
