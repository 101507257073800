<table #table *ngIf="visible">
    <thead>
        <ng-container *ngFor="let w of headerRows" axf-widget-renderer [widget]="w" [parent]="this"
            [mode]="'view'">
        </ng-container>
    </thead>
    <tbody>
        <ng-container *ngFor="let w of bodyRows;let r=index" axf-widget-renderer [rIndex]="r"
            [widget]="w" [parent]="this" [mode]="'view'">
        </ng-container>
    </tbody>
</table>
<button type="button" class="btn btn-sm btn-primary" (click)="addItemClick()" 
    *ngIf="visible && allowAdd && !readonly"><i class="fas fa-plus"></i> Add
    Item
</button>