import { AXPopupService } from '@acorex/ui';
import { Injectable } from '@angular/core';
import { AXFWidgetPickerComponent } from '../../widget/shared/widget-picker/widget-picker.component';
import { AXFWidgetService, WidgetConfig } from '../widget.service';

@Injectable({ providedIn: 'root' })
export class AXFWidgetPickerService {
  constructor(
    private popup: AXPopupService,
    private widgetService: AXFWidgetService
  ) {}

  showPicker() {
    return new Promise<WidgetConfig[] | null>((resolve) => {
      this.popup
        .open(AXFWidgetPickerComponent, {
          title: 'Add Element',
          size: 'md',
          data: {
            list: this.widgetService.getList(),
          },
        })
        .closed((c) => {
          if (c && c.data) {
            resolve(c.data);
          } else {
            resolve(null);
          }
        });
    });
  }
}
