import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AXKeyboardEvent } from '../base.component';

@Component({
  selector: 'ax-search-box',
  templateUrl: './searchbox.component.html',
})
export class AXSearchBoxComponent {
  constructor() {}

  showClear: boolean = true;
  private searchChangeObserver: any;

  @Output()
  onTextChanged: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  textChange: EventEmitter<string> = new EventEmitter<string>();

  private _text!: string;
  @Input()
  public get text(): string {
    return this._text;
  }
  public set text(v: string) {
    if (v !== this._text) {
      this._text = v;
      this.onTextChanged.emit(v);
      this.textChange.emit(v);
    }
  }

  onSearchChanged(e: AXKeyboardEvent) {
    if (!this.searchChangeObserver) {
      Observable.create((observer: any) => {
        this.searchChangeObserver = observer;
      })
        .pipe(debounceTime(500))
        .pipe(distinctUntilChanged())
        .subscribe((c: string) => {
          this.text = c;
        });
    }
    this.searchChangeObserver.next((<any>e.target).value);
  }
}
