import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AXFFormatService } from '../../../../services/format.service';
import { AXFWidgetView } from '../../../config/widget';

@Component({
  template: '',
  selector: '[axf-widget-text]',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXFTextBlockWidgetView extends AXFWidgetView {
  text!: string;
  private dataSubscription: Subscription | undefined;

  constructor(
    private hostElement: ElementRef<HTMLDivElement>,
    private cdr: ChangeDetectorRef,
    private formatService: AXFFormatService
  ) {
    super();
    //
    this.dataSubscription = this.dataService?.onChange.subscribe((data) => {
      this.hostElement.nativeElement.innerHTML = this.formatService.format(
        this.text,
        this
      );
    });
  }

  override onRender(): void {
    this.applyStyle(this.hostElement.nativeElement);
    this.hostElement.nativeElement.style.display = this.visible
      ? 'block'
      : 'none';
    this.cdr.markForCheck();
  }

  override ngOnInit() {
    super.ngOnInit();
    this.hostElement.nativeElement.innerHTML = this.formatService.format(
      this.text,
      this
    );
  }

  override ngOnDestroy() {
    this.dataSubscription?.unsubscribe();
    super.ngOnDestroy();
  }
}
