import { ACoreXUIModule } from '@acorex/ui';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AXFEditorService } from '../../../services/editor.service';
import { AXFCheckboxEditorComponent } from './checkbox.editor';

@NgModule({
  declarations: [AXFCheckboxEditorComponent],
  imports: [CommonModule, ACoreXUIModule, FormsModule],
  exports: [AXFCheckboxEditorComponent],
  entryComponents: [AXFCheckboxEditorComponent],
  providers: [],
})
export class AXFCheckboxEditorModule {
  constructor(service: AXFEditorService) {
    service.register('CheckboxEditor', AXFCheckboxEditorComponent);
  }
}
