import { AXDateTime } from '@acorex/ui';
import { Injectable } from '@angular/core';
import { AXFWidgetView } from '../widget/config/widget';
import { AXFDataService } from './data.service';

export interface AXFWordWithPipe {
  word: string | undefined;
  formetters: string[];
}

@Injectable({ providedIn: 'root' })
export class AXFFormatService {
  constructor(private dataService: AXFDataService) {}

  public decompose(value: string): AXFWordWithPipe {
    const result: AXFWordWithPipe = {
      word: value ? value.toString() : undefined,
      formetters: [],
    };
    if (value && typeof value === 'string') {
      const parts = value.split('|');
      result.word = parts[0].trim();
      for (let i = 1; i < parts.length; i++) {
        const pipe = parts[i];
        if ((this as any)[pipe.split(':')[0].trim()]) {
          result.formetters.push(pipe);
        }
      }
    }
    return result;
  }

  public format(value: any, widget?: AXFWidgetView): string {
    if (value && typeof value === 'string') {
      const expr = value.match(/\[\#(.*?)\.formula\.(.*?)\((.*?)\)\]/);
      if (expr) {
        const wname = expr[1];
        const methodName = expr[2];
        const params = expr[3] ? expr[3].split(',').map((c) => eval(c)) : [];
        widget = this.dataService.getWidget(wname);
        return (widget as any)['formula'][methodName](...params);
      }
      const list = value.match(/\[(.*?)\]/g);
      if (list) {
        list.forEach((w) => {
          const ww: AXFWordWithPipe = this.decompose(
            w.substring(1, w.length - 1)
          );
          let word: any = ww.word;
          if (widget) {
            if (word.startsWith('$$')) {
              word = this.dataService.getValue(word.substring(2));
            } else if (word.startsWith('$')) {
              word = this.dataService.getValue(
                widget.resolveProperty(word.substring(1))
              );
            } else if (
              widget.config.dataContext &&
              widget.config.dataContext.hasOwnProperty(ww.word)
            ) {
              word = widget.config.dataContext[ww.word as string];
            } else {
              word = this.dataService.getWord(word);
            }
          } else {
            word = this.dataService.getWord(word);
          }
          if (word != null) {
            if (ww.formetters.length > 0) {
              for (let i = 0; i < ww.formetters.length; i++) {
                const pipeParts = ww.formetters[i].split(':');
                const pipe = pipeParts[0].trim();
                const pipeParams = pipeParts.slice(1);
                word = (this as any)[pipe](word, pipeParams);
              }
            }
          }
          value = value.replace(
            w,
            word !== undefined && word != null ? word : ''
          );
        });
      }
    }
    return value !== undefined && value != null ? value : '';
  }

  private JDT(value: string) {
    try {
      const val = new AXDateTime(value, 'jalali');
      return val.format('YYYY/MM/DD');
    } catch (error) {
      return value;
    }
  }

  private number(value: string) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}
