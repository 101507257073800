<div>
    <ax-selection-list #modeSelection direction="horizontal" [mode]="'single'" [items]="modeItems"
        (selectedItemsChange)="handleModeChange($event)" textField="text" valueField="value">
    </ax-selection-list>
</div>
<div *ngIf="value.mode=='remote'" class="mt-2">
    <ax-select-box #remoteSelection [allowSearch]="false" [items]="remoteItems" [mode]="'single'"
        (selectedItemsChange)="handleRemoteChange($event)" textField="text" valueField="value">
    </ax-select-box>
    <div *ngIf="value.dataSource && value.dataSource.params &&  value.dataSource.params.length>0" class="mt-1">
        <span class="prop-caption ">Parameters</span>
        <div class="input-group">
            <ng-container *ngFor="let item of value.dataSource.params" class="params">
                <div class="input-group mt-1 input-group-sm">
                    <div class="input-group-prepend">
                        <span class="input-group-text" style="font-size: x-small;"
                            [attr.id]="item.name">{{item.name}}</span>
                    </div>
                    <input type="text" [(ngModel)]="item.value" (ngModelChange)="handleParamChange($event)"
                        class="form-control" [attr.aria-describedby]="item.name">
                </div>
            </ng-container>
        </div>
    </div>
</div>
<div class="mt-2" >
    <ax-button type="primary" size="sm" [block]="blocked" (onClick)="handleColumnEditor()">
        <i class="fas fa-columns"></i>&nbsp;<span>Columns: {{value.columns.length}}</span>
    </ax-button>
</div>
<div *ngIf="value.mode=='manual'" class="mt-2">
    <ax-button type="primary" size="sm" (onClick)="handleItemEditor()" [block]="blocked">
        <i class="fas fa-list"></i>&nbsp;<span>Items:
            {{  value &&  value.dataItems ? value.dataItems.length : 0}}</span>
    </ax-button>
</div>
<div *ngIf="value.mode=='remote'" class="mt-2">
    <div class="prop-caption"> Display </div>
    <ax-selection-list #displaySelection direction="horizontal" [mode]="'single'" [items]="displayList" [(selectedValues)]="value.displayMode"
    style="margin-bottom: 50px !important;"
        (selectedItemsChange)="handleDisplayChange($event)" textField="text" valueField="value">
    </ax-selection-list>
    <ax-button  *ngIf="value.displayMode=='onlySelected'"  type="primary" size="sm" (onClick)="handleDisplayItemEditor()" [block]="blocked" class="mt-2">
        <span>Select Items to Display</span>
    </ax-button>

    <ax-check-box  label="Show Document Link" [(value)]="value.showDocument"  (valueChange)="showDocumentChange($event)"></ax-check-box>
</div>