import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { AXFWidgetPrint } from '../../../config/widget';

@Component({
  templateUrl: './text-area-widget.print.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXFTextAreaWidgetPrint extends AXFWidgetPrint {
  placeholder!: string;
  rows!: number;
  textStyle!: string[];
  textAlign!: string;

  constructor(protected cdr: ChangeDetectorRef) {
    super();
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    if (
      this.value == undefined &&
      (this as any)['rIndex'] >= 0 &&
      (this as any)['dataContext'] != undefined &&
      (this as any)['dataContext'].hasOwnProperty((this as any)['name'])
    ) {
      this.value = (this as any)['dataContext'][(this as any)['name']];
    }
    this.cdr.detectChanges();
  }
}
