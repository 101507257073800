import { ACoreXUIModule } from '@acorex/ui';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AXFBoxStyleBoxSizeValue } from '../../../property-editor/editors/style/box-style/box-style.class';
import { AXFWidgetService } from '../../../services/widget.service';
import {
  AXF_BG_COLOR_PROPERTY,
  AXF_BOX_STYLE_PROPERTY,
  AXF_INIT_EVENT,
  AXF_NAME_PROPERTY,
  AXF_VISIBLE_PROPERTY,
} from '../../config/general-properties';
import { AXFWidgetSharedModule } from '../../shared/shared.module';
import { AXFRowWidgetDesigner } from './designer/row-widget.designer';
import { AXFRowWidgetPrint } from './print/row-widget.print';
import { AXFRowWidgetView } from './view/row-widget.view';

export const COMPONENTS = [
  AXFRowWidgetDesigner,
  AXFRowWidgetView,
  AXFRowWidgetPrint,
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [CommonModule, ACoreXUIModule, AXFWidgetSharedModule],
  exports: [...COMPONENTS],
  entryComponents: [...COMPONENTS],
  providers: [],
})
export class AXFRowWidgetModule {
  constructor(service: AXFWidgetService) {
    service.register({
      title: 'Row',
      hint: 'Row container element',
      icon: 'fas fa-align-justify',
      name: 'row',
      category: 'Layout',
      visible: true,
      container: true,
      droppable: false,
      designerClass: AXFRowWidgetDesigner,
      printClass: AXFRowWidgetPrint,
      viewClass: AXFRowWidgetView,
      options: {
        boxStyle: {
          border: new AXFBoxStyleBoxSizeValue('1'),
          padding: new AXFBoxStyleBoxSizeValue('0'),
          margin: new AXFBoxStyleBoxSizeValue('5', '0', '5', '0'),
        },
        bgColor: 'inherit',
      },
      properties: [
        AXF_BG_COLOR_PROPERTY,
        AXF_VISIBLE_PROPERTY,
        AXF_BOX_STYLE_PROPERTY,
        AXF_NAME_PROPERTY,
        AXF_INIT_EVENT,
      ],
    });
  }
}
