import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { AXFValueWidgetView } from '../../../config/widget';
@Component({
  templateUrl: './link-input-widget.view.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXFLinkInputWidgetView extends AXFValueWidgetView {
  placeholder!: string;
  defaultValue!: number;
  allowExtend!: boolean;
  constructor(protected override cdr: ChangeDetectorRef) {
    super(cdr);
  }

  override onRender(): void {
    let that: any = this;
    if (
      this.value == undefined &&
      that['rIndex'] >= 0 &&
      that['dataContext'] != undefined &&
      that['dataContext'].hasOwnProperty(that['name'])
    ) {
      this.value = that['dataContext'][that['name']];
    }
    this.cdr.markForCheck();
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    if (this.value === undefined && this.defaultValue !== undefined) {
      this.value = this.defaultValue;
    }
  }

  direct() {
    window.open(this.value, '_blank');
  }
}
