import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { UploadStructure } from '../../../../property-editor/editors/upload/upload.structure';
import { AXFWidgetView } from '../../../config/widget';

@Component({
  templateUrl: './video-widget.view.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXFVideoWidgetView extends AXFWidgetView {
  private _value!: UploadStructure;
  @Input()
  public get value(): UploadStructure {
    return this._value;
  }
  public set value(v: UploadStructure) {
    if (JSON.stringify(v) !== JSON.stringify(this._value)) {
      this._value = v;
      this.dataService?.setValue(this.getPath() as string, v);
      this.cdr.detectChanges();
    }
  }

  constructor(protected cdr: ChangeDetectorRef) {
    super();
  }

  override onRender(): void {
    this.cdr.markForCheck();
  }

  override ngOnInit() {
    super.ngOnInit();
  }
}
