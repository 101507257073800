import {
  AXBasePageComponent,
  AXHtmlUtil,
  AXToastService,
  AXToolbarMenuComponent,
  EventService,
  MenuItem,
} from '@acorex/ui';
import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AXFChangeTrackerService } from '../../services/change-tracker.service';
import { AXFConnectService } from '../../services/connect.service';
import { AFXSaveTemplateModel } from '../../services/db/database';
import { AXFTemplateService } from '../../services/template/template.service';
import { AXFWidgetService, WidgetConfig } from '../../services/widget.service';
import {
  AXFWidgetContainer,
  AXFWidgetDesigner,
} from '../../widget/config/widget';

@Component({
  templateUrl: './designer.page.html',
  styleUrls: ['./designer.page.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ACFDesignerPage
  extends AXBasePageComponent
  implements AXFWidgetContainer, OnInit, OnDestroy
{
  private intervalId: any;
  version: string = require('../../../../../../../package.json').version;

  @ViewChild('paper')
  paper!: ElementRef<HTMLDivElement>;

  @ViewChild('actionToolbar')
  actionToolbar!: AXToolbarMenuComponent;

  @ViewChild('print')
  printDiv!: ElementRef<HTMLDivElement>;

  config!: WidgetConfig;

  constructor(
    private widgetService: AXFWidgetService,
    private toastService: AXToastService,
    private eventService: EventService,
    private templateService: AXFTemplateService,
    private connectService: AXFConnectService,
    public changeTracker: AXFChangeTrackerService
  ) {
    super();
    eventService.on('SELECT', (c: AXFWidgetDesigner) => {
      setTimeout(() => {
        if (c && c?.uid) {
          // console.log('selected', c.uid);
          this.docTreeItems = [];
          this.docTreeItems.push(c);
          let parent: AXFWidgetDesigner = c.parent;
          while (parent != null && parent.config) {
            this.docTreeItems.push(parent);
            parent = parent.parent;
          }
          this.docTreeItems.reverse();
          this.callWidget(c.uid);
        }
      }, 50);
    });

    eventService.on('__save', (data) => {
      if (this.isSaving) {
        return;
      }
      this.isSaving = true;
      this.actionItems[0].startIcon = 'fas fa-spinner fa-pulse';
      this.actionToolbar.update();
      this.printRendering = true;
      setTimeout(() => {
        const html = this.printDiv.nativeElement.innerHTML;
        let body =
          '<html><head><meta charset="utf-8"/>' +
          '<style>.realTable thead { display: table-header-group } .realTable tr { page-break-inside: avoid } .realRow { page-break-inside: avoid} </style>' +
          '<title>SmartForms Api Sample</title></head><body style="font-family: Segoe UI;padding: 0px;margin: 0px;  ">';
        body = body + html + '</body></html>';

        let param: AFXSaveTemplateModel = {
          name: '',
          type: 'form',
          widget: this.widgets[0],
          printHtml: body,
        };
        this.templateService.saveForm(param).then((s) => {
          this.actionItems[0].startIcon = 'fas fa-save';
          this.isSaving = false;
          this.printRendering = false;
          this.actionToolbar.update();
        });
      }, 2000);
    });
    this.cleanupOldItems();
  }

  private toggledIndexes: Set<number> = new Set<number>();
  choosedItem: number;
  docTreeItems: AXFWidgetDesigner[] = [];

  widgets: WidgetConfig[] = [];
  mode = 'designer';
  view = 'designer';
  isSaving: boolean = false;
  showTree: boolean = false;
  printRendering: boolean = false;
  name: string = '';
  exprtFileName: string = null;
  orientation: number = 1;

  viewModeItems: MenuItem[] = [
    {
      startIcon: 'fas fa-paint-brush',
      name: 'designer',
      text: ' Design View',
      groupName: 'mode',
      selected: true,
      style: 'light',
      data: 'designer',
    },
    {
      startIcon: 'fas fa-desktop',
      name: 'form',
      text: ' Preview',
      groupName: 'mode',
      style: 'light',
      data: 'view',
    },
  ];

  actionItems: MenuItem[] = [
    {
      startIcon: 'fas fa-clone',
      name: 'generate',
      text: 'Options',
      style: 'ax-primary',
      items: [
        {
          startIcon: 'fas fa-download',
          name: 'export-json',
          text: 'Export as JSON',
        },
        {
          startIcon: 'fas fa-clipboard',
          name: 'copy-json',
          text: 'Copy as JSON',
        },
        {
          startIcon: 'fas fa-upload',
          split: true,
          name: 'import-json',
          text: 'Import JSON',
        },
        {
          startIcon: 'fab fa-buffer',
          split: true,
          name: 'import-buffer',
          text: 'Load Saved',
        },
        // {
        //   startIcon: 'fas fa-clipboard',
        //   name: "paste-json",
        //   text: "Paste JSON",
        // },
      ],
    },
    {
      startIcon: 'fas fa-save',
      name: 'save',
      text: 'Save',
      style: 'ax-success',
    },
    {
      startIcon: 'fas fa-undo',
      name: 'back',
      text: 'Back',
      style: 'light',
    },
  ];

  handleViewModeClick(e: MenuItem) {
    if (
      e.name === 'form' &&
      (this.widgets == null || this.widgets.length === 0)
    ) {
      this.toastService.error('The form is blank!');
      return;
    }
    this.view = e.name as string;
    this.mode = e.data;
  }

  async handleActionClick(e: MenuItem) {
    if (this.widgets == null || this.widgets.length === 0) {
      this.toastService.error('The form is blank!');
      return;
    }
    switch (e.name) {
      case 'save': {
        if (this.isSaving) {
          return;
        }
        this.isSaving = true;
        this.actionItems[1].startIcon = 'fas fa-spinner fa-pulse';
        this.actionToolbar.update();
        this.printRendering = true;

        setTimeout(() => {
          const html = this.printDiv.nativeElement.innerHTML;
          let body =
            '<html><head><meta charset="utf-8"/>' +
            '<style>.realTable thead { display: table-header-group } .realTable tr { page-break-inside: avoid } .realRow { page-break-inside: avoid} </style>' +
            '<title>SmartForms Api Sample</title></head><body style="font-family: Segoe UI;padding: 0px;margin: 0px;  ">';
          body = body + html + '</body></html>';

          let param: AFXSaveTemplateModel = {
            name: '',
            type: 'form',
            widget: this.widgets[0],
            printHtml: body,
          };

          this.templateService.saveForm(param).then((s) => {
            this.actionItems[1].startIcon = 'fas fa-save';
            this.isSaving = false;
            this.printRendering = false;
            if (s === true) {
              this.actionToolbar.update();
              this.toastService.success('Saved successfuly!');
            } else {
              this.actionToolbar.update();
              let errorMessage = 'Error in Saving!';
              if (localStorage.getItem('ErrorSaveText'))
                errorMessage = localStorage.getItem('ErrorSaveText') || '';
              this.toastService.error(errorMessage);
            }
          });
        }, 2000);

        if (this.widgets[0]?.options?.widgets?.length)
          this.addItem(
            this.name,
            this.widgetService.serialize(this.widgets[0])
          );
        break;
      }
      case 'back': {
        this.connectService.send('back', {
          name: '',
          type: 'form',
          template: this.widgetService.serialize(this.widgets[0]),
        });
        break;
      }
      case 'export-json': {
        const json = this.widgetService.serialize(this.widgets[0]);
        const pom = document.createElement('a');
        const blob = new Blob([json], { type: 'text/json;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        pom.href = url;
        pom.setAttribute(
          'download',
          `${this.exprtFileName ?? 'form-schema'}.json`
        );
        pom.click();
        break;
      }

      case 'copy-json': {
        const json = this.widgetService.serialize(this.widgets[0]);
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = json;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.toastService.success('JSON Copied successfuly!');
        break;
      }
      case 'import-json': {
        try {
          const selBox = document.createElement('input');
          selBox.style.display = 'none';
          selBox.type = 'file';
          selBox.accept = '.json';
          document.body.appendChild(selBox);
          selBox.addEventListener(
            'change',
            (e) => {
              // const file = e.files[0];
              // if (file) {
              //   const reader = new FileReader();
              //   reader.readAsText(file, "UTF-8");
              //   reader.onload = (evt) => {
              //     //document.getElementById("fileContents").innerHTML = evt.target.result;
              //   }
              //   reader.onerror = function (evt) {
              //     //document.getElementById("fileContents").innerHTML = "error reading file";
              //   }
              // }
            },
            false
          );
          selBox.click();
          document.body.removeChild(selBox);

          // const clipboardContents = await navigator.clipboard.readText();
          // if (clipboardContents) {
          //   try {
          //     this.widgets = [
          //       this.widgetService?.parse(clipboardContents) as WidgetConfig,
          //     ];
          //     this.toastService.success('JSON Pasted successfuly!');
          //   } catch (error) {
          //     throw new Error('Invalid JSON format!');
          //   }
          // }
          // else {
          //   throw new Error('Clipboard is empty!');
          // }
        } catch (error: any) {
          this.toastService.error(error?.message);
        }
        break;
      }
      case 'import-buffer': {
        if (
          window.confirm(
            'Are you sure you want to load from your local storage? All unsaved data will be lost!'
          )
        ) {
          const bufferWidgets = localStorage.getItem(this.name);
          if (bufferWidgets) {
            if (this.widgetService.parse(bufferWidgets)) {
              this.widgets[0] = this.widgetService.parse(bufferWidgets);
              alert('successfully loaded');
            } else {
              alert('invalid data');
            }
          } else alert("You've never saved any data!");
          this.eventService.broadcast('SELECT', this.widgets[0]);
        }
        break;
      }
    }
  }

  handleBreadcrumbClick(item: AXFWidgetDesigner) {
    if (!item.locked) {
      this.eventService.broadcast('SELECT', item);
    }
  }

  ngAfterViewInit() {
    this.connectService.send('load').then((data) => {
      if (data && data.widgets && data.widgets.length > 0) {
        this.name = data.name;
        this.widgets = [
          this.widgetService?.parse(data.widgets) as WidgetConfig,
        ];

        console.log('widgets loaded!', this.widgets);
        if (data.orientation !== undefined && data.orientation != null) {
          this.orientation = data.orientation;
        }
      } else {
        const page = this.widgetService.resolve('page');
        Object.assign(page.options, { uid: AXHtmlUtil.getUID() });
        this.widgets.push(page);
      }
      (this.widgets[0] as WidgetConfig).onRendered?.subscribe((c) => {
        if (
          (c as any).componentRef &&
          typeof (c as any).componentRef.edit === 'function'
        ) {
          (c as any).componentRef.edit();
        }
      });
    });
  }

  getTreeTitle(node): string {
    let name = node.title;
    const addition = node.options.displayName || node.options.name || '';
    return addition ? `${name} : ${addition}` : name;
  }
  getTreeClass(node): string {
    if (!node.options?.widgets?.length) return 'uncollapsible';
    if (['table-cell', 'col'].includes(node.name)) return 'collapsibleCell';
    return 'collapsible';
  }

  isItemSelected(item): boolean {
    return this.choosedItem === item.componentRef.uid;
  }
  chooseElement(item): void {
    this.choosedItem = item.componentRef.uid;
    this.handleBreadcrumbClick(item.componentRef);
  }

  toggleTreeContent(item: any): void {
    const uid = item.componentRef.uid;
    if (this.toggledIndexes.has(uid)) {
      this.toggledIndexes.delete(uid);

      // Recursive call to close all children
      if (item.options?.widgets) {
        this.collapseAllChildren(item.options.widgets);
      }
    } else {
      this.toggledIndexes.add(uid);
    }
  }
  openTreeContent(item: any): void {
    const uid = item.componentRef.uid;
    if (!this.toggledIndexes.has(uid)) {
      this.toggledIndexes.add(uid);
    }
  }

  callWidget(refId) {
    // return;
    // this.collapseAllChildren(this.widgets);
    // Assuming `widgets` is your array of navigation items at the root level
    const item = this.findItemByRefId(refId, this.widgets);
    if (item && item.componentRef?.uid) {
      // Select the item
      this.choosedItem = item.componentRef.uid;
      // Collapse all parent items so that the desired item is visible
      let parent = item.componentRef.parent; // Assuming there's a parent reference in your items

      while (parent) {
        this.openTreeContent({ componentRef: parent });
        parent = parent.parent; // Go up the tree
      }
      // Finally, ensure the selected item's tree is expanded
      if (!this.shouldShowTree(item)) {
        this.openTreeContent(item);
      }
    } else {
      console.error('Item not found for refId:', refId);
    }
  }

  shouldShowTree(item): boolean {
    return this.toggledIndexes.has(item.componentRef.uid);
  }

  hasWidgets(item): boolean {
    return item.options?.widgets?.length > 0;
  }

  collapseAllChildren(widgets): void {
    for (const child of widgets) {
      const childUid = child.componentRef.uid;
      this.toggledIndexes.delete(childUid);
      if (child.options?.widgets?.length) {
        this.collapseAllChildren(child.options.widgets);
      }
    }
  }

  findItemByRefId(refId, items): any {
    for (const item of items) {
      if (item.componentRef.uid === refId) {
        return item;
      }
      if (item.options?.widgets?.length) {
        const found = this.findItemByRefId(refId, item.options.widgets);
        if (found) return found;
      }
    }
    return null;
  }

  @HostListener('window:mousewheel', ['$event'])
  onWindowScroll(e: WheelEvent) {
    if (!this.paper) {
      return;
    }
    if (
      AXHtmlUtil.isInRecPoint(
        {
          x: e.clientX,
          y: e.clientY,
        },
        {
          height: this.paper.nativeElement.clientHeight,
          width: this.paper.nativeElement.clientWidth,
          left: this.paper.nativeElement.getBoundingClientRect().left,
          top: this.paper.nativeElement.getBoundingClientRect().top,
        }
      )
    ) {
      this.paper.nativeElement.scrollBy({
        top: e.deltaY / 1.5,
        left: 0,
      });
    }
  }

  getTitle(widget: WidgetConfig) {
    if (widget.name === 'outlet' && widget.options.widgetTitle) {
      return `${widget.title} (${widget.options.widgetTitle})`;
    } else {
      return widget.title;
    }
  }

  toggleTree() {
    this.showTree = !this.showTree;
  }

  ngOnInit(): void {
    this.startInterval();
  }

  startInterval(): void {
    // Clear existing interval if it exists
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    // Set a new interval
    this.intervalId = setInterval(() => {
      if (this.widgets[0]?.options?.widgets?.length)
        this.addItem(this.name, this.widgetService.serialize(this.widgets[0]));
      else {
        console.log('Empty Form!');
      }
      // this.toastService.info('Auto Save');
    }, 30000); // Interval set to 1 second
  }

  override ngOnDestroy(): void {
    // Clear the interval when the component is destroyed
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    super.ngOnDestroy(); // Call the base class's ngOnDestroy
  }

  // Function to get the tracking array
  getTrackingArray() {
    const trackingData = localStorage.getItem('tracking');
    return trackingData ? JSON.parse(trackingData) : [];
  }

  //  to save the tracking array
  saveTrackingArray(trackingArray) {
    localStorage.setItem('tracking', JSON.stringify(trackingArray));
  }

  //  to add a new item
  addItem(key, item) {
    try {
      // Try to add the new item
      console.log('save FORM on LOCAL_DB');
      localStorage.setItem(key, item);
      this.updateTrackingArray(key);
    } catch (error) {
      if (this.isQuotaExceededError(error)) {
        console.log('Storage Full! removing oldest possible form');
        // If storage is full, remove the oldest item and try again
        this.removeOldestItem();
        this.addItem(key, item); // Recursive call
      } else {
        throw error; // If the error is not a quota error, rethrow it
      }
    }
  }

  //  to check for QuotaExceededError
  isQuotaExceededError(error) {
    return (
      error &&
      (error.code === 22 ||
        error.code === 1014 ||
        error.name === 'QuotaExceededError')
    );
  }

  //  to update the tracking array after adding an item
  updateTrackingArray(key) {
    const trackingArray = this.getTrackingArray();
    trackingArray.unshift(key); // Add new item to the start of the array
    // if (trackingArray.length > 5) {
    //   const keyToRemove = trackingArray.pop(); // Remove the last item if length exceeds 5
    //   localStorage.removeItem(keyToRemove);
    // }
    this.saveTrackingArray(trackingArray);
  }

  //  to remove the oldest item from storage and tracking array
  removeOldestItem() {
    const trackingArray = this.getTrackingArray();
    if (trackingArray.length > 0) {
      const keyToRemove = trackingArray.pop(); // Remove the last item from the array
      localStorage.removeItem(keyToRemove);
      this.saveTrackingArray(trackingArray);
    }
  }
  cleanupOldItems() {
    const trackingArray = this.getTrackingArray();
    Object.keys(localStorage).forEach((key) => {
      if (
        key !== 'tracking' &&
        key.includes('strong') &&
        !trackingArray.includes(key)
      ) {
        console.log('romved oldType form', key);
        localStorage.removeItem(key);
      }
    });
  }
}
