import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
} from '@angular/core';
import { AXFWidgetDesigner } from '../../../config/widget';

@Component({
  selector: '[axf-container]',
  templateUrl: './container-widget.designer.html',
  styleUrls: ['./container-widget.designer.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXFContainerWidgetDesigner extends AXFWidgetDesigner {
  placeholder: string = '';

  constructor(
    private cdr: ChangeDetectorRef,
    private hostElement: ElementRef<HTMLTableCellElement>
  ) {
    super();
  }

  override onRender() {
    if (this.hostElement) {
      this.applyStyle(this.hostElement.nativeElement);
    }
    this.cdr.markForCheck();
  }
}
