import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MenuItem } from '../menu/menu.class';
import { AXMenuComponent } from '../menu/menu.component';
import { AXToolbarItem } from './toolbar-item';

@Component({
  selector: 'ax-toolbar-menu',
  templateUrl: './toolbarmenu.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXToolbarMenuComponent extends AXToolbarItem {
  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  @ViewChild('menu', { static: true }) menu!: AXMenuComponent;

  @Output()
  itemClick: EventEmitter<MenuItem> = new EventEmitter<MenuItem>();

  @ContentChild(TemplateRef, { static: true })
  _contentMenuTemplate!: TemplateRef<any>;

  private _menuTemplate!: TemplateRef<any>;
  @Input()
  public get menuTemplate(): TemplateRef<any> {
    return this._menuTemplate ? this._menuTemplate : this._contentMenuTemplate;
  }
  public set menuTemplate(v: TemplateRef<any>) {
    this._menuTemplate = v;
  }

  @Input()
  public selection: 'none' | 'single' | 'multiple' = 'none';

  private _items!: MenuItem[];
  @Input()
  public get items(): MenuItem[] {
    return this._items;
  }
  public set items(v: MenuItem[]) {
    this._items = v;
    this.update();
  }

  onItemClick(item?: MenuItem) {
    this.itemClick.emit(item);
  }

  ngAfterViewInit(): void {
    this.cdr.detach();
  }

  ngOnDestroy(): void {}

  public close(): void {
    this.menu.close();
  }

  update() {
    this.menu.update();
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }
}
