import { AXDialogService } from '@acorex/ui';
import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UpdateService {
  constructor(
    public updates: SwUpdate,
    private dialogService: AXDialogService
  ) {
    if (updates.isEnabled) {
      interval(5 * 60 * 1000).subscribe(() =>
        updates.checkForUpdate().then(() => console.log('checking for updates'))
      );
    }
  }

  public checkForUpdates(): void {
    this.updates.available.subscribe((event) => this.promptUser());
  }

  private promptUser(): void {
    console.log('updating to new version1');
    this.dialogService
      .confirm(
        'updating to new version',
        'update available for the app please confirm'
      )
      .okay(() => {
        this.updates.activateUpdate().then(() => document.location.reload());
      });
  }
}
