<div class="ax-menu-container" #container>
    <ul class="root" [class.hor]="direction=='horizontal'" [class.ver]="direction=='vertical'" #root>
        <ng-template #recursiveList let-list>
            <ng-container *ngFor="let item of list;trackBy:trackByItem">
                <li class="ax-toolbar-menu-item" *ngIf="item.visible!=false" (click)="onItemClick($event, item)"
                    [ngClass]="item.style" [class.selected]="item.selected"
                    [attr.title]="item.tooltip ? item.tooltip : null" [attr.data-uid]="item.uid"
                    [class.split]="item.split">
                    <div class="content-side">
                        <ng-container *ngIf="menuTemplate; else menuBody">
                            <ng-container *ngTemplateOutlet="menuTemplate; context: { $implicit: item }">
                            </ng-container>
                        </ng-container>
                        <ng-template #menuBody>
                            <span class="ax-toolbar-menu-item-text">
                                <i class="{{ item.icon }} ax-menu-item-icon" *ngIf="item.icon"></i>
                                <i class="{{ item.startIcon }} ax-menu-item-icon" *ngIf="item.startIcon"></i>
                                <span *ngIf="item.text">{{ item.text }}</span>
                                <i class="{{ item.endIcon }} ax-menu-item-icon" *ngIf="item.endIcon"></i>
                            </span>
                        </ng-template>
                    </div>
                    <div class="sub-icon-side">
                        <ng-container *ngIf="item.hasChildren; else emptySub">
                            <i class="fas fa-angle-down ax-menu-item-drop-icon"></i>
                        </ng-container>
                        <ng-template #emptySub>
                            <i class="fas"></i>
                        </ng-template>
                    </div>
                    <ul *ngIf="item.hasChildren" class="collapsed sub-menu">
                        <ng-container *ngTemplateOutlet="
                recursiveList;
                context: { $implicit: item.items }
              "></ng-container>
                    </ul>
                </li>
            </ng-container>
        </ng-template>
        <ng-container *ngTemplateOutlet="recursiveList; context: { $implicit: items }"></ng-container>
        <li class="more" #moreLI (click)="onItemClick($event)">
            <i class="fas fa-bars"></i>
            <ul #moreUL class="collapsed sub-menu"></ul>
        </li>
    </ul>
</div>