import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { AXFWidgetDesigner } from '../../../config/widget';

@Component({
  selector: '[axf-widget-date]',
  templateUrl: './date-input-widget.designer.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXFDateInputWidgetDesigner extends AXFWidgetDesigner {
  constructor() {
    super();
  }

  override onRender(): void {}
}
