import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { AXFFormatService } from '../../../../services/format.service';
import { AXFWidgetView } from '../../../config/widget';

@Component({
  selector: '[axf-panel]',
  templateUrl: './panel-widget.view.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXFPanelWidgetView extends AXFWidgetView {
  caption!: string;
  allowCollapse!: boolean;
  collapsed!: boolean;
  internalCaption!: string;
  private dataSubscription: Subscription | undefined;

  rIndex!: number;

  constructor(private formatService: AXFFormatService) {
    super();
    this.dataSubscription = this.dataService?.onChange?.subscribe((data) => {
      this.internalCaption = this.formatService.format(this.caption, this);
    });
  }

  override ngOnInit() {
    super.ngOnInit();
    this.internalCaption = this.formatService.format(this.caption, this);
  }

  override ngOnDestroy() {
    this.dataSubscription?.unsubscribe();
    super.ngOnDestroy();
  }
}
