import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { SummaryButtonItem } from '../../form/menu/menu.class';

@Component({
  selector: 'ax-dialog',
  templateUrl: './dialog.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class AXDialogComponent implements OnInit {
  @Input()
  message!: string;

  @Input()
  buttons: SummaryButtonItem[] = [];

  constructor() {}

  ngOnInit(): void {}

  onClick!: Function;

  onInnerClick(item: SummaryButtonItem) {
    if (this.onClick) this.onClick(item);
  }
}
