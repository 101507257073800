import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewEncapsulation,
} from '@angular/core';
import { AXFWidgetPrint } from '../../../config/widget';

@Component({
  selector: 'tr',
  templateUrl: './table-row-widget.print.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AXFTableRowWidgetPrint extends AXFWidgetPrint {
  rIndex!: number;

  constructor(private el: ElementRef, private cdr: ChangeDetectorRef) {
    super();
  }

  override onRender() {
    this.applyStyle(this.el.nativeElement);
    this.cdr.markForCheck();
  }
}
