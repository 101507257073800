import { Component, Input, ViewEncapsulation } from '@angular/core';
import { AXTextInputBaseComponent } from '../base.component';

@Component({
  selector: 'ax-text-area',
  templateUrl: './textarea.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class AXTextAreaComponent extends AXTextInputBaseComponent {
  @Input()
  rows: number = 3;
}
