<ng-container *ngIf="visible">
    <div style="display: flex;justify-content: center;align-items: center; width:100%">
        <div class="image" style="width: 130px; height: 120px; text-align: center;">
            <div class="axf-image-upload-container" (click)="openFile()" style="cursor: pointer;">
                <svg class="box_icon" xmlns="http://www.w3.org/2000/svg" width="50" height="43" viewBox="0 0 50 43">
                    <path
                        d="M48.4 26.5c-.9 0-1.7.7-1.7 1.7v11.6h-43.3v-11.6c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v13.2c0 .9.7 1.7 1.7 1.7h46.7c.9 0 1.7-.7 1.7-1.7v-13.2c0-1-.7-1.7-1.7-1.7zm-24.5 6.1c.3.3.8.5 1.2.5.4 0 .9-.2 1.2-.5l10-11.6c.7-.7.7-1.7 0-2.4s-1.7-.7-2.4 0l-7.1 8.3v-25.3c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v25.3l-7.1-8.3c-.7-.7-1.7-.7-2.4 0s-.7 1.7 0 2.4l10 11.6z">
                    </path>
                </svg>
                <span><strong>Choose file</strong></span>
            </div>
        </div>
    </div>
    
    <ng-container *ngIf="value">
        <table class="table table-bordered table-sm">
            <thead class="thead-dark">
                <tr>
                    <th>Row</th>
                    <th>File</th>
                    <th>Name</th>
                    <th>Create Date</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of value;let i=index">
                    <td>{{i+1}}</td>
                    <td><img *ngIf="item.srcData" src="./assets/images/file-icon.png" height="35" width="45"></td>
                    <td>
                        <ax-text-box [(text)]="item.name">
                        </ax-text-box>
                    </td>
                    <td>
                        {{item.createDate}}
                    </td>
                    <td>
                        <div class="progress" *ngIf="item.isLoading">
                            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                                aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
                        </div>
                        <ax-button type="light" size="sm" tooltip="Delete" (onClick)="deleteClick(item.index)"
                            *ngIf="!item.isLoading && !readonly">
                            <i class="fas fa-trash danger"></i>
                        </ax-button>
                        <ax-button type="light" size="sm" tooltip="View" (onClick)="viewClick(item.srcData)"
                        *ngIf="!item.isLoading">
                        <i class="fas fa-search"></i>
                    </ax-button>
                    </td>
                </tr>
            </tbody>
        </table>
    </ng-container>

</ng-container>
<input type="file" multiple="false" (change)="uploadImage($event)" style="display: none;" #fileInput name="filedata" />