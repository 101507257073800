import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewEncapsulation,
} from '@angular/core';
import { AXFWidgetPrint } from '../../../config/widget';

@Component({
  selector: 'td',
  templateUrl: './table-cell-widget.print.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AXFTableCellWidgetPrint extends AXFWidgetPrint {
  rIndex!: number;

  constructor(
    private el: ElementRef<HTMLTableCellElement>,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  override onRender() {
    this.applyStyle(this.el.nativeElement);
    if ((this as any)['colspan']) {
      this.el.nativeElement.colSpan = (this as any)['colspan'];
    }
    if ((this as any)['rowspan']) {
      this.el.nativeElement.rowSpan = (this as any)['rowspan'];
    }
    this.cdr.markForCheck();
  }
}
