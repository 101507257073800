<div #el *ngIf="visible">
    <ax-select-box [textField]="tField" [mode]="mode"
        [valueField]="vField" [allowSearch]="allowSearch" [selectedItems]="showItems(value)"
        (selectedItemsChange)="handleValueChange($event)" [isLoading]="isLoading" [items]="items"
        [readonly]="readonly" [columns]="displays"
        (onOpen)="onOpen()"  (onItemClick)="handleItemClick($event)">
    </ax-select-box>

    <table class="selecteditems" *ngIf="showSelected && mode=='multiple'"> 
        <tr *ngIf="value && value.length">
            <td colspan="2">{{title}}</td>
        </tr>
        <tr *ngFor="let item of value">
            <td>{{item[tField]}}</td>
            <td class="tdDel">
                <a (click)="delete(item)">
                    <i class="fas fa-times"></i>
                </a>
            </td>
        </tr>
    </table>
</div>