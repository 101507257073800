import { AXDialogService, MenuItem, SummaryButtonItem } from '@acorex/ui';
import { Component } from '@angular/core';
import { UpdateService } from './services/updateservice';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  /**
   *
   */
  constructor(
    private dialogService: AXDialogService,
    private sw: UpdateService
  ) {
    this.sw.checkForUpdates();
  }

  title = 'acorex-ui-sample';

  items = [
    { value: 'test1', title: 'test 1' },
    { value: 'test2', title: 'test 2' },
  ];

  viewModeItems: MenuItem[] = [
    {
      startIcon: 'fas fa-paint-brush',
      name: 'designer',
      text: ' Design View',
      groupName: 'mode',
      selected: true,
      style: 'light',
      data: 'designer',
    },
    {
      startIcon: 'fas fa-desktop',
      name: 'form',
      text: ' Preview',
      groupName: 'mode',
      style: 'light',
      data: 'view',
    },
  ];

  showDialog() {
    let btns: SummaryButtonItem[] = [
      {
        name: 'no',
        text: 'No',
        type: 'light',
        cancelBehavior: false,
        submitBehavior: false,
      },
      {
        name: 'yes',
        text: 'Yes',
        type: 'success',
        cancelBehavior: false,
        submitBehavior: false,
      },
    ];
    this.dialogService
      .show(
        'Unwrap saved widget',
        'Are you sure you want to unwrap this widget?',
        ...btns
      )
      .then((name) => {
        if (name === 'yes') {
        }
      });
  }
}
