<div class="allItem">
  <div class="rep" *ngFor="let item of items">
    <div style="padding: 6px">
      <span
        class="spanCheck"
        (click)="onCheckValueChange(getData(item, columns[0].fieldName))"
      >
        <i
          style="font-size: medium"
          class="fa fa-check"
          aria-hidden="true"
          *ngIf="showItem(getData(item, columns[0].fieldName))"
        ></i>
      </span>
      <div style="margin-left: 30px; font-size: medium">
        {{ getData(item, columns[1].fieldName) }}
      </div>
    </div>
  </div>
</div>
