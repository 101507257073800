import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
} from '@angular/core';
import { AXFDataSourceOption } from '../../../../property-editor/editors/data-source/data-source.class';
import { AXFWidgetPrint } from '../../../config/widget';

@Component({
  templateUrl: './dropdown-input-widget.print.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXFDropdownInputWidgetPrint extends AXFWidgetPrint {
  dataSource!: AXFDataSourceOption;
  text!: string;
  textAlign!: string;
  mode = 'single';
  constructor(
    private cdr: ChangeDetectorRef,
    private el: ElementRef<HTMLDivElement>
  ) {
    super();
  }

  override onRender(): void {
    if (this.el) {
      this.applyStyle(this.el.nativeElement);
    }
    if (
      this.value == undefined &&
      (this as any)['rIndex'] >= 0 &&
      (this as any)['dataContext'] != undefined &&
      (this as any)['dataContext'].hasOwnProperty((this as any)['name'])
    ) {
      this.value = [(this as any)['dataContext'][(this as any)['name']]];
    }
    this.cdr.detectChanges();
  }

  showItems() {
    if (this.value) {
      if (Array.isArray(this.value)) {
        return this.value
          .map((c) => c[(this.dataSource?.columns[1] as any)['fieldName']])
          .join(', ');
      } else {
        return this.value[(this.dataSource?.columns[1] as any)['fieldName']];
      }
    }
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();

    if (this.mode == 'multiple') {
      if (
        Array.isArray(this.value) &&
        this.value.length > 0 &&
        Array.isArray(this.value[0])
      )
        this.value = this.value[0];
    }

    if (this.dataSource.mode === 'manual' && this.dataSource.dataItems) {
      if (this.value) {
        if (Number.isInteger(this.value) || typeof this.value == 'string') {
          this.value = this.dataSource.dataItems.filter(
            (w) =>
              w[(this.dataSource?.columns[0] as any).fieldName] == this.value
          );
          this.cdr.detectChanges();
        } else this.cdr.detectChanges();
      } else {
        let defaultVals = this.dataSource.dataItems.filter(
          (s) => s.DefaultValue == true
        ); //.map((s) => { return s.value });
        if (defaultVals.length > 0) {
          this.value = defaultVals;
          this.cdr.detectChanges();
        }
      }
    }
    if (this.dataSource.mode === 'remote') {
      this.dataSource?.dataSource?.params.forEach((p) => {
        if (typeof p.value === 'string' && p.value.startsWith('$')) {
          p.value = this.resolveProperty(p.value);
        }
      });
      this.dataService
        ?.getList(
          this.dataSource?.dataSource?.name as string,
          this.dataSource?.dataSource?.params
        )
        .then((c) => {
          this.dataSource.dataItems = c;
          if (Number.isInteger(this.value))
            this.value = this.dataSource.dataItems.filter(
              (w) =>
                w[(this.dataSource?.columns[0] as any).fieldName] == this.value
            );
          this.cdr.detectChanges();
        });
    }
  }

  prepareText(val: any) {
    if (val) {
      if (Array.isArray(val)) {
        this.text = val
          .map((c) => c[(this.dataSource.columns[1] as any)['fieldName']])
          .join(', ');
      } else {
        this.text = val[(this.dataSource.columns[1] as any)['fieldName']];
      }
      this.cdr.detectChanges();
    }
  }

  binding() {
    if (
      this.value == undefined &&
      (this as any)['rIndex'] >= 0 &&
      (this as any)['dataContext'] != undefined &&
      (this as any)['dataContext'].hasOwnProperty((this as any)['name'])
    ) {
      if (this.dataSource.mode == 'remote') {
        this.dataSource?.dataSource?.params.forEach((p) => {
          if (typeof p.value === 'string' && p.value.startsWith('$')) {
            p.value = this.resolveProperty(p.value);
          }
        });
        this.dataService
          ?.getList(
            this.dataSource?.dataSource?.name as string,
            this.dataSource?.dataSource?.params
          )
          .then((c) => {
            this.dataSource.dataItems = c;
            let val = (this as any)['dataContext'][(this as any)['name']];
            if (
              typeof (this as any)['dataContext'][(this as any)['name']] ==
              'object'
            )
              val = (this as any)['dataContext'][(this as any)['name']][
                (this.dataSource?.columns[0] as any)['fieldName']
              ];
            this.value = this.dataSource.dataItems.filter(
              (w) => w[(this.dataSource?.columns[0] as any)['fieldName']] == val
            );
            this.prepareText(this.value);
          });
      } else {
        this.value = this.dataSource?.dataItems?.filter(
          (w) =>
            w[(this.dataSource?.columns[0] as any)['fieldName']] ==
            (this as any)['dataContext'][(this as any)['name']]
        );
        this.prepareText(this.value);
      }
    } else this.prepareText(this.value);
  }
}
