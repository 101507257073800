<div class="ax-field-set" #dropSelectBox [attr.id]="_uid"
    [ngClass]="{ 'ax-disabled': disabled, 'ax-readonly': readonly }" #el>
    <div class="ax-field-set-wrapper" [ngClass]="{ 'no-label': !label }" (click)="handleDropdownButtonClick($event)">
        <fieldset [ngClass]="{ 'input-focused': isFocused, 'input-error': errorText }">
            <legend *ngIf="label">
                {{ label }}
            </legend>
        </fieldset>
        <ng-container *ngIf="editorTemplate; else elseTemplate">
            <div class="editor-template">
                <ng-container *ngTemplateOutlet="editorTemplate"> </ng-container>
            </div>
        </ng-container>
        <ng-template #elseTemplate>
            <input type="text" [(ngModel)]="text" [placeholder]="placeholder" (keyup)="handleKeyEvent($event)"
                (blur)="handleBlurEvent($event)" (focus)="handleFocusEvent($event)" class="ax-text-box"
                [readonly]="readonly" disabled />
        </ng-template>
        <div class="ax-field-set-button">
            <button *ngIf="text && showClear" type="button" class="btn btn-light" (click)="clearText()">
                <i class="fas fa-times"></i>
            </button>
            <button type="button" class=" btn btn-primary">
                <i class="{{ icon }}"></i>
            </button>
        </div> 
    </div>
</div> 
<div class="validation-text" *ngIf="errorText">
    {{ errorText }}
</div> 
<ng-template #baseTemplate>
    <div class="ax-dropdown-container-bordered" [class.rtl]="rtl"
        [ngStyle]="{'min-width': dropdownWidth ? dropdownWidth + 'px' : 'unset'}">
        <ng-content>
        </ng-content>
    </div>
</ng-template>