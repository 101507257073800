<div #el>
    <div class="brd" *ngIf="visible">
        <table class="table table-bordered table-sm">
            <thead class="thead-dark">
                <tr *ngIf="dataSource">
                    <th *ngFor="let col of dataSource.columns">{{col.title}}</th>
                    <th *ngIf="allowAddDelete">
                        <ax-button type="primary" size="sm" tooltip="Add" (onClick)="addRowClick()">
                            <i class="fas fa-plus"></i> Add Row
                        </ax-button>
                    </th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngIf="dataSource">
                    <tr style="border-bottom: 1px solid var(--primary-color);" *ngFor="let item of value;let i=index">
                        <td *ngFor="let col of dataSource.columns">
                            <span
                                *ngIf="!col.fillByUser && col.fieldName==null && col.type!='image'">{{item | di:col.fieldName}}</span>
                            <span *ngIf="!col.fillByUser && col.fieldName!=null">{{item | di:col.fieldName}}</span>
                            <img *ngIf="!col.fillByUser && col.fieldName==null && col.type=='image'"
                                [src]="col.defaultValue" height="40" width="60">
                            <ax-text-box *ngIf="col.fillByUser && col.type=='string'"></ax-text-box>
                            <ax-text-box type="number" *ngIf="col.fillByUser && col.type=='number'"></ax-text-box>
                            <ax-date-picker label="" *ngIf="col.fillByUser && col.type=='date'"></ax-date-picker>
                            <ax-check-box *ngIf="col.fillByUser && col.type=='boolean'" label="' '">
                            </ax-check-box>
                            <ax-upload-file *ngIf="col.fillByUser && col.type=='image'" type="inline">
                            </ax-upload-file>
                            <ax-selection-list direction="horizontal"
                                *ngIf="col.fillByUser && col.type=='selectionList'">
                            </ax-selection-list>
                            <div class="signature" *ngIf="col.fillByUser && col.type=='signature'"
                                style="width: 51%; height: 52px;">
                                <img [src]="'./assets/images/No-Signature.jpg'" style="width: 50%; height: 50px;">
                                <div class="clear-box">
                                    <button class="btn btn-outline-danger" (click)="setSignatureClick(i)">
                                        <i class="fas fa-plus"></i>
                                    </button>
                                </div>
                            </div>
                        </td>
                        <td *ngIf="allowAddDelete">
                            <ax-button type="light" size="sm" tooltip="Delete" (onClick)="deleteClick(i)">
                                <i class="fas fa-trash danger"></i>
                            </ax-button>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>