import { AXDateTime } from '@acorex/ui';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { AXFWidgetPrint } from '../../../config/widget';

@Component({
  templateUrl: './time-input-widget.print.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXFTimeInputWidgetPrint extends AXFWidgetPrint {
  setCurrent: boolean = false;
  constructor(protected cdr: ChangeDetectorRef) {
    super();
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    if (!this.value && this.setCurrent)
      this.value = new AXDateTime().format('HH:mm');
    this.cdr.detectChanges();
  }
}
