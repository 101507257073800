import { AXDateTime } from '@acorex/ui';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { AXFWidgetPrint } from '../../../config/widget';

@Component({
  templateUrl: './date-input-widget.print.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXFDateInputWidgetPrint extends AXFWidgetPrint {
  textStyle!: string[];
  displayFormat!: string;
  setCurrent: boolean = false;
  calendarType: any;

  constructor(protected cdr: ChangeDetectorRef) {
    super();
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    if (
      this.value == undefined &&
      (this as any)['rIndex'] >= 0 &&
      (this as any)['dataContext'] != undefined &&
      (this as any)['dataContext'][(this as any)['name']] != null
    ) {
      this.value = new AXDateTime(
        (this as any)['dataContext'][(this as any)['name']].toString()
      );
    }
    if (this.value && this.calendarType != 'gregorian') {
      this.value = AXDateTime.convert(this.value, this.calendarType);
    }
    if (!this.value && this.setCurrent) this.value = new Date();
    this.cdr.detectChanges();
  }
}
