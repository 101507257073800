<table #table *ngIf="visible" style="min-width: 100%;width: 100%;
border-collapse: collapse;
border-spacing: 0px;
table-layout: fixed;" class="realTable">
    <thead>
        <ng-container *ngFor="let w of headerRows" axf-widget-renderer [widget]="w"
            [parent]="this" [mode]="'print'">
        </ng-container>
    </thead>
    <tbody>
        <ng-container *ngFor="let w of bodyRows;let r=index" axf-widget-renderer [rIndex]="r" [widget]="w"
            [parent]="this" [mode]="'print'">
        </ng-container>
    </tbody>
</table>