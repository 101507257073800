import { ChangeDetectorRef, Component } from '@angular/core';
import { AXFWidgetPrint } from '../../../config/widget';

@Component({
  templateUrl: './number-input-widget.print.html',
})
export class AXFNumberInputWidgetPrint extends AXFWidgetPrint {
  placeholder!: string;
  textStyle!: string[];
  textAlign!: string;
  decimalNumber!: number;
  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    let that: any = this;
    if (
      this.value == undefined &&
      that['rIndex'] >= 0 &&
      that['dataContext'] != undefined &&
      that['dataContext'].hasOwnProperty(that['name'])
    ) {
      this.value = that['dataContext'][that['name']];
    }
    if (
      this.decimalNumber &&
      Number(this.decimalNumber) > 0 &&
      this.value != undefined
    ) {
      if (this.value.toString().indexOf('.') == -1) {
        this.value += '.' + '0'.repeat(Number(this.decimalNumber));
      } else if (
        this.value.toString().length - this.value.toString().indexOf('.') !=
        Number(this.decimalNumber) + 1
      ) {
        let basePow = Math.pow(10, Number(this.decimalNumber));
        this.value = parseFloat(
          (Math.ceil(this.value * basePow) / basePow).toFixed(
            Number(this.decimalNumber)
          )
        );
      }
    }
    this.cdr.detectChanges();
    this.cdr.markForCheck();
  }
}
