import { ACoreXUIModule } from '@acorex/ui';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AngularImageViewerModule } from '@hreimer/angular-image-viewer';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { AXFWidgetService } from '../../../services/widget.service';
import {
  AXF_DATA_TYPE_PROPERTY,
  AXF_NAME_PROPERTY,
  AXF_TAG_PROPERTY,
  AXF_VISIBLE_PROPERTY,
} from '../../config/general-properties';
import { AXFWidgetSharedModule } from '../../shared/shared.module';
import { AXFFileUploadWidgetDesigner } from './designer/file-upload-widget.designer';
import { PdfModalPageComponent } from './pdfmodal.page';
import { AXFFileUploadWidgetPrint } from './print/file-upload-widget.print';
import { AXFFileUploadWidgetView } from './view/file-upload-widget.view';

export const COMPONENTS = [
  AXFFileUploadWidgetDesigner,
  AXFFileUploadWidgetPrint,
  AXFFileUploadWidgetView,
  PdfModalPageComponent,
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    ACoreXUIModule,
    AXFWidgetSharedModule,
    AngularImageViewerModule,
    NgxExtendedPdfViewerModule,
  ],
  exports: [...COMPONENTS],
  entryComponents: [...COMPONENTS],
  providers: [],
})
export class AXFFileUploadWidgetModule {
  constructor(service: AXFWidgetService) {
    service.register({
      title: 'File Upload',
      hint: 'File upload element',
      icon: 'far fa-file',
      category: 'Editors',
      visible: true,
      name: 'file-upload',
      designerClass: AXFFileUploadWidgetDesigner,
      printClass: AXFFileUploadWidgetPrint,
      viewClass: AXFFileUploadWidgetView,
      options: {
        dataType: 'object',
      },
      properties: [
        AXF_VISIBLE_PROPERTY,
        AXF_NAME_PROPERTY,
        AXF_DATA_TYPE_PROPERTY,
        AXF_TAG_PROPERTY,
      ],
    });
  }
}
