<div class="ax-field-set" [ngClass]="{ 'ax-disabled': disabled, 'ax-readonly': readonly }">
    <div class="ax-field-set-wrapper" [ngClass]="{ 'no-label': !label }">
      <fieldset [ngClass]="{ 'input-focused': isFocused, 'input-error': errorText }">
        <legend *ngIf="label">
          {{ label }}
        </legend>
      </fieldset>
      <input #input type="number" [(ngModel)]="text" [placeholder]="placeholder"
        [textMask]="{ mask: maskInner, guide: true }" (blur)="handleBlurEvent($event)" (focus)="handleFocusEvent($event)"
        (keyup)="handleKeyEvent($event)" class="ax-text-box" [readonly]="readonly"
        [attr.disabled]="disabled ? 'disabled' : null"   [attr.min]="minValue" [attr.max]="maxValue"/>
      <div class="ax-field-set-button">
        <button type="button" class="btn btn-light" (click)="clearText()" *ngIf="text && showClear">
          <i class="fas fa-times"></i>
        </button>
        <ng-content select="ax-button"> </ng-content>
      </div>
    </div>
  </div>
  <div class="validation-text" *ngIf="errorText">
    {{ errorText }}
  </div>