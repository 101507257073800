<div class="pnlCol">
    <table class="table table-bordered table-sm">
        <thead class="thead-dark">
            <tr>
                <th>Title</th>
                <th>Field Name</th>
                <th>Display in Grid</th>
                <th>Value Field</th>
                <th>Text Field</th>
                <th *ngIf="allowColumns">Type </th>
                <th *ngIf="allowColumns">Fill by User</th> 
                <th *ngIf="allowColumns">Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let col of columns;let i = index">
                <td>
                    <ax-text-box [(text)]="col.title" ></ax-text-box>
                </td>
                <td>
                    <ax-text-box [(text)]="col.fieldName"></ax-text-box>
                </td>
                <td style="text-align: center">
                   <ax-check-box label="" [(value)]="col.isDisplay">
                   </ax-check-box>
                </td>
                <td style="text-align: center">
                    <ax-check-box label="" [value]="col.valueField"  (valueChange)="setValueField($event,i)">
                    </ax-check-box>
                 </td>
                 <td style="text-align: center">
                    <ax-check-box label="" [value]="col.textField" (valueChange)="setTextField($event,i)">
                    </ax-check-box>
                 </td>
                <td *ngIf="allowColumns">
                    <ax-select-box [items]="columnTypeItems" valueField="value" textField="title"
                        [(selectedValues)]="col.type">
                    </ax-select-box>
                </td>
               
                <td *ngIf="allowColumns">
                    <ax-check-box label="Fill By User" [(value)]="col.fillByUser">
                    </ax-check-box>
                </td> 
                <td *ngIf="allowColumns">
                    <ax-button type="light" size="sm" tooltip="Up" (onClick)="upClick(i,col)">
                        <i class="fas fa-arrow-up primary"></i>
                    </ax-button>
                    <ax-button type="light" size="sm" tooltip="Down" (onClick)="downClick(i,col)">
                        <i class="fas fa-arrow-down primary"></i>
                    </ax-button>
                    <ax-button type="light" size="sm" tooltip="Delete" (onClick)="deleteClick(i)">
                        <i class="fas fa-trash danger"></i>
                    </ax-button>
                </td>
            </tr>
        </tbody>
    </table>
    <ax-button type="primary" size="sm" (onClick)="addItemClick()" tooltip="Add New">
        <i class="fas fa-plus"></i>&nbsp;
        <span>Add New Column</span>
    </ax-button>
</div>