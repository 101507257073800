import {
  ChangeDetectorRef,
  Directive,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Directive()
export abstract class AXFProperyEditor<T> {
  locked: boolean = false;
  initiated: boolean = false;

  @Output()
  valueChange: EventEmitter<T> = new EventEmitter<T>();

  private _value!: T;
  @Input()
  public get value(): T {
    return this._value;
  }
  public set value(v: T) {
    this._value = v;
    this.valueChange.emit(v);
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  constructor(protected cdr: ChangeDetectorRef) {}

  handleValueChange(v: T) {
    this.value = v;
  }
}
