import { AXPopupService } from '@acorex/ui';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AXFValueWidgetView } from '../../../config/widget';
import { ExpandTextPage } from '../expandtext.page';

@Component({
  selector: '[axf-widget-text]',
  templateUrl: './text-area-widget.view.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXFTextAreaWidgetView extends AXFValueWidgetView {
  placeholder!: string;
  rows!: number;
  private dataSubscription: Subscription | undefined;

  constructor(
    protected override cdr: ChangeDetectorRef,
    private popupService: AXPopupService,
    private hostElement: ElementRef
  ) {
    super(cdr);
    this.dataSubscription = this.dataService?.onChange.subscribe(() => {
      this.refresh();
    });
  }

  override onRender(): void {
    if (
      this.value == undefined &&
      (this as any)['rIndex'] >= 0 &&
      (this as any)['dataContext'] != undefined &&
      (this as any)['dataContext'].hasOwnProperty((this as any)['name'])
    ) {
      this.value = (this as any)['dataContext'][(this as any)['name']];
    }
    this.cdr.markForCheck();
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    let el: HTMLElement = this.hostElement.nativeElement as HTMLElement;
    let axButtonPlace = el.firstElementChild?.firstElementChild?.firstChild
      ?.lastChild as HTMLElement;
    axButtonPlace.innerHTML =
      '<ax-button style="bottom: 2px;position: absolute;right: 6px;"/></ax-button>';
    var button = document.createElement('button');
    button.innerHTML = '<i class="fa-expand fas"></i>';
    button.className = 'btn btn-md btn-primary';
    let that = this;
    button.onclick = function () {
      that.popupService
        .open(ExpandTextPage, {
          title: 'Expand Text',
          size: 'md',
          data: {
            value: that.value,
            readonly: that.readonly,
          },
        })
        .closed((c: any) => {
          if (c.data) that.value = c.data;
        });
    };
    (axButtonPlace.firstChild as HTMLElement).append(button);
    this.cdr.detectChanges();
  }

  override ngOnDestroy() {
    this.dataSubscription?.unsubscribe();
    super.ngOnDestroy();
  }
}
