import { AXDataColumn } from '@acorex/ui';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  ViewChild,
} from '@angular/core';
import { AXFValueWidgetView } from '../../../config/widget';

@Component({
  templateUrl: './dropdown-input-widget.view.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./dropdown-input-widget.view.scss'],
})
export class AXFDropdownInputWidgetView extends AXFValueWidgetView {
  @ViewChild('el') el!: ElementRef<HTMLElement>;

  mode: 'single' | 'multiple' = 'single';

  allowSearch!: boolean;
  dataSource!: any;
  isLoading = true;
  selectedItems: any = [];
  displays: AXDataColumn[] = [];
  items: AXDataColumn[] = [];
  vField: string = '';
  tField: string = '';
  showSelected!: boolean;
  title!: string;

  constructor(
    protected override cdr: ChangeDetectorRef,
    private ref: ElementRef,
    private zone: NgZone
  ) {
    super(cdr);
  }

  showItems(value: any) {
    //return value ? (Array.isArray(value) ? value : [value]) : [];

    if (value) {
      if (this.dataSource.mode === 'manual' && this.dataSource.dataItems) {
        if (Array.isArray(value)) {
          value.forEach((vl) => {
            if (
              !vl.hasOwnProperty((this.dataSource.columns[1] as any).fieldName)
            ) {
              let fullDt = this.dataSource.dataItems.find(
                (w) =>
                  w[(this.dataSource.columns[0] as any).fieldName] ==
                  vl[(this.dataSource.columns[0] as any).fieldName]
              );
              if (fullDt)
                vl[(this.dataSource.columns[1] as any).fieldName] =
                  fullDt[(this.dataSource.columns[1] as any).fieldName];
            }
          });
        } else {
          if (
            !value.hasOwnProperty(
              (this.dataSource.columns[1] as any).fieldName
            ) &&
            this.dataSource.dataItems
          ) {
            let fullDt = this.dataSource.dataItems.find(
              (w) =>
                w[(this.dataSource.columns[0] as any).fieldName] ==
                value[(this.dataSource.columns[0] as any).fieldName]
            );
            if (fullDt)
              value[(this.dataSource.columns[1] as any).fieldName] =
                fullDt[(this.dataSource.columns[1] as any).fieldName];
          }
          value = [value];
        }
        return value;
      } else return Array.isArray(value) ? value : [value];
    } else return [];
  }

  override onRender(): void {
    if (this.el) {
      this.applyStyle(this.el.nativeElement);
    }
    if (
      this.value == undefined &&
      (this as any)['rIndex'] >= 0 &&
      (this as any)['dataContext'] != undefined &&
      (this as any)['dataContext'].hasOwnProperty((this as any)['name'])
    ) {
      this.value = [(this as any)['dataContext'][(this as any)['name']]];
    }
    this.cdr.detectChanges();
  }

  override ngOnInit() {
    this.vField = (this.dataSource.columns[0] as any).fieldName;
    this.tField = (this.dataSource.columns[1] as any).fieldName;
    this.cdr.detectChanges();
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.items = this.dataSource.dataItems as AXDataColumn[];
    if (this.dataSource.columns.filter((s) => s.isDisplay).length > 1) {
      this.displays = this.dataSource.columns
        .filter((s) => s.isDisplay)
        .map(function (m) {
          return <AXDataColumn>{ dataField: m.fieldName, title: m.title };
        });
    }
    if (this.mode == 'multiple') {
      if (
        Array.isArray(this.value) &&
        this.value.length > 0 &&
        Array.isArray(this.value[0])
      )
        this.value = this.value[0];
    }
    if (this.dataSource.mode === 'manual' && this.dataSource.dataItems) {
      if (this.value) {
        if (Number.isInteger(this.value) || typeof this.value == 'string') {
          this.value = this.dataSource.dataItems.filter(
            (w) =>
              w[(this.dataSource.columns[0] as any).fieldName] == this.value
          );
          this.cdr.detectChanges();
        }
        // else {
        //     if (Array.isArray(this.value)) {
        //         let newVals = [];
        //         for (let index = 0; index < this.value.length; index++) {
        //             const vl = this.value[index];
        //             if (!vl.hasOwnProperty((this.dataSource.columns[1] as any).fieldName)) {
        //                 let fullDt = this.dataSource.dataItems.find(w => w[(this.dataSource.columns[0] as any).fieldName] == vl[(this.dataSource.columns[0] as any).fieldName])
        //                 if (fullDt)
        //                     vl[(this.dataSource.columns[1] as any).fieldName] = fullDt[(this.dataSource.columns[1] as any).fieldName];
        //             }
        //             newVals.push(vl);
        //         }
        //         this.value = newVals;
        //     }
        //     else
        //     {
        //         if (!this.value.hasOwnProperty((this.dataSource.columns[1] as any).fieldName)) {
        //             let fullDt = this.dataSource.dataItems.find(w => w[(this.dataSource.columns[0] as any).fieldName] == this.value[(this.dataSource.columns[0] as any).fieldName])
        //             if (fullDt)
        //             this.value[(this.dataSource.columns[1] as any).fieldName] = fullDt[(this.dataSource.columns[1] as any).fieldName];
        //         }
        //     }
        //     this.cdr.detectChanges();
        // }
      } else {
        let defaultVals = this.dataSource.dataItems.filter(
          (s) => s.DefaultValue == true
        );
        if (defaultVals.length > 0) {
          this.value = defaultVals;
          this.cdr.detectChanges();
        }
      }
    }
    if (this.dataSource.mode === 'remote') {
      this.dataSource.dataSource?.params.forEach((p) => {
        if (typeof p.value === 'string' && p.value.startsWith('$')) {
          p.value = this.resolveProperty(p.value);
        }
      });
      this.dataService
        ?.getList(
          this.dataSource.dataSource?.name as string,
          this.dataSource.dataSource?.params
        )
        .then((items) => {
          // console.log('items fetched!', items);
          if (items && items.length) {
            this.dataSource.dataItems = items;
            if (
              this.dataSource.displayMode === 'onlySelected' &&
              this.dataSource.displayItems &&
              this.dataSource.displayItems.length > 0
            )
              this.items = items.filter((d) =>
                this.dataSource?.displayItems?.includes(
                  d[(this.dataSource.columns[0] as any).fieldName]
                )
              );
            else this.items = items;
            if (Number.isInteger(this.value))
              this.value = this.dataSource.dataItems.filter(
                (w) =>
                  w[(this.dataSource.columns[0] as any).fieldName] == this.value
              );
            this.cdr.detectChanges();
          }
        });
    }
  }

  override refresh() {
    this.isLoading = true;

    if (this.dataSource.mode === 'remote') {
      this.dataService
        ?.getList(
          this.dataSource.dataSource?.name as string,
          this.dataSource.dataSource?.params
        )
        .then((c) => {
          this.dataSource.dataItems = c;
          super.refresh();
        });
    } else {
      this.dataBound();
      super.refresh();
    }

    // if (this.dataSource.mode === 'remote') {
    //     this.dataSource.dataItems = [];
    // } else {
    //     this.dataBound();
    // }
    // super.refresh();
  }

  private dataBound() {
    this.isLoading = false;
    this.invokeEvent('onDataBound');
    this.cdr.markForCheck();
  }

  handleValueChange(e: any) {
    this.value = this.mode === 'single' ? e[0] : e;
  }

  reload() {
    //setTimeout(() => {
    if (!this.readonly) {
      this.reset();
    }
    //}, 2000);
  }

  clear() {
    this.value = this.mode === 'single' ? null : [];
  }

  reset() {
    this.clear();
    if (this.dataSource.mode === 'remote') {
      this.dataSource.dataItems = [];
    }
    this.items = [];
  }

  onOpen() {
    if (this.dataSource.mode === 'remote') {
      console.log('yellow!', this.dataSource.dataSource.params);
      if (
        this.dataSource.dataItems == null ||
        this.dataSource.dataItems.length === 0 ||
        this.dataSource?.dataSource?.params.some(
          (p) => typeof p.value === 'string' && p.value.startsWith('$')
        ) // get RelatedDS if it depends on some other licence
      ) {
        this.dataSource?.dataSource?.params.forEach((p) => {
          if (typeof p.value === 'string' && p.value.startsWith('$')) {
            p.value = this.resolveProperty(p.value);
          }
        });
        this.dataService
          ?.getList(
            this.dataSource.dataSource?.name as string,
            this.dataSource.dataSource?.params
          )
          .then((c) => {
            this.dataSource.dataItems = c;
            if (
              this.dataSource.displayMode === 'onlySelected' &&
              this.dataSource.displayItems &&
              this.dataSource.displayItems.length > 0
            )
              this.items = c.filter((d) =>
                this.dataSource?.displayItems?.includes(
                  d[(this.dataSource.columns[0] as any).fieldName]
                )
              );
            else this.items = c;
            this.dataBound();
          });
      } else {
        this.dataBound();
      }
    } else this.dataBound();
  }

  // ****** api functions *******//
  setSelectedIndex(index: number) {
    const item: any = this.dataSource.dataItems
      ? this.dataSource.dataItems[index]
      : undefined;
    if (item) {
      this.value = item;
    }
  }

  handleItemClick(item: any) {
    this.invokeEvent('onItemClick');
  }

  delete(item: any) {
    this.value = this.value.filter(
      (w: any) => w[this.vField] != item[this.vField]
    );
    this.cdr.detectChanges();
  }
}
