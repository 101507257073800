import { AXBasePageComponent, AXPopupService, ClosingAction } from '@acorex/ui';
import { Component } from '@angular/core';
import { AXFExpressionEditorComponent } from './expression-editor.component';
import { AXFFormulaModel } from './formula.class';

@Component({
  templateUrl: './formula-manage.component.html',
  styleUrls: ['./formula-manage.component.scss'],
})
export class AXFFormulaManageComponent extends AXBasePageComponent {
  readonly: boolean = true;
  public data: AXFFormulaModel[] = [];
  typeItems: any[] = [
    { value: 'string', title: 'String' },
    { value: 'number', title: 'Number' },
    { value: 'boolean', title: 'Boolean' },
    { value: 'date', title: 'Date' },
    { value: 'time', title: 'Time' },
    { value: 'image', title: 'Image' },
  ];

  constructor(private popupService: AXPopupService) {
    super();
  }

  override onClosing(e: ClosingAction) {
    e.data = {
      actions: this.data,
    };
    e.resolve();
  }

  deleteClick(ind: number) {
    this.data.splice(ind, 1);
  }

  addItemClick() {
    if (!this.data) {
      this.data = [];
    }
    const index = this.data.length + 1;
    const newRow: AXFFormulaModel = {
      variable: 'var' + index.toString(),
      expression: '',
      dataType: 'string',
    };
    this.data.push(newRow);
  }

  handleShowEditor(ind: any, dt: any) {
    this.popupService
      .open(AXFExpressionEditorComponent, {
        title: 'Expression',
        size: 'lg',
        data: {
          code: dt,
        },
      })
      .closed((c: any) => {
        this.data[ind].expression = c.data;
      });
  }
}
