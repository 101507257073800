<div class="pnlCol">
    <table class="table table-bordered table-sm">
        <thead class="thead-dark">
            <tr>
                <th>Variable Name</th>
                <th>Data Type</th> 
                <th>Expression</th>
                <th></th>
            </tr>
        </thead>
        <tbody *ngIf="data">
            <tr *ngFor="let dt of data;let i = index">
                <td>
                    <ax-text-box [(text)]="dt.variable" ></ax-text-box>
                </td>
                <td> 
                    <ax-select-box [items]="typeItems" valueField="value" textField="title"
                        [(selectedValues)]="dt.dataType">
                    </ax-select-box>
                </td>
                <td> 
                    <ax-text-box  [(text)]="dt.expression" [readonly]="readonly">
                        <ax-button (onClick)="handleShowEditor(i,dt.expression)">
                            <i class="fas fas fa-code"></i>
                        </ax-button>
                    </ax-text-box>
                </td> 
                <td>  
                    <ax-button type="light" size="sm" tooltip="Delete" (onClick)="deleteClick(i)">
                        <i class="fas fa-trash danger"></i>
                    </ax-button>
                </td>
            </tr>
        </tbody>
    </table>
    <ax-button type="primary" size="sm" (onClick)="addItemClick()" tooltip="Add New">
        <i class="fas fa-plus"></i>&nbsp;
        <span>Add New Variable</span>
    </ax-button>
</div>