import { ACoreXUIModule } from '@acorex/ui';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AXFWidgetService } from '../../../services/widget.service';
import {
  AXF_DISPLAY_NAME_PROPERTY,
  AXF_DS_LIST_PROPERTY,
  AXF_NAME_PROPERTY,
  AXF_TAG_PROPERTY,
  AXF_VISIBLE_PROPERTY,
} from '../../config/general-properties';
import { AXFWidgetSharedModule } from '../../shared/shared.module';
import { AXFGridInputWidgetDesigner } from './designer/grid-input-widget.designer';
import { AXFGridInputWidgetPrint } from './print/grid-input-widget.print';
import { AXFGridInputWidgetView } from './view/grid-input-widget.view';

export const COMPONENTS = [
  AXFGridInputWidgetDesigner,
  AXFGridInputWidgetPrint,
  AXFGridInputWidgetView,
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [CommonModule, ACoreXUIModule, AXFWidgetSharedModule],
  exports: [...COMPONENTS],
  entryComponents: [...COMPONENTS],
  providers: [],
})
export class AXFGridInputWidgetModule {
  constructor(service: AXFWidgetService) {
    service.register({
      title: 'Data Table',
      hint: 'Grid table element',
      icon: 'fas fa-table',
      category: 'Editors',
      visible: true,
      name: 'grid',
      designerClass: AXFGridInputWidgetDesigner,
      printClass: AXFGridInputWidgetPrint,
      viewClass: AXFGridInputWidgetView,
      options: {},
      properties: [
        AXF_DS_LIST_PROPERTY,
        {
          name: 'allowAddDelete',
          category: 'Data',
          defaultValue: false,
          title: 'Allow Add/Delete',
          editor: 'CheckboxEditor',
          options: { label: 'Allow Add/Delete' },
          visible: (options: any) => {
            if (options.dataSource && options.dataSource.mode == 'manual')
              return true;
            else return false;
          },
        },
        AXF_VISIBLE_PROPERTY,
        AXF_NAME_PROPERTY,
        AXF_DISPLAY_NAME_PROPERTY,
        AXF_TAG_PROPERTY,
      ],
    });
  }
}
