<div class="ax-select-box" [ngClass]="{ 'ax-disabled': disabled, 'ax-readonly': readonly }">
    <ax-drop-down [disabled]="disabled" [readonly]="readonly" [fitParent]="true" [text]="text" label="{{ label }}" #d
      (onOpen)="handleOnOpen()" (onClose)="handleOnClose()">
      <div class="list-container">
        <div *ngIf="allowSearch" class="search">
          <ax-search-box [text]="searchText" (onTextChanged)="handleSearchChanged($event)"></ax-search-box>
        </div>
        <ng-container *ngIf="isLoading; then loadingTemplate; else elseLoadingTemplate">
        </ng-container>
        <ng-template #elseLoadingTemplate>
          <ng-container *ngIf="displayItems.length; then thenTemplate; else elseTemplate"></ng-container>
          <ng-template #thenTemplate>
            <ng-container *ngIf="columns && columns.length; then tableTemplate; else simpleTemplate"></ng-container>
            <ng-template #tableTemplate>
              <div class="viewport">
                <table class="table  table-bordered table-hover table-sm">
                  <thead>
                    <tr>
                      <th scope="col" *ngFor="let col of columns">{{col.title}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of displayItems" [class.selected]="isItemSelected(item)"
                      (click)="handleItemClick(item)">
                      <td *ngFor="let col of columns">{{item[col.dataField]}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ng-template>
            <ng-template #simpleTemplate>
              <div class="viewport">
                <div class="text-item" [class.selected]="isItemSelected(item)" *ngFor="let item of displayItems"
                  (click)="handleItemClick(item)">
                  {{ item[textField] }}
                </div>
              </div>
            </ng-template>
          </ng-template>
          <ng-template #elseTemplate>
            <div class="text-item">
              No Result Found
            </div>
          </ng-template>
        </ng-template>
        <ng-template #loadingTemplate>
          Loading...
        </ng-template>
      </div>
    </ax-drop-down>
  </div>