import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewEncapsulation,
} from '@angular/core';
import { AXFWidgetView } from '../../../config/widget';

@Component({
  selector: 'td',
  templateUrl: './table-cell-widget.view.html',
  styleUrls: ['./table-cell-widget.view.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AXFTableCellWidgetView extends AXFWidgetView {
  rIndex!: number;
  title!: string;
  constructor(
    private el: ElementRef<HTMLTableCellElement>,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  override onRender() {
    this.applyStyle(this.el.nativeElement);
    if ((this as any)['colspan']) {
      this.el.nativeElement.colSpan = (this as any)['colspan'];
    }
    if ((this as any)['rowspan']) {
      this.el.nativeElement.rowSpan = (this as any)['rowspan'];
    }
    if (!(this as any)['width']) {
      this.el.nativeElement.style.width = '100px';
    }
    if (
      this.rIndex > -1 ||
      (
        this.el.nativeElement.parentElement?.parentElement
          ?.parentElement as HTMLElement
      ).classList.contains('responsive')
    ) {
      if (this.title && this.title != '') {
        this.el.nativeElement.setAttribute('data-title', this.title);
      } else {
        if (
          (this.el.nativeElement?.parentElement
            ?.parentElement as HTMLElement) &&
          (this.el.nativeElement?.parentElement?.parentElement as HTMLElement)
            .localName == 'tbody'
        ) {
          let ttElm = (
            this.el.nativeElement?.parentElement?.parentElement
              ?.firstElementChild as Element
          ).children[this.el.nativeElement.cellIndex];
          if (
            ttElm &&
            ttElm.getAttribute('data-title') != null &&
            ttElm.getAttribute('data-title') != ''
          ) {
            this.el.nativeElement.setAttribute(
              'data-title',
              ttElm.getAttribute('data-title')!
            );
          }
        }
      }
    }

    this.cdr.detectChanges();
  }
}
