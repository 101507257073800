import { AXToastService } from '@acorex/ui';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { AXFConnectService } from '../../../../services/connect.service';
import { AXFValueWidgetView } from '../../../config/widget';

@Component({
  selector: '[axf-video-input]',
  templateUrl: './video-input-widget.view.html',
  styleUrls: ['./video-input-widget.view.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    style: 'display: flex;justify-content: center;align-items: center;',
    tabIndex: '0',
  },
})
export class AXFVideoInputWidgetView extends AXFValueWidgetView {
  height!: number;
  width!: number;
  isLoading = false;

  @ViewChild('fileInputV') fileInputV!: ElementRef<HTMLElement>;
  constructor(
    protected override cdr: ChangeDetectorRef,
    private connectService: AXFConnectService,
    private toastService: AXToastService,
    private ref: ElementRef<HTMLDivElement>
  ) {
    super(cdr);
    document.addEventListener('paste', this.onDocPaste.bind(this));
  }

  openFile() {
    if (!this.readonly) {
      this.connectService
        .send('getMedia')
        .then(async (data) => {
          if (data) {
            await this.bindData(data);
          }
        })
        .catch(() => {
          this.fileInputV.nativeElement.click();
        });
    }
  }

  override onRender(): void {
    this.cdr.markForCheck();
  }

  async handleValueChange(evt: any) {
    const data = evt.data;
    await this.bindData(data);
  }

  private async bindData(data: any) {
    this.isLoading = true;
    this.cdr.detectChanges();
    this.connectService
      .send('uploadFile', { data })
      .then((c) => {
        this.value = {
          orginalHeight: this.height,
          orginalWidth: this.width,
          srcData: c,
        };
      })
      .finally(() => {
        this.isLoading = false;
        this.ref.nativeElement.scrollIntoView();
        this.cdr.detectChanges();
      });
  }

  uploadVideo(e: any) {
    this.isLoading = true;
    this.cdr.detectChanges();
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    const pattern = /video-*/;
    const reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  remove() {
    this.value = null;
  }

  async _handleReaderLoaded(e: any) {
    const reader = e.target;
    this.bindData(reader.result);
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    document.removeEventListener('paste', this.onDocPaste.bind(this));
  }

  private onDocPaste(event: ClipboardEvent) {
    if (this.ref.nativeElement == document.activeElement) {
      var items = event.clipboardData?.items as DataTransferItemList;
      var blob = items[0].getAsFile();
      if (blob) {
        var reader = new FileReader();
        reader.onload = this._handleReaderLoaded.bind(this);
        reader.readAsDataURL(blob);
      }
    }
  }

  onClickPaste(e: MouseEvent) {
    e.stopPropagation();
    this.ref.nativeElement.focus();
    setTimeout(() => {
      this.toastService.success('Now press (Ctrl + V) to paste video', {
        timeOut: 3000,
      });
    }, 200);
  }
}
