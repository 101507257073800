import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { AXFValueWidgetView } from '../../../config/widget';

@Component({
  templateUrl: './number-input-widget.view.html',
  //styleUrls: ['./number-input-widget.view.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXFNumberInputWidgetView extends AXFValueWidgetView {
  defaultValue!: number;
  placeholder!: string;
  decimalNumber!: number;

  constructor(protected override cdr: ChangeDetectorRef) {
    super(cdr);
    this.valueChange.subscribe(() => {
      if (
        this.decimalNumber &&
        Number(this.decimalNumber) > 0 &&
        this.value &&
        this.value.toString().length - this.value.toString().indexOf('.') !=
          Number(this.decimalNumber) + 1
      ) {
        let basePow = Math.pow(10, Number(this.decimalNumber));
        this.value = Number(
          (Math.ceil(this.value * basePow) / basePow).toFixed(
            Number(this.decimalNumber)
          )
        );
        this.cdr.markForCheck();
        this.cdr.detectChanges();
      }
    });
  }

  override onRender(): void {
    let that: any = this;
    if (
      this.value === undefined &&
      that['rIndex'] >= 0 &&
      that['dataContext'] !== undefined &&
      that['dataContext'].hasOwnProperty(that['name'])
    ) {
      this.value = that['dataContext'][that['name']];
    }
    this.cdr.markForCheck();
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    if (this.value === undefined && this.defaultValue !== undefined) {
      this.value = this.defaultValue;
    }
  }

  override valueChanging(v: any) {
    return Number(v);
  }
}
