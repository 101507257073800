import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewEncapsulation,
} from '@angular/core';
import { AXFWidgetDesigner } from '../../../config/widget';

@Component({
  selector: '[axf-widget-checkbox]',
  templateUrl: './checkbox-input-widget.designer.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXFCheckboxInputWidgetDesigner extends AXFWidgetDesigner {
  textAlign!: string;
  defaultValue!: boolean;
  label!: string;
  constructor(
    private el: ElementRef<HTMLElement>,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  override onRender(): void {
    this.el.nativeElement.style.textAlign = this.textAlign;
    this.applyStyle(
      this.el.nativeElement.querySelector('label') as HTMLElement
    );
    if (this.el.nativeElement != null) {
      let that: any = this;
      (
        this.el.nativeElement.querySelector('span') as HTMLElement
      ).style.width = `${this.getSize(that['fontSize'])}px`;
      (
        this.el.nativeElement.querySelector('span') as HTMLElement
      ).style.height = `${this.getSize(that['fontSize'])}px`;
      if (this.label && this.label != '') {
        if (document.getElementsByClassName('ltr').length > 0)
          (
            this.el.nativeElement.querySelector('label') as HTMLElement
          ).style.paddingLeft = `${that.getSize(that['fontSize']) + 5}px`;
        else
          (
            this.el.nativeElement.querySelector('label') as HTMLElement
          ).style.paddingRight = `${that.getSize(that['fontSize']) + 5}px`;
        (
          this.el.nativeElement.querySelector('ax-check-box') as HTMLElement
        ).style.display = 'inline-block';
      } else
        (
          this.el.nativeElement.querySelector('ax-check-box') as HTMLElement
        ).style.display = 'initial';
      (
        this.el.nativeElement.querySelector('ax-check-box') as HTMLElement
      ).style.paddingBottom = `${
        that.getSize(that['fontSize']) < 15
          ? 0
          : that.getSize(that['fontSize']) - 15
      }px`;
      (
        this.el.nativeElement.querySelector('ax-check-box') as HTMLElement
      ).style.backgroundColor = that['bgColor'];
    }
    this.cdr.markForCheck();
  }

  getSize(font: string) {
    switch (font) {
      case 'xx-small':
      case 'x-small':
        return 12;
      case 'smaller':
      case 'inherit':
        return 15;
      default:
      case 'small':
        return 20;
      case 'medium':
        return 25;
      case 'large':
        return 30;
      case 'larger':
        return 35;
      case 'x-large':
      case 'xx-large':
        return 40;
    }
  }
}
