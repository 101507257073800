import { ACoreXUIModule } from '@acorex/ui';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SignaturePadModule } from 'angular2-signaturepad';
import { AXFEditorService } from '../../../services/editor.service';
import { AXFDataColumnEditorComponent } from './columns.component';
import { AXFDataSourceEditorComponent } from './data-source.editor';
import { AXIDataDisplayEditorComponent } from './display.component';
import { AXIDataItemEditorComponent } from './items.component';

@NgModule({
  declarations: [
    AXFDataSourceEditorComponent,
    AXFDataColumnEditorComponent,
    AXIDataItemEditorComponent,
    AXIDataDisplayEditorComponent,
  ],
  imports: [CommonModule, ACoreXUIModule, FormsModule, SignaturePadModule],
  exports: [AXFDataSourceEditorComponent],
  entryComponents: [
    AXFDataSourceEditorComponent,
    AXFDataColumnEditorComponent,
    AXIDataItemEditorComponent,
    AXIDataDisplayEditorComponent,
  ],
  providers: [],
})
export class AXFDataSourceEditorModule {
  constructor(service: AXFEditorService) {
    service.register('DataSourceEditor', AXFDataSourceEditorComponent);
  }
}
