import { AXPopupService, ClosedEventArgs } from '@acorex/ui';
import { ChangeDetectorRef, Component } from '@angular/core';
import { AXFProperyEditor } from '../../config/editor';
import { AXFFormulaManageComponent } from './formula-manage.component';
import { AXFFormulaModel } from './formula.class';

@Component({
  template: `
    <ax-button
      (onClick)="showActions($event)"
      [text]="title"
      size="sm"
      [block]="blocked"
    >
    </ax-button>
  `,
})
export class AXFFormulaEditorComponent extends AXFProperyEditor<
  AXFFormulaModel[]
> {
  title = 'Variables / Formula';
  blocked: boolean = true;
  constructor(
    protected override cdr: ChangeDetectorRef,
    private popupService: AXPopupService
  ) {
    super(cdr);
  }

  showActions(e: any) {
    this.popupService
      .open(AXFFormulaManageComponent, {
        title: this.title,
        size: 'md',
        data: {
          data: this.value,
        },
      })
      .closed((c?: ClosedEventArgs): void => {
        this.value = c?.data.actions;
        super.handleValueChange(this.value);
      });
  }
}
