<div class="edit-row ng-star-inserted">
    <div class="prop-caption"> Mode Size </div>
    <ax-selection-list direction="horizontal" mode="single" [items]="modeItems" [selectedValues]="value.modeSize"
        (selectedValuesChange)="modeSizeChange($event)" textField="title" valueField="value">
    </ax-selection-list>
</div>
<div *ngIf="value.modeSize=='custom'" class="mrgbtn">
    <ax-check-box label="Aspect Ratio" [value]="value.isAspectRatio" (valueChange)="aspectRatioChange($event)">
    </ax-check-box>
</div>
<div class="edit-row ng-star-inserted">
    <div class="prop-caption"> Width (px) </div>
    <ax-number-box [text]="value.width" (textChange)="widthChange($event)"
        [disabled]="value.modeSize=='auto'">
    </ax-number-box>
</div>
<div class="edit-row ng-star-inserted">
    <div class="prop-caption"> Height (px) </div>
    <ax-number-box [text]="value.height" (textChange)="heightChange($event)"
        [disabled]="value.modeSize=='auto'">
    </ax-number-box>
</div>
<div class="edit-row ng-star-inserted">
    <div class="prop-caption"> Source </div>
    <ax-selection-list direction="horizontal" mode="single" [items]="methods" [(selectedValues)]="value.sourceMethod"
        textField="title" valueField="value">
    </ax-selection-list>
</div>
<div class="edit-row ng-star-inserted" *ngIf="value.sourceMethod=='url'">
    <div class="prop-caption"> URL </div>
    <ax-text-box [(text)]="value.srcData" (textChange)="handleTextChange()">
    </ax-text-box>
</div>
<div class="edit-row ng-star-inserted" *ngIf="value.sourceMethod=='upload'">
    <div class="prop-caption"> Upload </div>
    <ax-upload-file type="inline" *ngIf="mode=='image'" (onLoad)="handleValueChange($event)">
    </ax-upload-file>
    <ax-text-box  *ngIf="mode=='video'"  >
        <ax-button (onClick)="selectVideo()">
            <i class="fas fa-upload"></i> 
        </ax-button>
    </ax-text-box>  
<input type="file" multiple="false" (change)="uploadVideo($event)" style="display: none;" #fileVideoBox name="fileVideoBox" />
</div>