import { ChangeDetectorRef, Component } from '@angular/core';
import { AXFWidgetPrint } from '../../../config/widget';

@Component({
  templateUrl: './link-input-widget.print.html',
})
export class AXFLinkInputWidgetPrint extends AXFWidgetPrint {
  placeholder!: string;
  textStyle!: string[];
  textAlign!: string;
  color!: string;
  defaultValue!: string;
  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    if (this.value === undefined && this.defaultValue !== undefined) {
      this.value = this.defaultValue;
    }
    let that: any = this;
    if (
      this.value == undefined &&
      that['rIndex'] >= 0 &&
      that['dataContext'] != undefined &&
      that['dataContext'].hasOwnProperty(that['name'])
    ) {
      this.value = that['dataContext'][that['name']];
    }
    this.cdr.detectChanges();
  }
}
