import { AXPopupService } from '@acorex/ui';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { AXFConnectService } from '../../../../services/connect.service';
import { AXFUrlResolverService } from '../../../../services/url-resolver.service';
import { AXFValueWidgetView } from '../../../config/widget';
import { PdfModalPageComponent } from '../pdfmodal.page';

@Component({
  selector: '[axf-file-upload]',
  templateUrl: './file-upload-widget.view.html',
  styleUrls: ['./file-upload-widget.view.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  //host: { style: 'display: flex;justify-content: center;align-items: center;' }
})
export class AXFFileUploadWidgetView extends AXFValueWidgetView {
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLElement>;
  constructor(
    protected override cdr: ChangeDetectorRef,
    private connectService: AXFConnectService,
    private resolverService: AXFUrlResolverService,
    private popupService: AXPopupService,
    private ref: ElementRef<HTMLDivElement>
  ) {
    super(cdr);
  }

  openFile() {
    if (!this.readonly) {
      this.connectService
        .send('getFile')
        .then(async (data) => {
          if (data) {
            await this.bindData(data);
          }
        })
        .catch(() => {
          this.fileInput.nativeElement.click();
        });
    }
  }

  override onRender(): void {
    this.cdr.markForCheck();
  }

  async handleValueChange(evt: any) {
    const data = evt.data;
    await this.bindData(data);
  }

  private async bindData(data: any) {
    if (this.value == null) this.value = [];
    let lengt = Array.from(this.value).length;
    this.value.push({
      index: lengt + 1,
      createDate: new Date().toISOString().split('.')[0].replace(/T/g, ' '),
      isLoading: true,
      name: new Date()
        .toISOString()
        .split('.')[0]
        .replace(/-/g, '')
        .replace(/:/g, '')
        .replace(/T/g, ''),
    });
    this.cdr.detectChanges();
    this.connectService
      .send('uploadFile', { data })
      .then((c) => {
        this.value[lengt] = Object.assign(this.value[lengt], {
          srcData: c,
          isLoading: false,
        });
      })
      .finally(() => {
        this.ref.nativeElement.scrollIntoView();
        this.cdr.detectChanges();
      });
  }

  uploadImage(e: any) {
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    const reader = new FileReader();
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  async _handleReaderLoaded(e: any) {
    const reader = e.target;
    this.bindData(reader.result);
  }

  getImageDimensions(file: any): any {
    return new Promise((resolved, rejected) => {
      const i = new Image();
      i.onload = () => {
        resolved({ w: i.width, h: i.height });
      };
      i.src = file;
    });
  }

  deleteClick(i: number) {
    this.value = this.value.filter((d: any) => d.index != i);
    this.cdr.detectChanges();
  }

  viewClick(fileName: string) {
    this.connectService
      .send('openFile', { fileName })
      .then((data) => {
        if (data != true) {
          this.resolverService.resolve(fileName).then((c: any) => {
            const linkSource = c['changingThisBreaksApplicationSecurity'];
            console.log('linkSource', linkSource);
            const isPDF = linkSource.startsWith('data:application/pdf');
            if (isPDF) {
              this.popupService
                .open(PdfModalPageComponent, {
                  title: 'View Document',
                  size: 'full', //
                  data: {
                    value: c,
                  },
                })
                .closed((e: any) => {
                  if (e.data && c != e.data) {
                    console.log('you closed');
                  }
                });
              return;
            } else {
              const downloadLink = document.createElement('a');
              downloadLink.href = linkSource;
              downloadLink.download = fileName;
              downloadLink.click();
            }
          });
        }
      })
      .catch(() => {});
  }
}
