import { Component, Input, ViewEncapsulation } from '@angular/core';
import { AXTextInputBaseComponent } from '../base.component';

@Component({
  templateUrl: './numberbox.component.html',
  selector: 'ax-number-box',
  encapsulation: ViewEncapsulation.None,
})
export class AXNumberBoxComponent extends AXTextInputBaseComponent {
  @Input() mask: any;
  get maskInner() {
    return this.mask ? this.mask : false;
  }

  @Input()
  minValue: any;

  @Input()
  maxValue: any;
}
