import { AXPopupService } from '@acorex/ui';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { AXFProperyEditor } from '../../config/editor';
import { AXFExpressionEditorComponent } from '../formula/expression-editor.component';

@Component({
  template: `
    <ax-text-box [(text)]="value" [readonly]="readonly">
      <ax-button (onClick)="handleShowEditor()">
        <i class="fas fas fa-code"></i>
      </ax-button>
    </ax-text-box>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXFEventEditorComponent extends AXFProperyEditor<string> {
  readonly: boolean = true;
  constructor(
    protected override cdr: ChangeDetectorRef,
    private popupService: AXPopupService
  ) {
    super(cdr);
  }

  override handleValueChange(value: any) {
    super.handleValueChange(value);
  }

  ngAfterViewInit() {
    this.initiated = true;
  }

  handleShowEditor() {
    this.popupService
      .open(AXFExpressionEditorComponent, {
        title: 'Expression',
        size: 'lg',
        data: {
          code: this.value,
        },
      })
      .closed((c: any) => {
        super.handleValueChange(c.data);
      });
  }
}
