import { AXBasePageComponent } from '@acorex/ui';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';

@Component({
  selector: 'ax-signature',
  styleUrls: ['./signaturepad.page.scss'],
  templateUrl: './signaturepad.page.html',
})
export class SignaturePadPage extends AXBasePageComponent {
  @ViewChild('sigpad1') signaturePad!: SignaturePad;

  confirmText!: string;
  confirm: boolean = false;
  signaturePadOptions: Object = {
    canvasHeight: 300,
    canvasWidth: 300,
  };

  constructor() {
    super();
  }

  @Output()
  valueChange: EventEmitter<string | null> = new EventEmitter<string | null>();

  private _value!: string | null;
  @Input()
  public get value(): string | null {
    return this._value;
  }
  public set value(v: string | null) {
    this._value = v;
    this.valueChange.emit(v);
  }

  ngAfterViewInit() {
    if (this.value) this.signaturePad.fromDataURL(this.value);
    else this.signaturePad.clear();
  }

  drawComplete() {
    this.value = this.signaturePad.toDataURL();
  }

  drawStart() {}

  clearClick() {
    this.value = null;
    this.signaturePad.clear();
  }

  save() {
    if (
      (this.confirmText != null && this.confirmText != '' && this.confirm) ||
      this.confirmText == null ||
      this.confirmText == ''
    ) {
      this.close(this.value);
    }
  }

  back() {
    this.close(null);
  }
}
