import { AXFDataService } from '../../../services/data.service';
import { AXFWidgetView } from '../../config/widget';

export class AXFRepeaterlWidgetFormula {
  constructor(public ww: AXFWidgetView) {}

  join(widget: string, textField: string, condition?: string): string {
    const service = (this.ww as any).dataService as AXFDataService;
    const items: any[] = [];
    let i = 0;
    (this.ww as any).allItems().forEach((element: any) => {
      const w = service.getWidget(`${this.ww.getName()}[${i}].${widget}`);
      if (w) {
        if (!condition || w.value == condition) {
          if (w.dataContext[textField] && w.dataContext[textField] != '')
            items.push(w.dataContext[textField]);
          else items.push(element[textField]);
        }
      } else {
        if (element[widget] == condition) items.push(element[textField]);
      }
      i++;
    });

    return items.join(','); //.map(c => c.dataContext[textField])
  }

  sum(widget: string): number {
    const service = (this.ww as any).dataService as AXFDataService;
    const items: any[] = [];
    let i = 0;
    let sum = 0;
    (this.ww as any).bodyRows.forEach((element: any) => {
      const w = service.getWidget(`${this.ww.getName()}[${i}].${widget}`);
      sum += w.value == undefined ? 0 : parseFloat(w.value);
      i++;
    });
    return sum;
  }

  existed(widget: string, condition?: any): boolean {
    const service = (this.ww as any).dataService as AXFDataService;
    let i = 0;
    let result = false;
    if ((this.ww as any).bodyRows) {
      for (let element of (this.ww as any).bodyRows) {
        const w = service.getWidget(`${this.ww.getName()}[${i}].${widget}`);
        if (
          w &&
          w.value &&
          (w.value.includes(condition) || w.value == condition)
        ) {
          result = true;
          break;
        }
        i++;
      }
    }
    return result;
  }

  addNewRow(obj: any) {
    let view = this.ww as any;
    if (view.rowTemplate) {
      let cloned = view.widgetService.clone(view.rowTemplate);
      let ind = view.setIndex(view.bodyRows.length);
      obj = Object.assign(obj, ind);
      cloned.dataContext = obj;
      view.bodyRows.push(cloned);
      view.cdr.detectChanges();
    }
  }

  deleteRow(selUid: any) {
    let view = this.ww as any;
    let delindex = view.bodyRows.findIndex((e: any) => e.options.uid == selUid);
    console.log('deleteRow on formula before!', view._value, delindex);
    view.bodyRows.splice(delindex, 1);
    setTimeout(() => {
      view._value.splice(delindex, 1);
      console.log('deleteRow on formula after!', view._value, delindex);
    }, 200);
    view.cdr.detectChanges();
  }

  setData(arr: any[]) {
    let view = this.ww as any;
    if (view.rowTemplate) {
      view.bodyRows = arr.map((c) => {
        const cloned = view.widgetService.clone(view.rowTemplate);
        cloned.dataContext = c;
        return cloned;
      });
      view.value = arr;
      view.cdr.detectChanges();
    }
  }

  setRequired(widget: string, value: boolean) {
    const service = (this.ww as any).dataService as AXFDataService;
    const items: any[] = [];
    let i = 0;
    (this.ww as any).bodyRows.forEach((element: any) => {
      const w = service.getWidget(`${this.ww.getName()}[${i}].${widget}`);
      if (w) {
        if (value) w.setRequired(value);
        else w.validator = null;
      }
    });
  }
}
