<div class="box-style-container">
    <!-- Start Margin Box-->
    <div class="box-input margin-box">
        <div class="label">
            <span>Margin</span>
        </div>
        <div class="start">
            <ax-text-box [text]="value.margin.left" (textChange)="handleInnerValueChange($event,'margin','left')"></ax-text-box>
        </div>
        <div class="center">
            <ax-text-box [text]="value.margin.top" (textChange)="handleInnerValueChange($event,'margin','top')"></ax-text-box>
            <div class="content">
                <!-- Start Border Box-->
                <div class="box-input border-box">
                    <div class="label">
                        <span>Border</span>
                    </div>
                    <div class="start">
                        <ax-text-box [text]="value.border.left" (textChange)="handleInnerValueChange($event,'border','left')"></ax-text-box>
                    </div>
                    <div class="center">
                        <ax-text-box [text]="value.border.top" (textChange)="handleInnerValueChange($event,'border','top')"></ax-text-box>
                        <div class="content">
                            <!-- Start Padding Box-->
                            <div class="box-input padding-box">
                                <div class="label">
                                    <span>Padding</span>
                                </div>
                                <div class="start">
                                    <ax-text-box [text]="value.padding.left" (textChange)="handleInnerValueChange($event,'padding','left')"></ax-text-box>
                                </div>
                                <div class="center">
                                    <ax-text-box [text]="value.padding.top" (textChange)="handleInnerValueChange($event,'padding','top')"></ax-text-box>
                                    <div class="content final-content">

                                    </div>
                                    <ax-text-box [text]="value.padding.bottom" (textChange)="handleInnerValueChange($event,'padding','bottom')"></ax-text-box>
                                </div>
                                <div class="end">
                                    <ax-text-box [text]="value.padding.right" (textChange)="handleInnerValueChange($event,'padding','right')"></ax-text-box>
                                </div>
                            </div>
                            <!-- Finish Padding Box-->
                        </div>
                        <ax-text-box [text]="value.border.bottom" (textChange)="handleInnerValueChange($event,'border','bottom')"></ax-text-box>
                    </div>
                    <div class="end">
                        <ax-text-box [text]="value.border.right" (textChange)="handleInnerValueChange($event,'border','right')"></ax-text-box>
                    </div>
                </div>
                <!-- Finish Border Box-->
            </div>
            <ax-text-box [text]="value.margin.bottom" (textChange)="handleInnerValueChange($event,'margin','bottom')"></ax-text-box>
        </div>
        <div class="end">
            <ax-text-box [text]="value.margin.right" (textChange)="handleInnerValueChange($event,'margin','right')"></ax-text-box>
        </div>
    </div>
    <!-- Finish Margin Box--> 
</div>
<div class="row">
    <div class="col-md-12 prop-caption">
        Border Color
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <ax-color-picker  [value]="value.borderColor" (valueChange)="handleColorChange($event)" >
        </ax-color-picker>
    </div>
</div>
