import { ChangeDetectionStrategy, Component, ElementRef } from '@angular/core';
import { AXFBoxStyleValue } from '../../../../property-editor/editors/style/box-style/box-style.class';
import { AXFWidgetView } from '../../../config/widget';

@Component({
  selector: '[axf-widget-col]',
  templateUrl: './col-widget.view.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXFColWidgetView extends AXFWidgetView {
  size: number = 1;
  color!: string;
  bgColor!: string;
  boxStyle!: AXFBoxStyleValue;

  constructor(private hostElement: ElementRef) {
    super();
  }

  override onRender(): void {
    let el: HTMLElement = this.hostElement.nativeElement as HTMLElement;
    el.classList.add('col-sm-12', `col-md-${this.size}`);
    el.style.display = 'table-cell';
    this.applyStyle(el);
  }
}
