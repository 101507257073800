import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { AXCheckedBaseComponent } from '../base.component';

@Component({
  selector: 'ax-check-box',
  templateUrl: './checkbox.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXCheckBoxComponent extends AXCheckedBaseComponent {
  constructor(protected override cdr: ChangeDetectorRef) {
    super(cdr);
  }

  handleClick(e: Event) {
    if (this.readonly) {
      e.stopPropagation();
      e.preventDefault();
      return false;
    }
    return true;
  }
}
