import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  templateUrl: './master.layout.html',
  encapsulation: ViewEncapsulation.None,
})
export class MasterLayout implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
