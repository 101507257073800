import { AXBasePageComponent, ClosingAction } from '@acorex/ui';
import { Component } from '@angular/core';

@Component({
  template: `
    <div
      ace-editor
      [text]="code"
      [mode]="'javascript'"
      [theme]="'tomorrow'"
      [options]="options"
      [autoUpdateContent]="true"
      (textChanged)="onChange($event)"
      style="min-height: 500px; width:100%; overflow: auto;"
    ></div>
  `,
})
export class AXFExpressionEditorComponent extends AXBasePageComponent {
  options: any = {
    maxLines: 1000,
    printMargin: false,
    enableBasicAutocompletion: true,
    enableSnippets: true,
    useWorker: false,
    fontSize: '16px',
    enableLiveAutocompletion: false,
  };

  public code: string = '';

  constructor() {
    super();
  }

  onChange(code: any) {
    this.code = code;
  }

  override onClosing(e: ClosingAction) {
    e.data = this.code;
    e.resolve();
  }
}
