import { AXPopupService } from '@acorex/ui';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { AXFValueWidgetView } from '../../../config/widget';
import { ExpandTextPage } from '../../text-area/expandtext.page';

@Component({
  templateUrl: './text-input-widget.view.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AXFTextInputWidgetView extends AXFValueWidgetView {
  placeholder!: string;
  defaultValue!: number;
  allowExtend!: boolean;
  constructor(
    protected override cdr: ChangeDetectorRef,
    private popupService: AXPopupService
  ) {
    super(cdr);
  }

  override onRender(): void {
    if (
      this.value == undefined &&
      (this as any)['rIndex'] >= 0 &&
      (this as any)['dataContext'] != undefined &&
      (this as any)['dataContext'].hasOwnProperty((this as any)['name'])
    ) {
      this.value = (this as any)['dataContext'][(this as any)['name']];
    }
    this.cdr.markForCheck();
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    if (this.value === undefined && this.defaultValue !== undefined) {
      this.value = this.defaultValue;
    }
  }

  setDateTime() {
    this.value =
      new Date().toLocaleDateString('en-GB') +
      ' ' +
      new Date().toLocaleTimeString('en-GB').substr(0, 5);
    this.cdr.markForCheck();
  }

  setTime() {
    this.value = new Date().toLocaleTimeString('en-GB').substr(0, 5);
    this.cdr.markForCheck();
  }

  extendData() {
    this.popupService
      .open(ExpandTextPage, {
        title: 'Expand Text',
        size: 'md',
        data: {
          value: this.value,
          readonly: this.readonly,
        },
      })
      .closed((c: any) => {
        if (c.data) this.value = c.data;
      });
  }
}
